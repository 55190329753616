<div class="input-group">
    <input class="form-control" aria-label="search books by title, author, publisher or year" type="text" id="searchBooks" 
           #searchText (keyup.enter)="searchBooks(searchText.value)" 
           placeholder="Search books by title, author, publisher or year">   

    <button class="clear-button" aria-label="submit book clear text" (click)="clearSearchText(searchText)"><mat-icon>close</mat-icon></button>  

    <button class="search-button" aria-label="submit book search" (click)="searchBooks(searchText.value)"><mat-icon>search</mat-icon></button>   
</div>
         


