<!-- LOADING STATE -->
<div
  class="container-fluid loading__animated"
  [hidden]="!loading"
  [@hideShowAnimation]
></div>
<!-- ERROR STATE -->
<div class="row" *ngIf="!loading && hasGetBibliographyError">
  <div class="row__save">
    <h1>Bibliography / Booklist</h1>
  </div>
  <div class="col-12">
    <mat-card class="card__loading">
      <mat-card-title>
        <span class="title">Error</span>
      </mat-card-title>
      <mat-card-content>
        <p>
          We couldn't find a bibliogrpahy with the id: "<strong>{{
            errorId
          }}</strong
          >".
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<form
  #bibliographyForm="ngForm"
  *ngIf="!loading && !hasGetBibliographyError"
  [@hideShowAnimation]
>
  <div class="row__save">
    <h1>Bibliography / Booklist</h1>
    <div class="col">
      <span
        class="helper helper__success"
        *ngIf="saveSuccessful && !tinyMCEHasError"
      >
        <mat-icon>check</mat-icon> Changes were saved
        {{ lastSavedDate | timeAgo }}
      </span>
      <span class="helper helper__error" *ngIf="tinyMCEHasError">
        <mat-icon>warning</mat-icon> Introduction exceeds maximum length
      </span>
      <button
        mat-raised-button
        class="button__save"
        (click)="onSave()"
        [disabled]="
          !bibliographyForm.form.valid ||
          tinyMCEHasError ||
          selectedAgeGrades.length < 1
        "
      >
        <mat-icon>save</mat-icon> Save changes
      </button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <mat-card class="card__name">
          <mat-card-title>
            <span class="title">Name</span>
          </mat-card-title>
          <mat-card-content>
            <div class="row">
              <div class="col col-12">
                <div
                  class="input-group name-bibliography"
                  [ngClass]="{
                    valid_and_required: bibliographyName.valid,
                    invalid: bibliographyName.invalid
                  }"
                >
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Bibliography Name"
                    name="bibliographyName"
                    [(ngModel)]="bibliography.name"
                    #bibliographyName="ngModel"
                    required
                    maxlength="255"
                  />
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <div class="row">
            <div class="col-4">
              <mat-card-title>
                <span class="title">Age / Grades</span>
              </mat-card-title>
              <mat-card-content class="checkbox-list">
                <p>Selecting at least one age/grade is required</p>
                <mat-checkbox
                  *ngFor="let item of allAgeGrades; let i = index"
                  [(ngModel)]="item.selected"
                  name="ageGrade{{ i }}"
                  (ngModelChange)="onClickAgeGrade()"
                >
                  <span>{{ item.name }}</span>
                </mat-checkbox>
              </mat-card-content>
            </div>

            <div class="col-8">
              <mat-card-title>
                <span class="title">Introduction</span>
              </mat-card-title>
              <mat-card-content>
                <editor
                  id="BibliographyIntroduction"
                  [(ngModel)]="tinyMCEInput"
                  (ngModelChange)="onTinyMCEInput()"
                  name="bibliographyIntroduction"
                  (onKeyPress)="onTinyMCEInput()"
                  [init]="{
                    valid_elements: [
                      'p,br,i,b,u,span,strong,em,ul,li,ol,a[href|target=_blank]'
                    ],
                    plugins: ['lists autolink link'],
                    menubar: 'false',
                    toolbar: ['bold italic | bullist numlist | link'],
                    link_assume_external_targets: true,
                    default_link_target: '_blank',
                    relative_urls: false,
                    target_list: false,
                    oninit: initTinyMCE()
                  }"
                  apiKey="25vqkf9hajnbjpua6m8ymgjs2hnqecl9odd07tfwuamo7u7h"
                >
                </editor>
                <div class="row mat-row">
                  <span class="helper helper__error" *ngIf="tinyMCEHasError"
                    ><mat-icon>warning</mat-icon> Max length exceeded!</span
                  >
                  <span class="flex"></span>
                  <span
                    class="helper"
                    [ngClass]="{ helper__error: tinyMCEHasError }"
                    >{{ getTinyMCEInputLength() }}/{{ tinyMCELimit }} characters
                    <mat-icon
                      matTooltip="Character count includes hidden HTML tags to preserve text formatting"
                      >help_outline</mat-icon
                    ></span
                  >
                </div>
              </mat-card-content>
            </div>
          </div>
        </mat-card>

        <mat-card>
          <mat-card-title>
            <span class="title">Categories and Subcategories</span>
          </mat-card-title>
          <mat-card-content>
            <tree-root
              #tree
              [nodes]="bibliography.nestedCategories"
              [options]="treeOptions"
              (activate)="onTreeEvent($event)"
              (deactivate)="onTreeEvent($event)"
            >
              <ng-template
                #treeNodeFullTemplate
                let-node
                let-index="index"
                let-templates="templates"
              >
                <div
                  [class]="node.getClass()"
                  [class.tree-node]="true"
                  [class.tree-node-expanded]="
                    node.isExpanded && node.hasChildren
                  "
                  [class.tree-node-collapsed]="
                    node.isCollapsed && node.hasChildren
                  "
                  [class.tree-node-leaf]="node.isLeaf"
                  [class.tree-node-active]="node.isActive"
                  [class.tree-node-focused]="node.isFocused"
                >
                  <tree-node-drop-slot
                    *ngIf="index === 0"
                    [dropIndex]="node.index"
                    [node]="node.parent"
                  >
                  </tree-node-drop-slot>

                  <div class="tree-node-custom">
                    <tree-node-wrapper
                      [node]="node"
                      [index]="index"
                      [templates]="templates"
                    >
                    </tree-node-wrapper>

                    <!--Our custom template-->
                    <div class="node-edit-wrapper" *ngIf="node.data.showBooks">
                      <div class="row">
                        <div class="col col-12">
                          <div class="form-group">
                            <label
                              class="categoryName"
                              for="CategoryName{{ index }}"
                              >Name</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="CategoryName{{ index }}"
                              placeholder="Name"
                              [(ngModel)]="node.data.description"
                              [ngModelOptions]="{ standalone: true }"
                            />
                          </div>

                          <table
                            class="table table-sm table-bordered"
                            *ngIf="
                              node.data.booksForBibliographyView.length > 0
                            "
                          >
                            <thead>
                              <tr>
                                <th>Remove</th>
                                <th>Author(s)</th>
                                <th>Title</th>
                                <th>Year</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let book of node.data.booksForBibliographyView
                                "
                              >
                                <td>
                                  <mat-checkbox
                                    [(ngModel)]="book.remove"
                                    [ngModelOptions]="{ standalone: true }"
                                  ></mat-checkbox>
                                </td>
                                <td>{{ book.authors }}</td>
                                <td>{{ book.title }}</td>
                                <td>{{ book.year }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p
                            *ngIf="
                              node.data.booksForBibliographyView.length < 1
                            "
                          >
                            No books in category
                          </p>
                          <button
                            mat-flat-button
                            class="button__white"
                            type="button"
                            *ngIf="
                              node.data.booksForBibliographyView.length > 0
                            "
                            (click)="deleteBooks(node)"
                          >
                            Remove Selected Books
                          </button>
                          <button
                            mat-flat-button
                            class="button__blue"
                            type="button"
                            (click)="addBook(node)"
                          >
                            <mat-icon>add</mat-icon> Add Book
                          </button>
                        </div>
                      </div>

                      <div class="row cancel-row">
                        <div class="col col-12">
                          <div class="float-right">
                            <button
                              mat-flat-button
                              class="button__white"
                              type="button"
                              (click)="removeCategory(node)"
                            >
                              Remove Category
                            </button>
                            <button
                              mat-flat-button
                              class="button__white"
                              type="button"
                              (click)="
                                node.data.showBooks = false;
                                node.toggleActivated()
                              "
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <tree-node-children [node]="node" [templates]="templates">
                  </tree-node-children>
                  <tree-node-drop-slot
                    [dropIndex]="node.index + 1"
                    [node]="node.parent"
                  >
                  </tree-node-drop-slot>
                </div>
              </ng-template>
            </tree-root>

            <hr />

            <div class="category-name-input">
              <label class="categoryName" for="CategoryName"
                >Category name</label
              >
              <input
                class="form-control"
                type="text"
                placeholder="New category"
                [(ngModel)]="blankCategoryName"
                name="CategoryName"
              />
            </div>

            <button
              mat-flat-button
              class="button__blue"
              (click)="addCategory(blankCategoryName)"
              [disabled]="blankCategoryName === ''"
            >
              <mat-icon>add</mat-icon> Add Category
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row__save">
    <div class="col">
      <span
        class="helper helper__success"
        *ngIf="saveSuccessful && !tinyMCEHasError"
      >
        <mat-icon>check</mat-icon> Changes were saved
        {{ lastSavedDate | timeAgo }}
      </span>
      <span class="helper helper__error" *ngIf="tinyMCEHasError">
        <mat-icon>warning</mat-icon> Introduction exceeds maximum length
      </span>
      <button
        mat-raised-button
        class="button__save"
        (click)="onSave()"
        [disabled]="
          !bibliographyForm.form.valid ||
          tinyMCEHasError ||
          selectedAgeGrades.length < 1
        "
      >
        <mat-icon>save</mat-icon> Save changes
      </button>
    </div>
  </div>
</form>
