<h1 *ngIf="isNewContributor" mat-dialog-title>Create new contributor</h1>
<h1 *ngIf="!isNewContributor" mat-dialog-title>Editing: <small>{{ contributor.firstName }} {{ contributor.middleName }} {{ contributor.lastName }}</small></h1>
<div mat-dialog-content>
    <form #contributorForm="ngForm">
        <div class="row">
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                        [ngClass]="{'valid_and_required': lastName.valid, 'invalid': lastName.invalid}"
                        id="input__lastName">Last name</span>
                </div>
                <input #lastName="ngModel" type="text" class="form-control" [(ngModel)]="contributor.lastName"
                    name="lastName" aria-labelledby="input__lastName" required>
            </div>
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                        [ngClass]="{'valid_and_required': firstName.valid, 'invalid': firstName.invalid}"
                        id="input__firstName">First name</span>
                </div>
                <input #firstName="ngModel" type="text" class="form-control" [(ngModel)]="contributor.firstName"
                    name="firstName" aria-labelledby="input__firstName" required>
            </div>
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="input__lastName">Middle name</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="contributor.middleName" name="middleName"
                    aria-labelledby="input__middleName">
            </div>
        </div>
        <div class="row border-top">
            <div class="col-6">
                <h2 #labelHeritages>Heritage</h2>
                <app-multi-select #heritageMultiSelect [id]="'heritageMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                    [multiSelectOptions]="heritageOptions" [selectionsToReturn]="selectedHeritages"
                    (onSelectionsChanged)="setSelectedHeritages($event)" [ariaLabel]="'heritage'">
                </app-multi-select>
                <div class="input-group">
                    <textarea type="text" id="contributorHeritageNotes" class="form-control"
                        [(ngModel)]="contributor.culturalHeritageNotes" name="heritageNotes"
                        placeholder="Notes"></textarea>
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="input__specificCulture">Specific culture</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="contributor.specificCulture"
                        name="specificCulture" aria-labelledby="input__specificCulture">
                </div>
                <div class="row mat-row">
                    <mat-checkbox #checkContested name="heritageContested" [(ngModel)]="contributor.culturalHeritageContested" (change)="pretendToBeRadio($event)">Heritage
                        contested</mat-checkbox>
                </div>
                <div class="row mat-row">
                    <mat-checkbox #checkVerified name="heritageVerified" [(ngModel)]="contributor.verified" (change)="pretendToBeRadio($event)">Heritage verified
                    </mat-checkbox>
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="input__verificationSource">Verification source</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="contributor.verificationSource"
                        name="verificationSource" aria-labelledby="input__verificationSource">
                </div>
                <h2 #labelGenders>Gender</h2>
                <app-multi-select #gendersMultiSelect [id]="'gendersMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                    [multiSelectOptions]="genderOptions" [selectionsToReturn]="selectedGenders"
                    (onSelectionsChanged)="setSelectedGenders($event)" [ariaLabel]="'gender'">
                </app-multi-select>
                <div *ngIf="contributorBooks.length > 0">
                    <h2>Books</h2>
                    <span class="hint">Note: Links open in a new tab</span>
                    <mat-list role="list">
                        <mat-list-item role="listitem" *ngFor="let book of contributorBooks">
                            <a href="/book/{{book.id}}" target="_blank" rel="noopener noreferrer">{{ book.title }}</a>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
            <div class="col-6">
                <h2 #labelReligions>Religion</h2>
                <app-multi-select #religionsMultiSelect [id]="'religionsMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                    [multiSelectOptions]="religionOptions" [selectionsToReturn]="selectedReligions"
                    (onSelectionsChanged)="setSelectedReligions($event)" [ariaLabel]="'religion'">
                </app-multi-select>
                <h2 #labelDisability>Disability/Condition</h2>
                <app-multi-select #disabilityMultiSelect [id]="'disabilityMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                    [multiSelectOptions]="disabilityOptions" [selectionsToReturn]="selectedDisabilities"
                    (onSelectionsChanged)="setSelectedDisabilities($event)" [ariaLabel]="'disability'">
                </app-multi-select>
                <div class="input-group">
                    <textarea type="text" id="contributorDisabilityNotes" class="form-control"
                        [(ngModel)]="contributor.disabilityNotes" name="disabilityNotes" placeholder="Notes"></textarea>
                </div>
                <h2>Sexuality</h2>
                <mat-checkbox name="lgbtq" [(ngModel)]="contributor.lgbtq">LGBTQ+</mat-checkbox>
                <div class="input-group">
                    <textarea type="text" id="contributorSexualityNotes" class="form-control"
                        [(ngModel)]="contributor.lgbtqNotes" name="sexualityNotes" placeholder="Notes"></textarea>
                </div>
                <h2>Wisconsin</h2>
                <mat-checkbox name="wisconsinContributor" [(ngModel)]="contributor.wisconsinContributor">Wisconsin author/illustrator</mat-checkbox>
                <div class="input-group">
                    <textarea type="text" id="contributorWisconsinContributorNotes" class="form-control"
                        [(ngModel)]="contributor.wisconsinContributorNotes" name="wisconsinContributorNotes" placeholder="Notes"></textarea>
                </div>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="border-top">
    <button mat-button (click)="closeDialog()">Cancel</button>
    <button class="button__save" mat-button mat-flat-button (click)="setNewContributor()"
        [disabled]="!contributorForm.form.valid">Save changes</button>
</div>