import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

//services
import { ListBooksService } from 'src/app/services/list-books.service';

//interface for multi-selection
import { IMultiSelectOptions } from '../multi-select/multi-select.component';

//classes
//import { Book } from 'src/app/classes/book';
import { CharacterType } from 'src/app/classes/CharacterType';
import { CharactersCharacterTypes} from 'src/app/classes/CharactersCharacterTypes'
import { Character, CharactersReligions } from 'src/app/classes/character';
import { CharactersCharacterGenders } from 'src/app/classes/CharactersCharacterGenders';
import { Religion } from 'src/app/classes/Religion';
import {Gender} from 'src/app/classes/Gender';
import { CharacterGenderSpecify } from 'src/app/classes/CharacterGenderSpecify';
import { CharactersCharacterGenderSpecifies } from 'src/app/classes/CharactersCharacterGenderSpecifies';
import { CharacterHeritage } from 'src/app/classes/CharacterHeritage';
import { CharactersCharacterHeritages} from 'src/app/classes/CharactersCharacterHeritages';
import { CharacterHeritageSpecify} from 'src/app/classes/CharacterHeritageSpecify';
import { CharactersCharacterHeritageSpecifies} from 'src/app/classes/CharactersCharacterHeritageSpecifies';
import { CharacterSexuality } from 'src/app/classes/CharacterSexuality';
import { CharactersCharacterSexualities } from 'src/app/classes/CharactersCharacterSexualities';
import { Disability} from 'src/app/classes/Disability';
import { CharactersDisabilities} from 'src/app/classes/CharactersDisabilities';

@Component({
  selector: 'app-book-characters',
  templateUrl: './book-characters.component.html',
  styleUrls: ['./book-characters.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class BookCharactersComponent implements OnInit {
  @Input() model: Character;
  @Input() index: number;

  prefix: string = "";
  
  constructor(private bookService: ListBooksService ) { }

  //diversity subject - Heritage setting
  allCharacterTypes: Array<CharacterType> = new Array<CharacterType>();
  characterTypeOptions: Array<any> = new Array<any>();
  selectedCharacterTypes: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedCharacterTypeToSave: Array<CharactersCharacterTypes> = new Array<CharactersCharacterTypes>();

  /*Character gender and gender specify */
  allCharacterGenders: Array<Gender> = new Array<Gender>();
  characterGenderOptions:Array<any> = new Array<any>();
  selectedCharacterGenders: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedCharacterGenderToSave: Array<CharactersCharacterGenders> = new Array<CharactersCharacterGenders>();

  allCharacterGenderSpecifies: Array<CharacterGenderSpecify> = new Array<CharacterGenderSpecify>();
  characterGenderSpecifyOptions:Array<any> = new Array<any>();
  selectedCharacterGenderSpecifies: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedCharacterGenderSpecifyToSave: Array<CharactersCharacterGenderSpecifies> = new Array<CharactersCharacterGenderSpecifies>();
/* END of gender & gender specify*/

/*Character heritage and heritage specify */
allCharacterHeritages: Array<CharacterHeritage> = new Array<CharacterHeritage>();
characterHeritageOptions:Array<any> = new Array<any>();
selectedCharacterHeritages: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
selectedCharacterHeritageToSave: Array<CharactersCharacterHeritages> = new Array<CharactersCharacterHeritages>();

allCharacterHeritageSpecifies: Array<CharacterHeritageSpecify> = new Array<CharacterHeritageSpecify>();
characterHeritageSpecifyOptions:Array<any> = new Array<any>();
selectedCharacterHeritageSpecifies: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
selectedCharacterHeritageSpecifyToSave: Array<CharactersCharacterGenderSpecifies> = new Array<CharactersCharacterGenderSpecifies>();
/* END of gender & gender specify*/

/*Religion */
allReligions: Array<Religion> = new Array<Religion>();
religionOptions:Array<any> = new Array<any>();
selectedReligions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
selectedReligionToSave: Array<CharactersReligions> = new Array<CharactersReligions>();
/*end religion*/

/*Character Disability*/
allCharacterDisabilities: Array<Disability> = new Array<Disability>();
characterDisabilityOptions:Array<any> = new Array<any>();
selectedCharacterDisabilities: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
selectedCharacterDisabilityToSave: Array<CharactersDisabilities> = new Array<CharactersDisabilities>();
/*END of Disability */

/** Sexuality section */
allCharacterSexuality: Array<CharacterSexuality> = new Array<CharacterSexuality>();
selectedCharacterSexualities: Array<CharactersCharacterSexualities> =new Array<CharactersCharacterSexualities>(); 


getAllCharacterSexualities(): void {
this.bookService.getAllCharacterSexualityOptions().subscribe(characterSexualities => {
    if (characterSexualities != undefined && characterSexualities.length > 0) {
       this.populateCharacterSexuality(characterSexualities)
    }
  })
}

populateCharacterSexuality(characterSexuality:Array<CharacterSexuality>):void{
  this.selectedCharacterSexualities =this.model.charactersCharacterSexualities; 
  this.allCharacterSexuality = characterSexuality;
  this.allCharacterSexuality.forEach(item => {
   
    if (this.selectedCharacterSexualities.filter(sex=>sex.characterSexualityId === item.characterSexualityId).length>0){
      item.selected = true;
    }
    else{
      item.selected=false;
    }
  })
}

onClickSexuality(){
  let updatedCharacterSexualities = new Array<CharactersCharacterSexualities>();
  this.allCharacterSexuality.forEach(all=>{
      if (all.selected){
        const updatedSexuality: CharactersCharacterSexualities ={
          characterId: this.model.id,
          characterSexualityId: all.characterSexualityId,
          characterSexuality: null 
        }
         updatedCharacterSexualities.push(updatedSexuality);
    }
     
    })
    this.model.charactersCharacterSexualities = updatedCharacterSexualities;
}
  

/** END of Sexuality Section */


  getAllCharacterTypes(): void {
    this.bookService.getAllCharacterTypeOptions().subscribe(characterTypes => {
      if (characterTypes != undefined && characterTypes.length > 0) {
        this.allCharacterTypes = characterTypes;
        this.assembleCharacterTypeOptions(characterTypes);
      }
    })
  }

  assembleCharacterTypeOptions(characterTypes: Array<CharacterType>): void {
    for (let characterType of characterTypes) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: characterType.characterTypeId,
        displayText: characterType.characterTypeName
      }
      this.characterTypeOptions.push(data);
    }

    this.characterTypeOptions = this.characterTypeOptions.slice();

  }

  transformCharacterCharacterType(characterCharacterTypes: Array<CharactersCharacterTypes>) {
    characterCharacterTypes.forEach(item => {
      let data = {
        option: item.characterTypeId,
        displayText: item.characterType.characterTypeName
      };
      this.selectedCharacterTypes.push(data);
    });
  }

  setSelectedCharacterType(characterTypes: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
      this.selectedCharacterTypes = characterTypes;
      this.selectedCharacterTypes = this.selectedCharacterTypes.slice();
      this.updateCharacterCharacterTypeToSave();
  }
  updateCharacterCharacterTypeToSave(): void {
    // Transform multi-select data into book publishers
    let updatedCharacterTypes = new Array<CharactersCharacterTypes>();
    this.selectedCharacterTypes.forEach(item => {
      const characterCharacterType: CharactersCharacterTypes = {
        characterId: this.model.id,
        characterTypeId: item.option,
        characterType: null 
      }
      updatedCharacterTypes.push(characterCharacterType);
    });
    this.model.charactersCharacterTypes = updatedCharacterTypes;
   
  }

/************************************************************************
 * Functions of gender and gender specifies
 * 
 * *********************************************************************/
  getAllCharacterGenders(): void {
    this.bookService.getAllCharacterGenderOptions().subscribe(charactergenders => {
      if (charactergenders != undefined && charactergenders.length > 0) {
        this.allCharacterGenders = charactergenders;
        this.assembleGenderOptions(charactergenders);
      }
    })
  }

assembleGenderOptions(genders: Array<Gender>): void {
  for (let gender of genders) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option: gender.id,
      displayText: gender.name
    }
    this.characterGenderOptions.push(data);
  }
  
  this.characterGenderOptions = this.characterGenderOptions.slice();

}

transformCharacterCharacterGender(characterCharacterGenders: Array<CharactersCharacterGenders>) {
  characterCharacterGenders.forEach(item => {
    let data = {
      option: item.genderId,
      displayText: item.gender.name
    };
    this.selectedCharacterGenders.push(data);
  });
}

setSelectedCharacterGenders(characterGenders: Array<IMultiSelectOptions>): void {
  // If the user removed all publishers, mark them all for deletion
    this.selectedCharacterGenders = characterGenders;
    this.selectedCharacterGenders = this.selectedCharacterGenders.slice();
    this.updateCharacterCharacterGenderToSave();
}
updateCharacterCharacterGenderToSave(): void {
  // Transform multi-select data into book publishers
  let updatedCharacterGenders = new Array<CharactersCharacterGenders>();
  this.selectedCharacterGenders.forEach(item => {
    const characterCharacterGender: CharactersCharacterGenders = {
      characterId: this.model.id,
      genderId: item.option,
      gender: null 
    }
    updatedCharacterGenders.push(characterCharacterGender);
  });
  this.model.charactersCharacterGenders = updatedCharacterGenders;
  
}

getAllCharacterGenderSpecifies(): void {
  this.bookService.getAllCharacterGenderSpecifyOptions().subscribe(charactergenderSpecifies => {
    if (charactergenderSpecifies != undefined && charactergenderSpecifies.length > 0) {
      this.allCharacterGenderSpecifies = charactergenderSpecifies;
      this.assembleGenderSpecifyOptions(charactergenderSpecifies);
    }
  })
}

assembleGenderSpecifyOptions(genderSpecifies: Array<CharacterGenderSpecify>): void {
  for (let genderSpecify of genderSpecifies) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option: genderSpecify.characterGenderSpecifyId,
      displayText: genderSpecify.name
    }
    this.characterGenderSpecifyOptions.push(data);
  }
  
  this.characterGenderSpecifyOptions = this.characterGenderSpecifyOptions.slice();

}

transformCharacterCharacterGenderSpecify(characterCharacterGenderSpecifies: Array<CharactersCharacterGenderSpecifies>) {
  characterCharacterGenderSpecifies.forEach(item => {
  let data = {
    option: item.characterGenderSpecifyId,
    displayText: item.characterGenderSpecify.name
  };
  this.selectedCharacterGenderSpecifies.push(data);
});
}

setSelectedCharacterGenderSpecifies(characterGenderSpecifies: Array<IMultiSelectOptions>): void {
// If the user removed all publishers, mark them all for deletion
  this.selectedCharacterGenderSpecifies = characterGenderSpecifies;
  this.selectedCharacterGenderSpecifies = this.selectedCharacterGenderSpecifies.slice();
  this.updateCharacterCharacterGenderSpecifyToSave();
}
updateCharacterCharacterGenderSpecifyToSave(): void {
// Transform multi-select data into book publishers
let updatedCharacterGenderSpecifies = new Array<CharactersCharacterGenderSpecifies>();
this.selectedCharacterGenderSpecifies.forEach(item => {
  const characterGenderSpecify: CharactersCharacterGenderSpecifies = {
    characterId: this.model.id,
    characterGenderSpecifyId: item.option,
    characterGenderSpecify: null 
  }
  updatedCharacterGenderSpecifies.push(characterGenderSpecify);
});
this.model.charactersCharacterGenderSpecifies = updatedCharacterGenderSpecifies;

}
/**End of Functions of gender & gender specifies */

/************************************************************************
 * Functions of Heritage and heritage specifies
 * 
 * *********************************************************************/
getAllCharacterHeritages(): void {
  this.bookService.getAllCharacterHeritageOptions().subscribe(characterHeritages => {
    if (characterHeritages != undefined && characterHeritages.length > 0) {
      this.allCharacterHeritages = characterHeritages;
      this.assembleHeritageOptions(characterHeritages);
    }
  })
}

assembleHeritageOptions(heritages: Array<CharacterHeritage>): void {
  for (let heritage of heritages) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option:  heritage.id,
      displayText: heritage.name
    }
    this.characterHeritageOptions.push(data);
  }

  this.characterHeritageOptions = this.characterHeritageOptions.slice();

}

transformCharacterCharacterHeritage(characterCharacterheritages: Array<CharactersCharacterHeritages>) {
  characterCharacterheritages.forEach(item => {
  let data = {
    option: item.characterHeritageId,
    displayText: item.characterHeritage.name
  };
  this.selectedCharacterHeritages.push(data);
});
}

setSelectedCharacterHeritage(characterHeritages: Array<IMultiSelectOptions>): void {
// If the user removed all publishers, mark them all for deletion
  this.selectedCharacterHeritages = characterHeritages;
  this.selectedCharacterHeritages = this.selectedCharacterHeritages.slice();
  this.updateCharacterCharacterHeritageToSave();
}
updateCharacterCharacterHeritageToSave(): void {
// Transform multi-select data into book publishers
let updatedCharacterHeritages = new Array<CharactersCharacterHeritages>();
  this.selectedCharacterHeritages.forEach(item => {
    const characterCharacterHeritage: CharactersCharacterHeritages = {
      characterId: this.model.id,
      characterHeritageId: item.option,
      characterHeritage: null 
    }
    updatedCharacterHeritages.push(characterCharacterHeritage);

  });
this.model.charactersCharacterHeritages = updatedCharacterHeritages;
}

getAllCharacterHeritageSpecifies(): void {
this.bookService.getAllCharacterHeritageSpecifyOptions().subscribe(characterHeritageSpecifies => {
  if (characterHeritageSpecifies != undefined && characterHeritageSpecifies.length > 0) {
    this.allCharacterHeritageSpecifies = characterHeritageSpecifies;
    this.assembleHeritageSpecifyOptions(characterHeritageSpecifies);
  }
})
}

assembleHeritageSpecifyOptions(heritageSpecifies: Array<CharacterHeritageSpecify>): void {
  for (let heritageSpecify of heritageSpecifies) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option: heritageSpecify.characterHeritageSpecifyId,
      displayText: heritageSpecify.name
    }
    this.characterHeritageSpecifyOptions.push(data);
  }
  
  this.characterHeritageSpecifyOptions = this.characterHeritageSpecifyOptions.slice();

}

transformCharacterCharacterHeritageSpecify(characterCharacterHeritageSpecifies: Array<CharactersCharacterHeritageSpecifies>) {
  characterCharacterHeritageSpecifies.forEach(item => {
let data = {
  option: item.characterHeritageSpecifyId,
  displayText: item.characterHeritageSpecify.name
};
this.selectedCharacterHeritageSpecifies.push(data);
});
}

setSelectedCharacterHeritageSpecify(characterHeritageSpecifies: Array<IMultiSelectOptions>): void {
// If the user removed all publishers, mark them all for deletion
this.selectedCharacterHeritageSpecifies = characterHeritageSpecifies;
this.selectedCharacterHeritageSpecifies = this.selectedCharacterHeritageSpecifies.slice();
this.updateCharacterCharacterHeritageSpecifyToSave();
}
updateCharacterCharacterHeritageSpecifyToSave(): void {
// Transform multi-select data into book publishers
let updatedCharacterHeritageSpecifies = new Array<CharactersCharacterHeritageSpecifies>();
this.selectedCharacterHeritageSpecifies.forEach(item => {
const characterHeritageSpecify: CharactersCharacterHeritageSpecifies = {
  characterId: this.model.id,
  characterHeritageSpecifyId: item.option,
  characterHeritageSpecify: null 
}
updatedCharacterHeritageSpecifies.push(characterHeritageSpecify);
});
this.model.charactersCharacterHeritageSpecifies = updatedCharacterHeritageSpecifies;

}
/**End of Functions of Heritage & Heritage specifies */

/** Function of Character Religions */
getAllReligions(): void {
  this.bookService.getAllReligions().subscribe(religions => {
    if (religions != undefined && religions.length > 0) {
      this.allReligions = religions;
      this.assembleReligionOptions(religions);
    }
  })
}
assembleReligionOptions(religions: Array<Religion>): void {
  for (let religion of religions) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option: religion.id,
      displayText: religion.name
    }
    this.religionOptions.push(data);
  }
  this.religionOptions = this.religionOptions.slice();
}
transformCharacterReligion(charactersReligions: Array<CharactersReligions>) {
  charactersReligions.forEach(item => {
    let data = {
      option: item.religionId,
      displayText: item.religion.name
    };
    this.selectedReligions.push(data);
  });
}
setSelectedReligion(characterReligions: Array<IMultiSelectOptions>): void {
  // If the user removed all publishers, mark them all for deletion
    this.selectedReligions = characterReligions;
    this.selectedReligions = this.selectedReligions.slice();
    this.updateCharacterReligionToSave();
}
updateCharacterReligionToSave(): void {
  // Transform multi-select data into book publishers
  let updatedCharacterReligions = new Array<CharactersReligions>();
  this.selectedReligions.forEach(item => {
    const characterReligion: CharactersReligions = {
      characterId: this.model.id,
      religionId: item.option,
      religion: null 
    }
    updatedCharacterReligions.push(characterReligion);
  });
  this.model.charactersReligions = updatedCharacterReligions;
}


/** End OF Function of Character Religions */

/** Function of Character Disability */
getAllCharacterDisabilities(): void {
  this.bookService.getAllDisabilities().subscribe(disabilities => {
    if (disabilities != undefined && disabilities.length > 0) {
      this.allCharacterDisabilities = disabilities;
      this.assembleDisabilityOptions(disabilities);
    }
  })
}

assembleDisabilityOptions(disabilities: Array<Disability>): void {
  for (let disability of disabilities) {
    // Convert data to match IMultiSelectOptions
    let data = {
      option: disability.id,
      displayText: disability.name
    }
    this.characterDisabilityOptions.push(data);
  }
  this.characterDisabilityOptions = this.characterDisabilityOptions.slice();
}

transformCharacterCharacterDisability(characterDisabilities: Array<CharactersDisabilities>) {
  characterDisabilities.forEach(item => {
    let data = {
      option: item.disabilityId,
      displayText: item.disability.name
    };
    this.selectedCharacterDisabilities.push(data);
  });
}

setSelectedCharacterDisability(disabilities: Array<IMultiSelectOptions>): void {
  // If the user removed all publishers, mark them all for deletion
    this.selectedCharacterDisabilities = disabilities;
    this.selectedCharacterDisabilities = this.selectedCharacterDisabilities.slice();
    this.updateCharacterCharacterDisabilityToSave();
}
updateCharacterCharacterDisabilityToSave(): void {
  // Transform multi-select data into book publishers
  let updatedCharacterDisabilities = new Array<CharactersDisabilities>();
  this.selectedCharacterDisabilities.forEach(item => {
    const charactersDisability: CharactersDisabilities = {
      characterId: this.model.id,
      disabilityId: item.option,
      disability: null 
    }
    updatedCharacterDisabilities.push(charactersDisability);
  });
  this.model.charactersDisabilities = updatedCharacterDisabilities;
}
/** End OF Function of Character Religions */



  ngOnInit() {
    
    this.getAllCharacterTypes();
    this.transformCharacterCharacterType(this.model.charactersCharacterTypes);

    this.getAllCharacterGenders();
    this.transformCharacterCharacterGender(this.model.charactersCharacterGenders);

    this.getAllCharacterGenderSpecifies();
    this.transformCharacterCharacterGenderSpecify(this.model.charactersCharacterGenderSpecifies);

    this.getAllCharacterHeritages();
    this.transformCharacterCharacterHeritage(this.model.charactersCharacterHeritages);

    this.getAllCharacterHeritageSpecifies();
    this.transformCharacterCharacterHeritageSpecify(this.model.charactersCharacterHeritageSpecifies);
  
    this.getAllReligions();
    this.transformCharacterReligion(this.model.charactersReligions);
    
    this.getAllCharacterDisabilities();
    this.transformCharacterCharacterDisability(this.model.charactersDisabilities);
    
    this.getAllCharacterSexualities();

    this.prefix = (this.model.isSecondaryCharacter ? "secondary" : "primary") + this.index;
    
  }
  
  /**
   * Close the expanded "character" panel
   * @param {string} prefix of the clicked expansion panel 
   */
   openCharacterPanel(prefix: string): void {
    let panelTitle = document.getElementById(prefix + 'panelTitle');
    if (panelTitle != undefined) {
        panelTitle.classList.add('opened');
    }
  }

  /**
   * Close the expanded "character" panel
   * @param {string} prefix of the clicked expansion panel 
   */
   closeCharacterPanel(prefix: string): void {
    let panelTitle = document.getElementById(prefix + 'panelTitle');
    if (panelTitle != undefined) {
        panelTitle.classList.remove('opened');
    }
  }

}
