import { Component, OnInit, Inject } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { FormGroup, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

// Classes
import { ContributorPersonalInfo, ContributorsGenders, ContributorsReligions, ContributorsContributorHeritages, ContributorHeritage, ContributorsDisabilities } from 'src/app/classes/Contributor';
import { Gender } from 'src/app/classes/Gender';
import { IMultiSelectOptions } from '../multi-select/multi-select.component';
import { Book } from 'src/app/classes/Book';
import { Disability } from 'src/app/classes/Disability';

// Services
import { ContributorService } from 'src/app/services/contributor.service';
import { ListBooksService } from 'src/app/services/list-books.service';
import { Religion } from 'src/app/classes/Religion';

export interface DialogData {
  contributor: ContributorPersonalInfo;
  isNewContributor: boolean;
}

/**
 * Base component that contains functionality shared by the add/edit contributor PAGE and MODAL. It is extended by 
 * DialogNewContributorComponent and PageNewContributorComponent, which follow it in this file.
 */
@Component({
  selector: 'app-new-contributor',
  template: ''
})
export class AddEditContributorComponent {
  contributorForm: FormGroup;
  isNewContributor: boolean = true;
  isCanceled: boolean = false;
  contributor = new ContributorPersonalInfo();
  contributorBooks = new Array<Book>();

  allGenders: Array<Gender> = new Array<Gender>();
  genderOptions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedGenders: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();

  allReligions: Array<Religion> = new Array<Religion>();
  religionOptions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedReligions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();

  allHeritages: Array<ContributorHeritage> = new Array<ContributorHeritage>();
  heritageOptions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedHeritages: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();

  allDisabilities: Array<Disability> = new Array<Disability>();
  disabilityOptions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedDisabilities: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();

  constructor(        
    public contributorService: ContributorService,
    public booksService: ListBooksService,
    public titleService: Title) { }

  init() {
    this.getAllGenders();
    this.getAllReligions();
    this.getAllHeritages();
    this.getAllDisabilities();    
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  /**
   * Get all books associated with the given contributor id
   * @param {number} id "contributorId" of a ContributorPersonalInfo class
   */
  getBooksForContributor(id: number): void {
    this.booksService.getBooksForContributorId(id).subscribe(result => {
      if (result != undefined && result.length > 0) {
        this.contributorBooks = result;
      }
    });
  }

  /**
   * Get available contributor gender entries
   */
  getAllGenders(): void {
    this.contributorService.getContributorGenders().subscribe(genders => {
      if (genders != undefined && genders.length > 0) {
        this.allGenders = genders;
        this.assembleGenderOptions(genders);
      }

      if (this.contributor.contributorsGenders.length > 0) {
        for (let gender of this.contributor.contributorsGenders) {
          let temp = this.allGenders.find(entry => entry.id === gender.genderId);
          let data = {
            option: temp.id,
            displayText: temp.name
          };
          this.selectedGenders.push(data);
        }
        this.selectedGenders = this.selectedGenders.slice();
      }
    });
  }

    /**
   * Get available contributor religion entries
   */
  getAllReligions(): void {
    this.booksService.getAllReligions().subscribe(religions => {
      if (religions != undefined && religions.length > 0) {
        this.allReligions = religions;
        this.assembleReligionOptions(religions);
      }

      if (this.contributor.contributorsReligions.length > 0) {
        for (let religion of this.contributor.contributorsReligions) {
          let temp = this.allReligions.find(entry => entry.id === religion.religionId);
          let data = {
            option: temp.id,
            displayText: temp.name
          };
          this.selectedReligions.push(data);
        }

        this.selectedReligions = this.selectedReligions.slice();

      }
    });
  }

  /** 
   * Get available contributor heritage entries 
   */
  getAllHeritages(): void {
    this.contributorService.getContributorHeritages().subscribe(heritages => {
      if (heritages != undefined && heritages.length > 0) {
        this.allHeritages = heritages;
        this.assembleHeritageOptions(heritages);
      }

      if (this.contributor.contributorsContributorHeritages.length > 0) {
        for (let heritage of this.contributor.contributorsContributorHeritages) {
          let temp = this.allHeritages.find(entry => entry.id === heritage.contributorHeritageId);
          let data = {
            option: temp.id,
            displayText: temp.name
          };
          this.selectedHeritages.push(data);
        }

        this.selectedHeritages = this.selectedHeritages.slice();

      }
    });
  }

  /**
   * Get available contributor disability entries
   */
  getAllDisabilities(): void {
    this.booksService.getAllDisabilities().subscribe(disabilities => {
      if (disabilities != undefined && disabilities.length > 0) {
        this.allDisabilities = disabilities;
        this.assembleDisabilityOptions(disabilities);
      }

      if (this.contributor.contributorsDisabilities.length > 0) {
        for (let disability of this.contributor.contributorsDisabilities) {
          let temp = this.allDisabilities.find(entry => entry.id === disability.disabilityId);
          let data = {
            option: temp.id,
            displayText: temp.name
          };
          this.selectedDisabilities.push(data);
          this.selectedDisabilities = this.selectedDisabilities.slice();
        }
      }
    });
  }

  /**
   * Transform gender data into options for the multi-select component
   * @param {Array<Gender>} genders Array of Gender objects
   */
  assembleGenderOptions(genders: Array<Gender>): void {
    for (let gender of genders) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: gender.id,
        displayText: gender.name
      }
      this.genderOptions.push(data);
    }

    this.genderOptions = this.genderOptions.slice();

  }

    /**
   * Transform religion data into options for the multi-select component
   * @param {Array<Religion>} religions Array of Religion objects
   */
  assembleReligionOptions(religions: Array<Religion>): void {
    for (let religion of religions) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: religion.id,
        displayText: religion.name
      }
      this.religionOptions.push(data);
    }

    this.religionOptions = this.religionOptions.slice();

  }

  /**
   * Transform heritage data into options for the multi-select component
   * @param {Array<ContributorHeritage>} heritages Array of contributor heritage objects
   */
  assembleHeritageOptions(heritages: Array<ContributorHeritage>): void {
    for (let heritage of heritages) {
      let data = {
        option: heritage.id,
        displayText: heritage.name
      };
      this.heritageOptions.push(data);
    }

    this.heritageOptions = this.heritageOptions.slice();

  }

  /**
   * Transform disability data into options for the multi-select component
   * @param {Array<ContributorDisability>} disabilities Array of contributor disability objects
   */
  assembleDisabilityOptions(disabilities: Array<Disability>): void {
    for (let disability of disabilities) {
      let data = {
        option: disability.id,
        displayText: disability.name
      };
      this.disabilityOptions.push(data);
    }

    this.disabilityOptions = this.disabilityOptions.slice();

  }

  /**
   * Transform user's multi-select selections into data the back end will accept
   */
  updateGendersToSave(): void {
    // Transform multi-select data into book contributor genders
    let updatedGenders = new Array<ContributorsGenders>();
    this.selectedGenders.forEach(item => {
      let gender = new ContributorsGenders();
      gender.contributorId = this.contributor.id || 0;
      gender.genderId = item.option;
      updatedGenders.push(gender);
    });
    this.contributor.contributorsGenders = updatedGenders;
  }

    /**
   * Transform user's multi-select selections into data the back end will accept
   */
  updateReligionsToSave(): void {
    // Transform multi-select data into book contributor religions
    let updatedReligions = new Array<ContributorsReligions>();
    this.selectedReligions.forEach(item => {
      let religion = new ContributorsReligions();
      religion.contributorId = this.contributor.id || 0;
      religion.religionId = item.option;
      updatedReligions.push(religion);
    });
    this.contributor.contributorsReligions = updatedReligions;
  }

  /**
   * Transform user's multi-select selections into data the back end will accept
   */
  updateHeritagesToSave(): void {
    // Transform multi-select data into contributor heritages
    let updatedHeritages = new Array<ContributorsContributorHeritages>();
    this.selectedHeritages.forEach(item => {
      let heritage = new ContributorsContributorHeritages();
      heritage.contributorId = this.contributor.id || 0;
      heritage.contributorHeritageId = item.option;
      // heritage.contributorHeritage = this.allHeritages.find(entry => entry.id === item.option);
      updatedHeritages.push(heritage);
    });
    this.contributor.contributorsContributorHeritages = updatedHeritages;
  }

  /**
   * Transform user's multi-select selections into data the back end will accept
   */
  updateDisabilitiesToSave(): void {
    // Transform multi-select data into contributor disabilities
    let updatedDisabilities = new Array<ContributorsDisabilities>();
    this.selectedDisabilities.forEach(item => {
      let disability = new ContributorsDisabilities();
      disability.contributorId = this.contributor.id || 0;
      disability.disabilityId = item.option;
      // disability.contributorDisability = this.allDisabilities.find(entry => entry.id === item.option);
      updatedDisabilities.push(disability);
    });
    this.contributor.contributorsDisabilities = updatedDisabilities;
  }

  /**
   * Update user's new gender selections
   * @param {Array<IMultiSelectOptions>} genders Array of multi-select options for gender
   */
  setSelectedGenders(genders: Array<IMultiSelectOptions>): void {
    this.selectedGenders = genders;
    this.selectedGenders = this.selectedGenders.slice();
    this.updateGendersToSave();
  }

    /**
   * Update user's new religion selections
   * @param {Array<IMultiSelectOptions>} genders Array of multi-select options for gender
   */
  setSelectedReligions(religions: Array<IMultiSelectOptions>): void {
    this.selectedReligions = religions;
    this.selectedReligions = this.selectedReligions.slice();
    this.updateReligionsToSave();
  }

  /**
   * Update user's new heritage selections
   * @param {Array<IMultiSelectOptions>} heritages Array of multi-select options for heritage
   */
  setSelectedHeritages(heritages: Array<IMultiSelectOptions>): void {
    this.selectedHeritages = heritages;
    this.selectedHeritages = this.selectedHeritages.slice();
    this.updateHeritagesToSave();
  }

  /**
   * Update user's new disability selections
   * @param {Array<IMultiSelectOptions>} disabilities Array of multi-select options for gender
   */
  setSelectedDisabilities(disabilities: Array<IMultiSelectOptions>): void {
    this.selectedDisabilities = disabilities;
    this.selectedDisabilities = this.selectedDisabilities.slice();
    this.updateDisabilitiesToSave();
  }

  /////////////////////
  // EVENT LISTENERS //
  /////////////////////
  /**
   * Toggle mutually exclusive checkboxes to ensure only one value is saved. This is necessary 
   * because the respective properties in the data model are two separate properties, rather than 
   * a single property with a boolean value (disallowing HTML radio buttons).
   * @param {Event} event Event emitted by MatCheckbox
   */
  pretendToBeRadio(event): void {
    if (event.source.name === 'heritageContested' && event.checked) {
      this.contributor.verified = false;
    } else if (event.source.name === 'heritageVerified' && event.checked) {
      this.contributor.culturalHeritageContested = false;
    }
  }
}

/**
 * Component with behaviors and template unique to the add/edit contributors PAGE.
 */
@Component({
  selector: 'app-page-new-contributor',
  templateUrl: './page-new-contributor.component.html',
  styleUrls: ['./add-edit-contributor.component.scss']
})
export class PageNewContributorComponent extends AddEditContributorComponent implements OnInit {
  allContributors: Array<ContributorPersonalInfo>;
  isLoading: boolean;
  hasError: boolean;
  errorId: string;
  saveSuccessful: boolean;
  lastSavedDate: Date;

  constructor(
    public contributorService: ContributorService,
    public booksService: ListBooksService,
    public titleService: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(contributorService, booksService, titleService);
    this.isLoading = true;
  }

  ngOnInit() {
    super.init();
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0) {
      this.isNewContributor = true;
      this.contributor = new ContributorPersonalInfo();
      this.isLoading = false;
      this.setTitle('New contributor | CCBC Book Search');
    } else {
      // Get existing contributor info
      this.isNewContributor = false;
      this.setTitle('Edit contributor | CCBC Book Search');
      this.getContributor(id);
    }
  }

  /**
   * Look up the contributor with the given id
   * @param {number} id Contributor id received via route parameters
   */
  getContributor(id: number): void {
    this.contributorService.getContributors().subscribe(contributors => {
      this.contributor = contributors.find(entry => entry.id === id);
      if (this.contributor != undefined) {
        this.getBooksForContributor(id);
        this.updateExistingSelections();
      } else {
        this.hasError = true;
        this.isLoading = false;
        this.errorId = this.route.snapshot.paramMap.get('id');
        console.info(`couldn\'t find a contributor with id: ${id}`);
      }
    });
  }

  /**
   * Transform contributor's existing array-type attributes into multi-select options
   * to be displayed, then stop "loading"
   */
  updateExistingSelections(): void {
    if (this.contributor.contributorsGenders.length > 0) {
      for (let gender of this.contributor.contributorsGenders) {
        let temp = this.allGenders.find(entry => entry.id === gender.genderId);
        let data = {
          option: temp.id,
          displayText: temp.name
        };
        this.selectedGenders.push(data);
        this.selectedGenders = this.selectedGenders.slice();
      }
    }
    if (this.contributor.contributorsReligions.length > 0) {
      for (let religion of this.contributor.contributorsReligions) {
        let temp = this.allReligions.find(entry => entry.id === religion.religionId);
        let data = {
          option: temp.id,
          displayText: temp.name
        };
        this.selectedReligions.push(data);
        this.selectedReligions = this.selectedReligions.slice();
      }
    }
    if (this.contributor.contributorsContributorHeritages.length > 0) {
      for (let heritage of this.contributor.contributorsContributorHeritages) {
        let temp = this.allHeritages.find(entry => entry.id === heritage.contributorHeritageId);
        let data = {
          option: temp.id,
          displayText: temp.name
        };
        this.selectedHeritages.push(data);
        this.selectedHeritages = this.selectedHeritages.slice();
      }
    }
    if (this.contributor.contributorsDisabilities.length > 0) {
      for (let disability of this.contributor.contributorsDisabilities) {
        let temp = this.allDisabilities.find(entry => entry.id === disability.disabilityId);
        let data = {
          option: temp.id,
          displayText: temp.name
        };
        this.selectedDisabilities.push(data);
        this.selectedDisabilities = this.selectedDisabilities.slice();
      }
    }

    this.isLoading = false;
  }

  /**
   * Save user's changes
   */
  saveContributor(): void {
    this.contributorService.saveContributor(this.contributor)
      .subscribe(contributor => {
        this.lastSavedDate = new Date();
        this.saveSuccessful = true;
        this.isNewContributor = false;
        this.contributor.id = contributor.id
        this.router.navigate(['/contributor/' + contributor.id]);
        
      });
  }
}

/**
 * Behaviors and template unique to the add/edit contributors MODAL
 */
@Component({
  selector: 'app-dialog-new-contributor',
  templateUrl: './dialog-new-contributor.component.html',
  styleUrls: ['./add-edit-contributor.component.scss']
})
export class DialogNewContributorComponent extends AddEditContributorComponent implements OnInit {

  constructor(
    public contributorService: ContributorService,
    public booksService: ListBooksService,
    public titleService: Title,
    public dialogRef: MatDialogRef<DialogNewContributorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super(contributorService, booksService, titleService);
    this.dialogRef.backdropClick().subscribe(() => {
      this.isCanceled = true;
    });

    this.dialogRef.beforeClose().subscribe(() => {
      if (!this.isCanceled) {
        this.dialogRef.close(this.contributor)
      } else {
        this.dialogRef.close(-1);
      }
    });
  }

  ngOnInit() {
    super.init();
    this.isNewContributor = this.data.isNewContributor;
    this.contributor = this.data.contributor;
    
    if (!this.isNewContributor) {
      this.getBooksForContributor(this.contributor.id);     
    }
  }

  /**
   * Close the dialog to return altered data
   */
  setNewContributor(): void {
    this.dialogRef.close();
  }

  /**
   * Close the dialog with the cancellation flag
   */
  closeDialog(): void {
    this.isCanceled = true;
    this.dialogRef.close();
  }


}
