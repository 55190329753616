import { Component, OnInit } from '@angular/core';
import {ListBooksService} from '../../services/list-books.service';

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss']
})
export class GenerateReportComponent implements OnInit {

  constructor(private bookService: ListBooksService) { }

  loading: boolean = false;

  ngOnInit() {
    this.loading = false;
  }

  doGetReport() {
    if (!this.loading) {
      this.loading = true;

      this.bookService.getReport().subscribe(data => this.convertResultToFile(data), 
      error => console.log('Error downloading the file.', error),
      () => console.info('OK')).add(() => this.loading = false);      

    }
  }

  convertResultToFile(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);   
    const element = document.createElement('a');
    element.href = url;
    element.download = 'CCBC Report.csv';
    document.body.appendChild(element);
    element.click();
  }
}
