
import {Book} from './Book';
import { BibliographyCategoryList } from './BibliographyCategoryList';

export class Bibliography {
    
    constructor() {
        this.id = 0;

        this.bibliographiesAgeGrades = new Array<BibliographiesAgeGrades>();
        this.nestedCategories = new Array<NestedCategories>();
       
    }
    
    id: number;
    name: string; //required, maxlegnth 255
    introduction: string;  //maxlength 2000

    bibliographiesAgeGrades: Array<BibliographiesAgeGrades>;
    nestedCategories: Array<NestedCategories>;

   
}


export class BibliographyAgeGrade {
    ageGradeId: number;
    name: string;

    selected: boolean;
}


export class BibliographiesAgeGrades {
    constructor() {}
    
    bibliographyId: number;
    bibliographyAgeGradeId: number;


    bibliographyAgeGrade: BibliographyAgeGrade;
}

export class NestedCategories {
    constructor() {
        this.id = 0;

        this.booksForBibliographyView = new Array<BookForBibliographyView>();
        this.children = new Array<NestedCategories>();
        this.showBooks = false;
    }

    id: number;
    description: string;
    order: number;

    //local properties
    showBooks: boolean;

    booksForBibliographyView: Array<BookForBibliographyView>;
    children: Array<NestedCategories>;

}

export class BookForBibliographyView {
    bookId: number;
    bibliographyCategoryId: number;

    title: string;
    authors: string;
    year: number;

    remove: boolean = false;

}

export class BookForBibliographyAdd {
    bookId: number;
    title: string;
    authors: string;
    year: number;

    add: boolean = false;
}
