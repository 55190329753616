import { Book } from 'src/app/classes/Book';

export class Publisher {
    publisherId: number;
    name: string;
}

export class BookPublishers {
    bookId: number;
    publisherId: number;
    publisher: Publisher;
}