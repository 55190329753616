import { Injectable } from '@angular/core';

@Injectable()
export class AuthHeaderService {

  constructor() { }

  getAuthorizationHeader() : string {
    const token = localStorage.getItem('bearerToken');
    if (token == null) {
      return ``;
    }
    return `bearer ${token}`;
  }

  clearAuthorizationHead() : void {
    localStorage.removeItem('bearerToken');
  }
}
