import { Contributor } from 'src/app/classes/Contributor';
import { BookPublishers } from 'src/app/classes/Publisher';

/*Diversity subject + Secondary diversity + GenderMarker */
import { SubjectDetail } from "src/app/classes/SubjectDetail";

import { Heritage } from "src/app/classes//Heritage";
import { BooksHeritages } from "src/app/classes/BooksHeritages";

import { Disability } from "src/app/classes//Disability";
import { BooksDisabilities } from "src/app/classes//BooksDisabilities";

import { Religion } from "src/app/classes//Religion";
import { BooksReligions } from "src/app/classes/BooksReligions";

import { Lgbtq } from "src/app/classes/Lgbtq";
import { BooksLgbtqs } from "src/app/classes/BooksLgbtqs";
//secondary diversity
import { Secondary } from "src/app/classes/Secondary";
import { BooksSecondaries } from "src/app/classes/BooksSecondaries";
//genderMarker
import { GenderMarkerLookup } from "src/app/classes/GenderMarkerLookup";
import { BooksGenderMarkers} from "src/app/classes/BooksGenderMarkers";
/*END of Diversity subject + Secondary diversity + GenderMarker*/

import { Character } from "./character";

import { Recommendation } from "src/app/classes/Recommendation";
import { BooksCategories } from 'src/app/classes/BooksCategories';

export class Book {

    constructor() {
        this.id = 0;
        this.bookContributors = new Array<Contributor>();
        this.bookPublishers = new Array<BookPublishers>();
        this.bookGenres = new Array<BookGenre>();

        /*diversity subject */
        this.booksHeritages =new Array<BooksHeritages>();
        this.booksReligions = new Array<BooksReligions>();
        this.booksLgbtqs = new Array<BooksLgbtqs>();
        this.booksDisabilities =new Array<BooksDisabilities>();
        this.booksSecondaries = new Array<BooksSecondaries>();
        this.booksGenderMarkers = new Array<BooksGenderMarkers>();
        /*End of diversity subject */

        this.subjectDetail = new SubjectDetail();
        this.characters = new Array<Character>();
  
        this.recommendation = new Recommendation();

        //books Category 
        this.booksCategories = new Array<BooksCategories>();

    }

    id : number;
    title : string;   //required, maxlength 255    
    isbn : string; //required, maxlength 15
    alttitle1 : string; //not required, maxlength 255
    alttitle2 : string; //not required, maxlength 255
    alttitle3 : string; //not required, maxlength 255
    alttitle4 : string; //not required, maxlength 255

    // contributor section
    bookContributors: Array<Contributor>;

    /*diversity subject + gender marker + secondary diversity */
    subjectDetail: SubjectDetail;
   
    //availableHeritages:  Array<Heritage>;
    booksHeritages:  Array<BooksHeritages>;

    //availableReligions: Array<Religion>;
    // publisher section
    year : number; //required, integer
    nonUsPublisher : boolean;
    reIssue : boolean;
    newEdition : boolean;
    bookPublishers: Array<BookPublishers>;
    booksReligions: Array<BooksReligions>;

    //availableLgbtqs: Array<Lgbtq>;
    booksLgbtqs: Array<BooksLgbtqs>;

    //availableDisabilities:  Array<Disability>;
    booksDisabilities:  Array<BooksDisabilities>;
    //secondary diveristy
    //availableSecondaries:  Array<Secondary>;
    booksSecondaries:  Array<BooksSecondaries>;
    //gender marker
    genderMarker: GenderMarkerLookup;
    genderMarkerNotes: string; //maxlength 255
    booksGenderMarkers: Array<BooksGenderMarkers>;
    availableGenderMarkers: Array<GenderMarkerLookup>;
    /*End of diversity subject + gender marker + secondary diversity */

    //Collection
    ccbcCollection : string; //required, maxlength 100, select [Picture Book, Fiction, NonFiction]
    callNumber : string; //required, maxlength 100   
    bookGenres: Array<BookGenre>;
    originallyPublishedIn : string;  //year 1st published - this is a string in db for some strange reason...

    characters: Array<Character>;

    //Translated
    translated: boolean;
    translatedNotes: string; //maxlength 255

    recommendation: Recommendation;

    booksCategories: Array<BooksCategories>;
}


export class BookGenre {
    bookId: number;
    genreId: number;
    genre: Genre;
    //book: Book;
    constructor() {
        this.bookId;
        this.genreId = null;
    }
}

export class Genre {
    id: number;
    type: string;
    displayName: string;
    constructor() {
        this.id = null;
        this.type = null;
        this.displayName = null;
    }
}

export const CCBC_COLLECTION_OPTIONS: string[] = [
    'Fiction',
    'Non-Fiction',
    'Picture Book'
];