export class BooksList {
    constructor (item : BooksList) {          
       this.id = item.id;
       this.title = item.title;
       this.year = item.year;
       this.authors = item.authors;
       this.publishers = item.publishers;
       this.isRecommended = item.isRecommended;
    }

    id : number;
    title : string;
    publishers : string;
    year : number;
    authors: string;
    isRecommended: boolean;
    total : number;
    books: Array<BooksList>;
}