import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Bibliography, BibliographyAgeGrade, BibliographiesAgeGrades, BookForBibliographyAdd } from 'src/app/classes/Bibliography';
import { BibliographyListView } from '../classes/BibliographyListView';
import { BookBibliography} from '../classes/BookBibliography';
import { BibliographyList } from 'src/app/classes/BibliographyList';

@Injectable({
  providedIn: 'root'
})
export class BibliographyService {

  private hostName: string;
  private bibliographyUrlBase = '/api/Bibliography'; 

  constructor(
    private http: HttpClient
  ) {
    this.hostName = environment.apiHost;
  }

    /**
     * Gets a single bibliography matching on the provided id
     * @param {number} id The bibliography's id number
     */
    getBibliographyById(id : number | null) : Observable<Bibliography> {
      return this.http.get<Bibliography>(this.hostName+this.bibliographyUrlBase+'/GetByIdAsync/'+id.toString());
    }



    /* Get  bibliography age grades for checkboxes*/
    getAllAgeGrades() : Observable<BibliographyAgeGrade[]> {
      return this.http.get<BibliographyAgeGrade[]>(this.hostName+this.bibliographyUrlBase+'/AvailableBibliographyAgeGrades')
        .pipe();
    }


    /**
     * Update a bibliography with the given id, or create a new bibliography if the id equals zero.
     * @param {Bibliography} bibliography The bibliography's updated information
     */
    saveBibliography(bibliography: Bibliography): Observable<Bibliography> {
      console.info("saving bibliography: ", bibliography);
      if(bibliography.id === 0 || bibliography.id === undefined) {      
        return this.http.post<Bibliography>(this.hostName+this.bibliographyUrlBase+"/CreateBibliography", bibliography);
      } else {
        return this.http.post<Bibliography>(this.hostName+this.bibliographyUrlBase+"/UpdateBibliography", bibliography);
      }
    }


    /* search for books by title to add to a bibliography category */
    searchForBooks(text: string): Observable<Array<BookForBibliographyAdd>> {
      let searchParameters = {
        searchText: text
      };
      return this.http.post<Array<BookForBibliographyAdd>>(this.hostName+this.bibliographyUrlBase+"/SearchForBooksByTitle", searchParameters);
    }

    /*get all bibliography with book numbers*/
    getAllBibliography(): Observable<Array<BibliographyListView>> {
      return this.http.get<Array<BibliographyListView>>(this.hostName+this.bibliographyUrlBase+"/GetAllBibliography");
    }

    /**
     * Get bibliography for this book
     * @param id 
     */
    getBookBibliographyById(id : number | null) : Observable<Array<BookBibliography>> {
      return this.http.get<BookBibliography[]>(this.hostName+this.bibliographyUrlBase+'/GetBookBibliography/'+id.toString());
    }

    getAllBibliographyCategory() :Observable<Array<BibliographyList>> {
      return this.http.get<BibliographyList[]>(this.hostName+this.bibliographyUrlBase+"/GetAllBibliographyCategory");
    }

    /**
     * Delete the bibliography with the provided id
     * @param {number} id The bibliography's id number
     */
    deleteBibliographyById(id: number | null): Observable<boolean> {
      return this.http.delete<boolean>(this.hostName+this.bibliographyUrlBase+'/'+id.toString());
    }

}
