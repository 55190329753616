<mat-card class="card__list-books">
    <div class="row">
        <div class="col-10">
            <span class="title">All Books</span>
          </div>
          <div class="col text-right">
             <button mat-flat-button color="accent"
                [routerLink]="['/book', 0]">
                <mat-icon>add</mat-icon>Add Book
            </button>
          </div>
    </div>
    <mat-card-content>
        <nav aria-label="page navigation">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                    <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                        (click)="$event.preventDefault();updatePage(1)">First</a>
                </li>
                <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                    <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                        (click)="$event.preventDefault();updatePage(pager.currentPage - 1)">Previous</a>
                </li>
                <li *ngFor="let page of pager.pages" class="page-item"
                    [ngClass]="{'active': pager.currentPage === page}">
                    <a class="page-link" href="#" (click)="$event.preventDefault();updatePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                    <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                        (click)="$event.preventDefault();updatePage(pager.currentPage + 1)">Next</a>
                </li>
                <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                    <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                        (click)="$event.preventDefault();updatePage(pager.totalPages)">Last</a>
                </li>
            </ul>
        </nav>
        <table class="table table-bordered table-striped">
            <col width="8%">
            <col width="28%">
            <col width="20%">
            <col width="20%">
            <col width="8%">
            <col width="8%">
            <col width="8%">
            <thead class="thead-light">
                <tr>
                    <th scope="col" class="cell-centered">Edit</th>
                    <th scope="col" class="sortable" (click)="sortBy('title')" role="columnheader" [attr.aria-sort]="ariaSort.title"
                        (keydown)="onKey($event, 'title')" tabindex="0" aria-label="sort by title">
                        <div class="flex">
                            <span>Title</span>
                            <span *ngIf="order.property==='title' && order.ascending">&#9652;</span>
                            <span *ngIf="order.property==='title' && !order.ascending">&#9662;</span>
                            <span *ngIf="order.property!=='title'" class="greyedOut">&#9652;</span>
                        </div>
                    </th>
                    <th scope="col" class="sortable" (click)="sortBy('author')" role="columnheader" [attr.aria-sort]="ariaSort.author"
                        (keydown)="onKey($event, 'author')" tabindex="0" aria-label="sort by author first name">
                        <div class="flex">
                            <span>Author(s)</span>
                            <span *ngIf="order.property==='author' && order.ascending">&#9652;</span>
                            <span *ngIf="order.property==='author' && !order.ascending">&#9662;</span>
                            <span *ngIf="order.property!=='author'" class="greyedOut">&#9652;</span>
                        </div>
                    </th>
                    <th scope="col" class="sortable" (click)="sortBy('publishers')" role="columnheader" [attr.aria-sort]="ariaSort.publishers"
                        (keydown)="onKey($event, 'publishers')" tabindex="0" aria-label="sort by publishers">
                        <div class="flex">
                            <span>Publisher(s)</span>
                            <span *ngIf="order.property==='publishers' && order.ascending">&#9652;</span>
                            <span *ngIf="order.property==='publishers' && !order.ascending">&#9662;</span>
                            <span *ngIf="order.property!=='publishers'" class="greyedOut">&#9652;</span>
                        </div>
                    </th>
                    <th scope="col" (click)="sortBy('year')" class="cell-centered sortable" role="columnheader" [attr.aria-sort]="ariaSort.year"
                        (keydown)="onKey($event, 'year')" tabindex="0" aria-label="sort by year">
                        <div class="flex">
                            <span>Year</span>
                            <span *ngIf="order.property==='year' && order.ascending">&#9652;</span>
                            <span *ngIf="order.property==='year' && !order.ascending">&#9662;</span>
                            <span *ngIf="order.property!=='year'" class="greyedOut">&#9652;</span>
                        </div>
                    </th>
                    <th scope="col" (click)="sortBy('isRecommended')" (keydown)="onKey($event, 'isRecommended')" tabindex="0" 
                        aria-label="sort by recommended" role="columnheader" [attr.aria-sort]="ariaSort.isRecommended"
                        class="cell-centered sortable">
                        <div class="flex">
                            <span>Recommended</span>
                            <span *ngIf="order.property==='isRecommended' && order.ascending">&#9652;</span>
                            <span *ngIf="order.property==='isRecommended' && !order.ascending">&#9662;</span>
                            <span *ngIf="order.property!=='isRecommended'" class="greyedOut">&#9652;</span>
                        </div>
                    </th>
                   
                    <th scope="col" class="cell-centered">Delete</th>
                </tr>
            </thead>
            <tbody>

                <tr [hidden]="!loading" [@hideShowAnimation]>
                    <td colspan="7">
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let book of pagedBooks" [hidden]="loading" [@hideShowAnimation]>
                    <td class="cell-centered"><button mat-stroked-button color="accent"
                            routerLink="/book/{{book.id}}">Edit</button></td>
                    <td [innerHtml]="book.title"></td>
                    <td [innerHtml]="book.authors"></td>
                    <td [innerHtml]="book.publishers"></td>
                    <td [innerHtml]="book.year" class="cell-centered"></td>
                    <td class="cell-centered">
                        <span *ngIf="book.isRecommended">Yes</span>
                        <span *ngIf="!book.isRecommended">No</span>
                    </td>
                    
                    <td class="cell-centered"><button mat-stroked-button mat-icon-button color="primary"
                            (click)="confirmDelete(book)" aria-label="delete book">
                            <mat-icon>delete_forever</mat-icon>
                        </button></td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>