import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthHeaderService } from '../services/auth-header.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthHeaderService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = this.auth.getAuthorizationHeader();
    if (authHeader !== ``) {
      // Clone the request to add the new header.
      req = req.clone({ headers: req.headers.set('Authorization', authHeader) });
    }

    return next.handle(req)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.auth.clearAuthorizationHead(); //The token is no longer valid, clear it
            this.router.navigate(['/login']); //This may cause issues if we ever want the user to enter credentials on a different screen
          }
        }

        // Replaced Observable.throw() (deprecated in rxjs version 6) with throwError() 
         return throwError(err);
      }));

  }
}