
export class Recommendation {

    constructor() {
        this.ccbcAgeRecommendation = null;
        this.isRecommended = false;
        this.coverImage = null;
        this.coverImageContentType = null;
        this.ccbcChoicesAnnotation = null;

        this.recommendationsAgeGradeRanges = new Array<RecommendationsAgeGradeRange>();
        this.recommendationsContentTypes = new Array<RecommendationsContentType>();
        this.recommendationsFormats = new Array<RecommendationsFormat>();
        this.recommendationsCCBCSubjects = new Array<RecommendationsCCBCSubject>();
        this.recommendationsWisconsinInterests = new Array<RecommendationsWisconsinInterest>();

        this.newBookCover = null;
    }
     

    bookId: number;
    ccbcAgeRecommendation: string;
    isRecommended:boolean;
    coverImage:string;
    coverImageContentType:string;
    ccbcChoicesAnnotation: string;

    recommendationsAgeGradeRanges: Array<RecommendationsAgeGradeRange>;
    recommendationsContentTypes: Array<RecommendationsContentType>;
    recommendationsFormats:  Array<RecommendationsFormat>;
    recommendationsCCBCSubjects:  Array<RecommendationsCCBCSubject>;
    recommendationsWisconsinInterests:  Array<RecommendationsWisconsinInterest>;

    //for uploaded new cover image
    newBookCover:File;
}

export class AgeGradeRange {
    ageGradeRangeId: number;    
    name: string;
    selected:boolean;      
}

export class RecommendationsAgeGradeRange {
    
    constructor() {}
    
    bookId: number;
    ageGradeRangeId: number;

    ageGradeRange: AgeGradeRange;
    recommendation: Recommendation;
}

export class CCBCSubject {
    ccbcSubjectId: number;    
    name: string;
    selected: boolean;
}

export class RecommendationsCCBCSubject {

    constructor() {}

    bookId: number;
    ccbcSubjectId: number;

    ccbcSubject: CCBCSubject;
    recommendation: Recommendation;
}

export class ContentType {
    contentTypeId: number;    
    name: string;
    selected:boolean;
}

export class RecommendationsContentType {

    constructor() {}

    bookId: number;
    contentTypeId: number;

    contentType: ContentType;
    recommendation: Recommendation;
}

export class Format {
    formatId: number;    
    name: string;
    selected: boolean;
}

export class RecommendationsFormat {

    constructor() {}

    bookId: number;
    formatId: number;

    format: Format;
    recommendation: Recommendation;
}

export class WisconsinInterest {
    id: number;    
    name: string;
    selected: boolean;
}

export class RecommendationsWisconsinInterest {

    constructor() {}

    bookId: number;
    wisconsinInterestId: number;

    wisconsinInterest: WisconsinInterest;
    recommendation: Recommendation;
}