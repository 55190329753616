import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { ContributorService } from 'src/app/services/contributor.service';
import { ListBooksService } from 'src/app/services/list-books.service';
import { PagerService } from 'src/app/services/pager.service';

// Pipes
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { SearchFilterPipe } from 'src/app/pipes/search-filter.pipe';

// Classes
import { ContributorPersonalInfo } from 'src/app/classes/Contributor';
import { Book } from 'src/app/classes/Book';

@Component({
    selector: 'app-list-contributors',
    templateUrl: './list-contributors.component.html',
    styleUrls: ['./list-contributors.component.scss']
})
export class ListContributorsComponent implements OnInit {

    @ViewChildren('allContributors') contributorsList: QueryList<any>;

    public allContributors: Array<ContributorPersonalInfo> = new Array<ContributorPersonalInfo>();
    public contributors: Array<ContributorPersonalInfo> = new Array<ContributorPersonalInfo>();
    public books: Array<Book> = new Array<Book>();
    public loading: boolean = true;
    public fragment: string;
    public filterText: string;
    public isNoResults: boolean = false;

    public pager: any = {};
    public total: number = 0;

    public take: number = 30;
    public takes: Array<number> = [30, 50, 100];
    public takeButtons: Array<HTMLSpanElement> = new Array<HTMLSpanElement>();

    public selectedPage: number = 1;
    public selectedOrderBy = {
        property: 'lastName',
        ascending: true
    };

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router,
        private pagerService: PagerService,
        private contributorService: ContributorService,
        private booksService: ListBooksService,
        private orderBy: OrderByPipe,
        private searchFilter: SearchFilterPipe) { }

    /**
     * Get the selected page from the URL, load all contributors and other view items
     */
    ngOnInit() {
        this.selectedPage = +this.route.snapshot.paramMap.get('page');
        this.contributorService.getContributors().subscribe(contributors => {
            this.allContributors = contributors;
            this.total = contributors.length;
            this.getPages();
        });
        this.titleService.setTitle('Contributors | CCBC Book Search');
    }

    /**
     * Set up top and bottom take controls (results per page)
     */
    ngAfterViewInit() {
        this.takes.forEach(item => {
            let takeButton: HTMLSpanElement = document.getElementById('take' + item);
            let takeButtonBottom: HTMLSpanElement = document.getElementById('take' + item + 'Bottom');
            if (takeButton != undefined) {
                this.takeButtons.push(takeButton);
            }
            if (takeButtonBottom != undefined) {
                this.takeButtons.push(takeButtonBottom);
            }
        });
    }

    /**
     * Determine how many pages the list of contributors should be broken up into depending 
     * on the total number of contributors, the currently selected page, and the number of results
     * to display per page.
     */
    getPages(): void {
        this.pager = this.pagerService.getPager(this.total, this.selectedPage, this.take);

        // Update path to enable linking
        this.selectedPage = this.pager.currentPage;
        this.router.navigate(['/contributors/' + this.selectedPage]);

        // Order contributors based on selection
        this.orderBy.transform(this.allContributors, this.selectedOrderBy.property, this.selectedOrderBy.ascending);

        // Show requested contributors
        this.contributors = this.allContributors.slice(this.pager.startIndex, this.pager.endIndex);

        // Set active per-page selection
        this.takeButtons.forEach(el => {
            if (el.id.includes(this.take.toString())) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
        this.loading = false;
    }

    /**
     * Clear filters and navigate to another page when pagination controls are clicked
     * @param {number} page The page number to display 
     */
    updatePage(page: number): void {
        this.filterText = '';
        this.selectedPage = page;
        this.getPages();
    }

    /////////////////////
    // EVENT LISTENERS //
    /////////////////////
    /**
     * Get books associated with the given contributor id
     * @param {number} id The id of the contributor in the row that was clicked 
     */
    getContributorBooks(id: number): void {
        this.booksService.getBooksForContributorId(id).subscribe(result => {
            if (result != undefined && result.length > 0) {
                this.books = result;
            }
        });

        let panelTitle = document.getElementById('panelTitle' + id);        
        if (panelTitle != undefined) {
            panelTitle.classList.add('opened');
        }
    }

    /**
     * Close the expanded "books" panel
     * @param {number} id Id of the clicked expansion panel 
     */
    closePanel(id: number): void {
        let panelTitle = document.getElementById('panelTitle' + id);
        if (panelTitle != undefined) {
            panelTitle.classList.remove('opened');
        }
    }

    /**
     * Set the number of items to display per page
     * @param {number} take The number of items to display as selected by the user
     */
    setTake(take: number): void {
        this.filterText = '';
        this.take = take;
        this.getPages();
    }

    /**
     * Rearrange list of all contributors by first or last name, depending on which button the user clicked to fire the event
     * @param {string} orderColumn Order by contributor lastName or firstName
     */
    setOrderBy(orderColumn: string): void {
        if (orderColumn === this.selectedOrderBy.property) {
            this.selectedOrderBy.ascending = !this.selectedOrderBy.ascending;
        }
        this.selectedOrderBy.property = orderColumn;
        this.updatePage(1);
        this.getPages();
    }

    /**
     * Filter all contributors by text in the filter input (fires whenever filterText changes)
     */
    filterAllContributors(): void {
        this.contributors = this.searchFilter.transform(this.allContributors, this.filterText);
        if (this.contributors.length === 0) {
            this.isNoResults = true;
        } else {
            this.isNoResults = false;
        }
    }
}