import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
// Components
import { DialogContributorsComponent } from 'src/app/components/dialog-contributors/dialog-contributors.component';
import { DialogPublishersComponent } from 'src/app/components/dialog-publishers/dialog-publishers.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IMultiSelectOptions } from '../multi-select/multi-select.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogNewContributorComponent } from '../add-edit-contributor/add-edit-contributor.component';
import { DialogAssignBibliographyComponent} from '../dialog-assign-bibliography/dialog-assign-bibliography.component';
// Classes
import { Book, Genre, BookGenre, CCBC_COLLECTION_OPTIONS } from 'src/app/classes/Book';
import { Contributor, ContributorPersonalInfo } from 'src/app/classes/Contributor';
import { Publisher, BookPublishers } from 'src/app/classes/Publisher';
import { BookBibliography } from 'src/app/classes/BookBibliography';
import { BooksCategories} from 'src/app/classes/BooksCategories';

// Services
import { ListBooksService } from 'src/app/services/list-books.service';
import { ContributorService } from 'src/app/services/contributor.service';
import { Recommendation } from 'src/app/classes/Recommendation';
import { BibliographyService } from 'src/app/services/bibliography.service';
import { Character } from 'src/app/classes/character';
import { throwError } from 'rxjs';



/**
 * Allows editing of all information about a single book.
 */
@Component({
  selector: 'app-edit-book',
  animations: [
    trigger('hideShowAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('0s', style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './edit-book.component.html',
  styleUrls: ['./edit-book.component.scss']
})
export class EditBookComponent implements OnInit {

  // Local variables
  hasGetBookError: boolean = false;
  errorId: string;
  book: Book | null;

  // We'll split book.characters using the characters.isSecondaryCharacter property
  // so the template can loop over them seperately
  bookPrimaryCharacters: Array<Character> = new Array<Character>();
  bookSecondaryCharacters: Array<Character> = new Array<Character>();

  isNewBook: boolean = false;
  richTextHasError: boolean = false;
  bookForm: FormGroup;
  loading: boolean = true;
  saveSuccessful: boolean = false;
  saveError: boolean = false;
  lastSavedDate: Date;
  isLoadingContributors: boolean = true;

  isPrimary: string;
  isSecondary: string;
  // Contributor section
  contributorsMarkedForDeletion: Array<Contributor>;
  contributorTypes: Array<string>;
  allContributors: Array<ContributorPersonalInfo>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  // Publishers section
  allPublishers: Array<Publisher> = new Array<Publisher>();
  publisherOptions: Array<any> = new Array<any>();
  selectedPublishers: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  publishersToSave: Array<BookPublishers> = new Array<BookPublishers>();
  newPublisher: Publisher = new Publisher();

  // Collection section
  ccbcCollectionOptions = CCBC_COLLECTION_OPTIONS;
  allGenres: Array<Genre> = new Array<Genre>();
  genreOptions: Array<any> = new Array<any>();
  selectedGenres: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  genresToSave: Array<BookGenre> = new Array<BookGenre>();

  //bibliography
  bookBibliographies: Array<BookBibliography> =new Array<BookBibliography>(); 
  bookBibliographiesToBeDeleted: Array<BookBibliography> = new Array<BookBibliography>();  
 

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private bookService: ListBooksService,
    private contributorService: ContributorService,
    private bibliographyService: BibliographyService,
    private titleService: Title
  ) { }

  //stuff to get tinyMce to work with tabs
  public activeTabIndex: number = 0;

  handleTabChange(e: MatTabChangeEvent) {
    this.activeTabIndex = e.index;
  }


  ngOnInit() {
    this.contributorsMarkedForDeletion = new Array<Contributor>();
    this.contributorTypes = new Array<string>();
    this.allContributors = new Array<ContributorPersonalInfo>();
    this.getBook();
    this.getAllContributors();
    this.getContributorTypes();
    this.getAllGenres();
    this.isPrimary = "Pri"; //to avoid duplicate name 
    this.isSecondary = "Sec"; //    
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  /**
   * Gets the requested book and binds to the view.
   */
  getBook(): void {
    // Get book ID from route and cast as number
    const id: number = +this.route.snapshot.paramMap.get('id');
    if (id === 0) {
      this.book = new Book();
      this.extractPrimaryAndSecondaryCharactersForDisplay(); // split the characters array into primary & secondary arrays for display purposes
      this.isNewBook = true;
      this.loading = false;
      this.setTitle('New book | CCBC Book Search');
      this.transformPublishers([]);
    } else {
      this.setTitle('Edit book | CCBC Book Search');
      // Get the requested book details
      this.bookService.getBookById(id).subscribe(book => {
        this.book = book;
        this.extractPrimaryAndSecondaryCharactersForDisplay(); // split the characters array into primary & secondary arrays for display purposes
        this.loading = false;
        this.publishersToSave = book.bookPublishers;
        this.transformPublishers(book.bookPublishers);
        this.genresToSave = book.bookGenres;
        this.transformGenres(book.bookGenres);

        if (this.book.recommendation === null) {
          this.book.recommendation = new Recommendation();
          this.book.recommendation.bookId = this.book.id;
        }

      }, err => {
        this.loading = false;
        this.hasGetBookError = true;
        this.errorId = this.route.snapshot.paramMap.get('id');
        this.book = new Book();
      });

      //if loading book completes without error, load bookbibliography if any

      if (!this.hasGetBookError && this.loading && !this.isNewBook)
      {
          this.bibliographyService.getBookBibliographyById(id).subscribe(bioblio=>{
             this.bookBibliographies = bioblio;
          });
         
      }
    }
  }

  // Split the single array of characters from the book model
  // into seperate arrays for primary and secondary characters.
  // Also, add empty placeholder primary & secondary characters for display purposes
  extractPrimaryAndSecondaryCharactersForDisplay(){

    this.bookPrimaryCharacters = new Array<Character>();
    this.bookSecondaryCharacters = new Array<Character>();

    // Primary Characters
    let tempPrimaryCharacters = this.book.characters.filter(character => character.isSecondaryCharacter === false);

    // How many primary characters should we display for this book?
    // We'll always show 5 primary characters, but can show more if the book has more
    let numPrimaryCharactersToShow = tempPrimaryCharacters.length > 5 ? tempPrimaryCharacters.length : 5;

    for(let i = 0; i < numPrimaryCharactersToShow; i++)
    {
      // If we don't have 5 characters, and we don't have a character with a sort order at or below this position (i)
      if(tempPrimaryCharacters.length < 5 && tempPrimaryCharacters.filter(c => c.sortOrder <= i + 1).length == 0)
      {
          // Inject a placeholder character for this position
        let placeholderCharacter = new Character();
        placeholderCharacter.isSecondaryCharacter = false;
        placeholderCharacter.bookId = this.book.id;
        
        this.bookPrimaryCharacters.push(placeholderCharacter);

      }
      else
      {
          // Else shift the next character into our list to display
          this.bookPrimaryCharacters.push(tempPrimaryCharacters.shift());
      }

    }

    // Secondary Characters
    let tempSecondaryCharacters = this.book.characters.filter(character => character.isSecondaryCharacter === true);

    // How many secondary characters should we display for this book?
    // We'll always show 5 secondary characters, but can show more if the book has more
    let numSecondaryCharactersToShow = tempSecondaryCharacters.length > 5 ? tempSecondaryCharacters.length : 5;
   
    for(let i = 0; i < numSecondaryCharactersToShow; i++)
    {
      // If we don't have 5 characters, and we don't have a character with a sort order at or below this position (i)
      if(tempSecondaryCharacters.length < 5 && tempSecondaryCharacters.filter(c => c.sortOrder <= i + 1).length == 0)
      {
          // Inject a placeholder character for this position
        let placeholderCharacter = new Character();
        placeholderCharacter.isSecondaryCharacter = true;
        placeholderCharacter.bookId = this.book.id;
        
        this.bookSecondaryCharacters.push(placeholderCharacter);

      }
      else
      {
          // Else shift the next character into our list to display
          this.bookSecondaryCharacters.push(tempSecondaryCharacters.shift());
      }

    }


  }

  // Merge and transform the separate primary & secondary character arrays used by the template
  // into a single characters array for saving
  mergePrimaryAndSecondaryCharactersForSave(): Character[]
  {
    let mergedCharacters = new Array<Character>();

    for(let i = 0; i < this.bookPrimaryCharacters.length; i++)
    {
      // If any fields filled in
      if(!this.isCharacterEmpty(this.bookPrimaryCharacters[i]))
      {
         // Apply an incrementing sort order
        this.bookPrimaryCharacters[i].sortOrder = i + 1;

        // Add character to the array for saving
        mergedCharacters.push(this.bookPrimaryCharacters[i]);
      }
     
    }

    for(let i = 0; i < this.bookSecondaryCharacters.length; i++)
    {
      // If any fields filled in
      if(!this.isCharacterEmpty(this.bookSecondaryCharacters[i]))
      {
         // Apply an incrementing sort order
        this.bookSecondaryCharacters[i].sortOrder = i + 1;

        // Add character to the array for saving
        mergedCharacters.push(this.bookSecondaryCharacters[i]);
      }
     
    }
    
    return mergedCharacters;

  }

  // Check if any fields have been filled for the given character
  isCharacterEmpty(character: Character): boolean
  {

    if(  (character.disabilityNotes !== undefined && character.disabilityNotes !== '' && character.disabilityNotes !== null)
      || (character.heritageByNotes !== undefined && character.heritageByNotes !== '' && character.heritageByNotes !== null)
      || (character.religionNotes !== undefined && character.religionNotes !== '' && character.religionNotes !== null)
      || (character.sexualityNotes !== undefined && character.sexualityNotes !== '' && character.sexualityNotes !== null)
      || (character.typeNotes !== undefined && character.typeNotes !== '' && character.typeNotes !== null)
      || (character.heritageByNotes !== undefined && character.heritageByNotes !== '' && character.heritageByNotes !== null)
      || (character.genderNotes !== undefined && character.genderNotes !== '' && character.genderNotes !== null)
      || character.charactersCharacterGenders.length !== 0
      || character.charactersCharacterGenderSpecifies.length !== 0
      || character.charactersCharacterHeritages.length !== 0
      || character.charactersCharacterHeritageSpecifies.length !== 0
      || character.charactersCharacterSexualities.length !== 0
      || character.charactersCharacterTypes.length !== 0
      || character.charactersDisabilities.length !== 0
      || character.charactersReligions.length !== 0
    )
    {
      return false;
    }
    
    return true;

  }

  /**
   * Transform exististing publishers into data consumable by the multi-select
   * @param {Array<BookPublishers>} publishers Publishers already associated with this book
   */
  transformPublishers(publishers: Array<BookPublishers>) {
    publishers.forEach(item => {
      let data = {
        option: item.publisherId,
        displayText: item.publisher.name
      };
      this.selectedPublishers.push(data);
    });
    this.getAllPublishers();
  }

  /**
 * Transform exististing genres into data consumable by the multi-select
 * @param {Array<BookGenre>} genres Genres already associated with this book
 */
  transformGenres(genres: Array<BookGenre>) {
    genres.forEach(item => {
      let data = {
        option: item.genreId,
        displayText: item.genre.displayName
      };
      this.selectedGenres.push(data);
    });
  }

  getAllContributors(): void {
    this.contributorService.getContributors().subscribe(contributors => {
      this.allContributors = contributors;
      this.isLoadingContributors = false;
    });
  }

  /**
   * Gets available types of contributors, binds to the view in order for new contributors to be added to the book being edited.
   */
  getContributorTypes(): void {
    this.contributorService.getContributorTypes().subscribe(contributorTypes => {
      for (let type of contributorTypes) {
        this.contributorTypes.push(type.displayName);
      }
    });
  }

  /**
   * Get all known publishers
   */
  getAllPublishers(): void {
    this.bookService.getPublishers().subscribe(publishers => {
      if (publishers != undefined && publishers.length > 0) {
        this.allPublishers = publishers;
        this.assemblePublisherOptions(publishers);
      }
    })
  }

  /**
   * Convert publisher objects on the book into objects consumable by the multi-select
   * @param publishers 
   */
  assemblePublisherOptions(publishers: Array<Publisher>): void {
    for (let publisher of publishers) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: publisher.publisherId,
        displayText: publisher.name
      }
      this.publisherOptions.push(data);
    }
    this.publisherOptions = this.publisherOptions.slice();
  }

  /**
   * Get everything currently in the multi-select, transform to match data model expected by
   * API, then update the list of publishers that should be saved to the book.
   */
  updatePublishersToSave(): void {
    // Transform multi-select data into book publishers
    let updatedPublishers = new Array<BookPublishers>();
    this.selectedPublishers.forEach(item => {
      const bookPublisher: BookPublishers = {
        bookId: this.book.id,
        publisherId: item.option,
        publisher: this.allPublishers.find(entry => entry.publisherId === item.option)
      }
      updatedPublishers.push(bookPublisher);
    });
    this.publishersToSave = updatedPublishers;
  }

  /**
 * Get all genres
 */
  getAllGenres(): void {
    this.bookService.getGenres().subscribe(genres => {
      if (genres != undefined && genres.length > 0) {
        this.allGenres = genres;
        this.assembleGenreOptions(genres);
      }
    })
  }

  /** Convert genre objects on the book into objects consumable by the multi-select
   * @param genres 
   */
  assembleGenreOptions(genres: Array<Genre>): void {
    for (let genre of genres) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: genre.id,
        displayText: genre.displayName
      }
      this.genreOptions.push(data);
    }
    this.genreOptions = this.genreOptions.slice();
  }

  /**
   * Get everything currently in the multi-select, transform to match data model expected by
   * API, then update the list of publishers that should be saved to the book.
   */
  updateGenresToSave(): void {
    // Transform multi-select data into book publishers
    let updatedGenres = new Array<BookGenre>();
    this.selectedGenres.forEach(item => {
      const bookGenre: BookGenre = {
        bookId: this.book.id,
        genreId: item.option,
        genre: this.allGenres.find(entry => entry.id === item.option)
      }
      updatedGenres.push(bookGenre);
    });
    this.genresToSave = updatedGenres;
  }


  /////////////////////
  // EVENT LISTENERS //
  /////////////////////
  /**
   * Updates all changed fields on the current book, updates the UI where necessary, then sends the 
   * updated book off to be saved.
   */
  onSave(): void {

    let newCoverImage: File = this.book.recommendation.newBookCover;

    // Copy the current book before updating its information
    let bookToSave = JSON.parse(JSON.stringify(this.book));
    this.saveSuccessful = false;
    this.saveError = false;

    // Remove deleted contributors
    this.contributorsMarkedForDeletion.forEach(contrib => {
      let index = this.book.bookContributors.indexOf(contrib);
      this.book.bookContributors.splice(index, 1);
      if (contrib.bookId != 0) {
        this.bookService.removeContributor(contrib).subscribe(result => {
          // Confirm removal or handle errors
        });
      }
    });

    
    // Save remaining contributors
    let contributorOrder = 1;
    bookToSave.bookContributors = new Array<Contributor>();
    this.book.bookContributors.forEach(contrib => {
      let newContrib = new Contributor();
      newContrib.bookId = bookToSave.id;
      newContrib.contributorId = contrib.contributorId;
      newContrib.sortOrder = contributorOrder;
      contributorOrder++;
      newContrib.contributorTypeId = contrib.contributorTypeId;
      bookToSave.bookContributors.push(newContrib);
    });

    // Save any changes to publishers
    bookToSave.bookPublishers = this.publishersToSave.map((entry) => {
      let pub = new BookPublishers();
      pub.publisherId = entry.publisherId;
      pub.bookId = bookToSave.id;
      return pub;
    });

    // save any changes to genres
    bookToSave.bookGenres = this.genresToSave.map((entry) => {
      let genre = new BookGenre();
      genre.genreId = entry.genreId;
      genre.bookId = bookToSave.id;
      return genre;
    });

    //remove bookbiliography
    if (this.bookBibliographiesToBeDeleted.length>0){
        this.bookBibliographiesToBeDeleted.forEach(bookbibliography=>{
        let booksCategory =new BooksCategories();
        booksCategory.bookId = bookbibliography.bookId;
        booksCategory.bibliographyCategoryId = bookbibliography.categoryId;
      
        var index = this.book.booksCategories.findIndex(item => item.bibliographyCategoryId === bookbibliography.categoryId);
        var indexBookBilio = this.bookBibliographies.indexOf(bookbibliography);

        if (index>-1){
            this.book.booksCategories.splice(index, 1);
        }
        //update current bookbiliography model to refresh view
        if (indexBookBilio>-1){
          this.bookBibliographies.splice(indexBookBilio,1);
        }

      });
    }
    //add new bibliography to save list 
    if (this.bookBibliographies.length>0) {
      this.bookBibliographies.forEach(bookbibliography=>{
        let booksCategory =new BooksCategories();
        booksCategory.bookId = bookbibliography.bookId;
        booksCategory.bibliographyCategoryId = bookbibliography.categoryId;
        var index = this.book.booksCategories.findIndex(item => item.bibliographyCategoryId === bookbibliography.categoryId);
        
        //this is a new bibliography assigned to this book
        if (index<0){
          this.book.booksCategories.push(booksCategory);
        }
         
    });
  }
    // Save remaining book cateogries
    bookToSave.booksCategories = this.book.booksCategories.map((booksCategory) => {
      let newBookCategory = new BooksCategories();
      newBookCategory.bookId = booksCategory.bookId;
      newBookCategory.bibliographyCategoryId = booksCategory.bibliographyCategoryId;
     
      return newBookCategory;
    });

    // Extract primary & secondary characters with populated fields, and merge into characters[] for saving
    bookToSave.characters = this.mergePrimaryAndSecondaryCharactersForSave();

      this.bookService.saveBook(bookToSave).subscribe({
        next: (book) => {

          this.saveSuccessful = true;
          this.lastSavedDate = new Date();
          this.contributorsMarkedForDeletion = new Array<Contributor>();
         
          console.info('save successful: ', book);
  
          // Reload route with newly set book id
          if (this.isNewBook) {
  
            if (newCoverImage) {  //update new image, then reload route
              this.bookService.postFile(newCoverImage, book.id).subscribe(result => { 
                this.router.routeReuseStrategy.shouldReuseRoute = () => false; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/book/' + book.id]);
              });
            }
            else { //just reload the route
              this.router.routeReuseStrategy.shouldReuseRoute = () => false; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/book/' + book.id]);
            }
  
          }
          else if (newCoverImage) {  //if existing book just upload the new image
            this.bookService.postFile(newCoverImage, book.id).subscribe(result => { });
          }

          // Need to refresh the characters bound to the template, 
          // so the ids of deleted characters are cleared and ids of newly created characters are set
          this.book.characters = book.characters;
          this.extractPrimaryAndSecondaryCharactersForDisplay();
          
        },
        error: (err) => {
          window.alert('The application encountered an error saving the book.');
          throwError(err);
        }
      });
  }

  /**
   * Displays a modal where users can create new contributors or add existing ones to the book being edited.
   */
  showContributorsModal(): void {
    // Create new dialog with data that matches ContributorDataInterface
    const dialogRef = this.dialog.open(DialogContributorsComponent, {
      width: '80%',
      data: {
        bookTitle: this.book.title,
        bookId: this.book.id,
        contributorsList: this.allContributors,
        existingContributors: this.book.bookContributors
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Add new contributors to the book
      if (result.length > 0) {
        result.forEach(contributor => {          
          this.book.bookContributors.push(contributor);
        });
      }
      this.getAllContributors();
    });
  }

  /**
   * Displays a modal for editing data about a contributor that exists on this book record
   * @param id The id of the contributor to be edited
   */
  showEditContributorModal(id: number): void {
    const dialogRef = this.dialog.open(DialogNewContributorComponent, {
      width: '80%',
      data: {
        contributor: this.allContributors.find(entry => entry.id === id),
        isNewContributor: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == -1) { return; }
      this.contributorService.saveContributor(result)
        .subscribe(contributor => {
          console.info('updated contributor', contributor);
        })
    });
  }

  /**
   * Mark a contributor for deletion or remove a contributor from the list of marked ones.
   * @param {$event} event Checkbox event when the remove box is checked or unchecked
   * @param {Contributor} contributor Contributor to mark or unmark
   * @param {number} index Index of the contributor in the list
   */
  toggleDeleteContributor(event, contributor: Contributor, index: number): void {
    if (this.book.bookContributors.indexOf(contributor) > -1) {
      if (event.checked) {
        this.contributorsMarkedForDeletion.push(contributor);
      } else {
        // Remove id from deletion list if it's there
        let index = this.contributorsMarkedForDeletion.indexOf(contributor);
        if (index > -1) {
          this.contributorsMarkedForDeletion.splice(index, 1);
        }
      }
    }
  }

  onContributorDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.book.bookContributors, event.previousIndex, event.currentIndex);
  }

  /**
   * Displays a modal where users can create new publishers to add to the book being edited
   */
  showPublishersModal(): void {
    // Create new dialog with data that matches IPublisherData
    const dialogRef = this.dialog.open(DialogPublishersComponent, {
      width: '80%',
      data: {
        allPublishers: this.allPublishers
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      // Check for cancellation
      if (result === -1 || result === undefined) { return; }
      // Save new publisher via service
      this.bookService.addPublisher(result)
        .subscribe(publisher => {
          // Transform and add selection, then copy selected publishers array to new reference
          // to be displayed in the multi-select
          this.selectedPublishers.push({
            option: publisher.publisherId,
            displayText: publisher.name
          });
          this.selectedPublishers = this.selectedPublishers.slice();
          this.updatePublishersToSave();

          //add new publisher to the list of all publishers
          this.allPublishers.push(publisher);
        });
    });
  }

  /**
   * Transform multi-select selections into BookPublishers and pass them on for later saving
   * @param {Array<IMultiSelectOptions>} publishers List of publishers currently selected in the multi-select
   */
  setSelectedPublishers(publishers: Array<IMultiSelectOptions>): void {
    this.selectedPublishers = publishers;
    this.selectedPublishers = this.selectedPublishers.slice();
    this.updatePublishersToSave();
  }

  /**
   * Transform multi-select selections into BookGenres and pass them on for later saving
   * @param {Array<IMultiSelectOptions>} genres List of publishers currently selected in the multi-select
   */
  setSelectedGenres(genres: Array<IMultiSelectOptions>): void {
    this.selectedGenres = genres;
    this.selectedGenres = this.selectedGenres.slice();
    this.updateGenresToSave();
  }

  checkMCEInput(event): void {
    this.richTextHasError = event;
  }

  /*Bibliography checkbox Remove event  */  
  deleteBibliographyFromBook(event, bookbibliography: BookBibliography, index: number): void {
    if (this.bookBibliographies.indexOf(bookbibliography) > -1) {
      
      if (event.checked) {
        //push to delete array 
        this.bookBibliographiesToBeDeleted.push(bookbibliography);
      } else {
        // Remove id from deletion array
        let index = this.bookBibliographiesToBeDeleted.indexOf(bookbibliography);
        if (index > -1) {
          this.bookBibliographiesToBeDeleted.splice(index, 1);
        }
      }
    }
  }
  assignBibliography(bookId: number): void {
    // Create new dialog of bibliography
    const dialogBiblioRef = this.dialog.open(DialogAssignBibliographyComponent, {
      width: '50%',
      height: '60%',
      data: {
        bookId: this.book.id,
        bookBibliographies: this.bookBibliographies 
      }
    });
      dialogBiblioRef.afterClosed().subscribe(result => {
    });
  }
  
}

