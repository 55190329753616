import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, style, animate, transition, state } from '@angular/animations';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TreeModel, TreeNode, TREE_ACTIONS, ITreeOptions} from 'angular-tree-component';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogBibliographyBookComponent } from 'src/app/components/dialog-bibliography-book/dialog-bibliography-book.component';

//classes
import { Bibliography, BibliographyAgeGrade, BibliographiesAgeGrades, NestedCategories, BookForBibliographyView } from 'src/app/classes/Bibliography';

//services
import { BibliographyService } from 'src/app/services/bibliography.service';

@Component({
  selector: 'app-edit-bibliography',
  animations: [
    trigger('hideShowAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('0s', style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './edit-bibliography.component.html',
  styleUrls: ['./edit-bibliography.component.scss']
})
export class EditBibliographyComponent implements OnInit {

  //local variables
  bibliography: Bibliography | null;
  loading: boolean = true;
  hasGetBibliographyError: boolean = false;
  errorId: number;

  richTextHasError: boolean = false;
  bibliographyForm: FormGroup;
  saveSuccessful: boolean = false;
  saveError: boolean = false;
  lastSavedDate: Date;
  newBibliography: boolean = false;
 
  blankCategoryName: string ="";

  treeOptions: ITreeOptions = {
    idField: 'id',
    displayField: 'description',
    allowDrag: true,
    allowDrop: (element, {parent, index}) => {  //limit nesting depth to 1
      return parent.level <= 1;
    }
  };


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bibliographyService: BibliographyService,
    private titleService: Title,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.GetBibliography();
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  //////////
  // Load //
  //////////
  GetBibliography(): void {
    // Get bibliography ID from route and cast as number
    const id: number = +this.route.snapshot.paramMap.get('id');

    if (id === 0) {
      //create new bib
      this.bibliography = new Bibliography();
      //add one blank category
      let blankCategory = new NestedCategories();
      blankCategory.description = "Default Category"; 
      blankCategory.order = -1;
      this.blankCategoryName = "";
      this.bibliography.nestedCategories.push(blankCategory);    
      this.getAgeGrades();
      this.loading = false;
      this.newBibliography = true;
      this.setTitle('New booklist | CCBC Book Search');
    }
    else {
      this.bibliographyService.getBibliographyById(id).subscribe(bib => {
        this.bibliography = bib;
        this.getAgeGrades();
        this.loading = false;
        this.blankCategoryName ="";
        this.setTitle('Edit booklist | CCBC Book Search');
      }, err => {
        this.errorId = id;
        this.hasGetBibliographyError = true;
      });
    }
  }

  //////////////////////////
  // Age/Grade checkboxes //
  //////////////////////////  
  allAgeGrades: Array<BibliographyAgeGrade> = new Array<BibliographyAgeGrade>();
  selectedAgeGrades: Array<BibliographiesAgeGrades> = new Array<BibliographiesAgeGrades>();

  getAgeGrades(): void {
    this.bibliographyService.getAllAgeGrades().subscribe(ageGrades => {
      if (ageGrades != undefined && ageGrades.length > 0) {
        this.populateAgeGrades(ageGrades);
      }
    });
  }

  populateAgeGrades(ageGrades: Array<BibliographyAgeGrade>): void {
    this.selectedAgeGrades = this.bibliography.bibliographiesAgeGrades;
    this.allAgeGrades = ageGrades;
    this.allAgeGrades.forEach(item => {
      if (this.selectedAgeGrades.filter(age => age.bibliographyAgeGradeId === item.ageGradeId).length > 0) {
        item.selected = true;
      }
      else {
        item.selected = false;
      }
    });
  }

  onClickAgeGrade(): void {
    let updatedAgeGrades = new Array<BibliographiesAgeGrades>();
    this.allAgeGrades.forEach(all => {
      if (all.selected) {
        const updatedAgeGrade: BibliographiesAgeGrades = {
          bibliographyId: this.bibliography.id,
          bibliographyAgeGradeId: all.ageGradeId,
          bibliographyAgeGrade: null
        }
        updatedAgeGrades.push(updatedAgeGrade);
      }
    });
    this.bibliography.bibliographiesAgeGrades = updatedAgeGrades;   
    this.selectedAgeGrades = updatedAgeGrades;
  }

  //////////////
  // Tiny MCE //
  //////////////
  public isTinyMCEAlive: boolean = false;
  public tinyMCEInput: string = '';
  public tinyMCELimit: number = 4000;
  public tinyMCEHasError: boolean = false;
  public TINY_MCE_REGEX: RegExp = /&nbsp;/;

  /**
   * Set initial input value equal to back end data
   */
  initTinyMCE(): void {
    if (this.isTinyMCEAlive) { return; }
    if (this.bibliography.introduction != null) {
      this.tinyMCEInput = this.bibliography.introduction;
    }    
    this.tinyMCEHasError = this.checkTinyMCEHasError();
    this.isTinyMCEAlive = true;
  }

  /**
   * Get length of editor input after stripping non-breaking spaces. We should not get any of these
   * if input validation works, but we should check for them just in case.
   */
  getTinyMCEInputLength(): number {
    const length = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim().length;    
    return length;
  }

  /**
   * Check if input length is within limit
   */
  checkTinyMCEHasError(): boolean {
    const length = this.getTinyMCEInputLength();
    return length > this.tinyMCELimit;
  }

  /**
   * Check for length within constraints and update model data to match editor input
   * (minus auto-inserted non-breaking spaces to save on character limit)
   */
  onTinyMCEInput(): void {
    // Store rich text but replace '&nbsp;' to save on characters
    this.bibliography.introduction = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim();
    // Update UI helper and bubble up event to disable saving the book
    this.tinyMCEHasError = this.checkTinyMCEHasError();
  }


  ///////////
  // Save! //
  ///////////
  onSave(): void {
    this.saveSuccessful = false;
    this.saveError = false;

    this.bibliographyService.saveBibliography(this.bibliography).subscribe(bib => {
      this.saveSuccessful = true;
      this.saveError = false;
      this.lastSavedDate = new Date();

      //reload with id in route
      if (this.newBibliography) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/bibliography/' + bib.id]);
      }

    }, err => {
      this.saveSuccessful = false;
      this.saveError = true;
    });

  }

  //Manage Categories
  addCategory(categoryName: string): void {
    
    //if category with empty name, ignore add
    if (categoryName.trim().length===0){
      this.blankCategoryName="";
      return;
    }

    let blankCategory = new NestedCategories();
    blankCategory.description = categoryName;
    blankCategory.order = 1; //not really used by front-end component, only important for saving back to db - we will calculate on backend
    this.bibliography.nestedCategories.push(blankCategory);  
    this.bibliography.nestedCategories = [...this.bibliography.nestedCategories]; //found this in the demo, this forces the nestable component to re-render

    this.blankCategoryName="";
  }

  //tree specific events
  onTreeEvent = ($event: any) => {

    if ($event.eventName == "activate") {
      $event.node.data.showBooks = true;
    }

    if ($event.eventName == "deactivate") {
      $event.node.data.showBooks = false;
    }

  };

  deleteBooks = (node: TreeNode) => {

    //loop backwards to prevent altering the indices when splicing
    var i = node.data.booksForBibliographyView.length;
    while (i --) {
      if (node.data.booksForBibliographyView[i].remove) {
        node.data.booksForBibliographyView.splice(i,1);
      }
    }

  };
 
  removeCategory = (node: TreeNode) => {

    //if parent, promote any children to primary status first before removing (insert at same level) - NOTE - this logic assumes only one level of subcategory
    if (node.level == 1) {
      
      for (let parentIndex = 0; parentIndex < this.bibliography.nestedCategories.length; parentIndex++) {

        if (this.bibliography.nestedCategories[parentIndex].id == node.data.id) {

          //add children to parent array below the parent
          this.bibliography.nestedCategories.splice(parentIndex+1,0, ...this.bibliography.nestedCategories[parentIndex].children);  //... is the spread operator https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax

          //then remove parent
          this.bibliography.nestedCategories.splice(parentIndex,1);

        }

      }

    }

    //otherwise if child just remove it
    if (node.level == 2) { 

      for (let parent of this.bibliography.nestedCategories) {  //find parent
        if (parent.id == node.parent.data.id) {

          for (let childIndex = 0; childIndex < parent.children.length; childIndex++) {
            if (parent.children[childIndex].id == node.data.id) {
              parent.children.splice(childIndex,1);
            }
          }
        }

      }

    }

    this.bibliography.nestedCategories = [...this.bibliography.nestedCategories]; //found this in the demo, this forces the nestable component to re-render
  }

  //fire the book add model
  addBook = (node: TreeNode) => {

    // Create new dialog with data that matches IPublisherData
    const dialogRef = this.dialog.open(DialogBibliographyBookComponent, {
      width: '60%',
      data: {
        category: node.data
      }
     
    });

    dialogRef.afterClosed().subscribe(result => {

      // Check for cancellation
      if (result === -1 || result === undefined) { return; }

      node.data.booksForBibliographyView.push(...result);

    });


  };

}
