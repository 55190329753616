<!-- Navigation -->
<nav>
  <mat-toolbar color="primary">
    <button class="button__menu" mat-icon-button (click)="sidenav.toggle()"  *ngIf="loggedIn" aria-label="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <h1><a href="#">CCBC Book Search</a></h1>
    <ng-container  *ngIf="loggedIn"><search-books></search-books></ng-container>
    <span class="flex"></span>
    
    <button mat-button routerLink="/logout" *ngIf="loggedIn">Log out</button>
    <button mat-button routerLink="/login" *ngIf="!loggedIn">Log in</button>
  </mat-toolbar>
</nav>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" role="navigation">
    <mat-nav-list>
      <a mat-list-item routerLink="/"><mat-icon matListIcon>library_books</mat-icon> Book list</a>
      <a mat-list-item href="/book/0"><mat-icon matListIcon>book</mat-icon> Add book</a>
      <a mat-list-item routerLink="/contributors"><mat-icon matListIcon>people</mat-icon> Contributor list</a>
      <a mat-list-item href="/contributor/0"><mat-icon matListIcon>person</mat-icon> Add contributor</a>
      <a mat-list-item routerLink="/bibliographies"><mat-icon matListIcon>list</mat-icon>Bibliography list</a>
      <a mat-list-item href="/bibliography/0"><mat-icon matListIcon>list_alt</mat-icon>Add Bibliography</a>
     
      <app-generate-report></app-generate-report>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content role="main">
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>