<div style="height:max-content; min-height:85%;">
  <form #assignBibliographyForm="ngForm">

    <h1>Assign to bibliography</h1>
    
    <p>Select a bibliography from the dropdown to see available categories.</p>

    <div class="container" style="margin-top:20px;" >
      <div class="row" *ngIf="bibliographyCategories.length>0">
        <div class="col-sm-6">
          <div>
            <mat-select matNativeControl placeholder="Bibliography" aria-placeholder="Bibliography"
              (selectionChange)="onBibliographyChange($event.value)">
              <mat-option *ngFor="let bibliographyCategory of bibliographyCategories" [value]="bibliographyCategory.id">
                {{ bibliographyCategory.bibliographyName }}
              </mat-option>
            </mat-select>
          </div>
         
          <div *ngIf="categoryList.length>0" style="margin-top:20px;">
            <mat-select matNativeControl placeholder="Category" (selectionChange)="onCategoryChange($event.value)">
              <mat-option *ngFor="let category of categoryList" [value]="category.id">
                {{ category.categoryName }}
              </mat-option>
            </mat-select>
          </div>
          
          <div *ngIf="subCategoryList.length>0" style="margin-top:20px;">
            <mat-select matNativeControl placeholder="Subcategory" 
              (selectionChange)="onSubcategoryChange($event)">
              <mat-option value="-1"><mat-icon>delete</mat-icon>Clear</mat-option>
                <mat-option *ngFor="let subCategory of subCategoryList" [value]="subCategory.id">
                {{ subCategory.categoryName }}
              </mat-option>
            </mat-select>
          </div>
         
        </div>
        <div class="col-sm-6"> 
            <div *ngIf="blexists" style="margin-top:20px;" class="alert alert-danger">
            <p>This bibliography category has been assigned to this book. </p>
            </div>
        </div>
      </div>
     <div class="row">
       <div class="col-sm-12" *ngIf="bibliographyCategories.length==0">
         <p>There are no bibliographies available.</p>
         <button mat-flat-button color="accent" (click)="closeAddBibliographyModal()"
                [routerLink]="['/bibliography', 0]">
                <mat-icon>add</mat-icon>Add Bibliography
            </button>
       </div>
     </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="border-top" align="end">
  <button mat-button (click)="cancelAdd()">Cancel</button>
  <button class="button__save" mat-button mat-flat-button (click)="assignBibliography()"
    [disabled]="blexists || selectedCategory==0">Confirm changes</button>
</div>