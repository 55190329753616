<h1 mat-dialog-title>Add contributors
  <small class="small">for <em>{{ data.bookTitle }}</em></small>
</h1>
<div mat-dialog-content>
  <p>Select a role from the dropdown to see available contributors.</p>
  <div class="row">
    <mat-form-field class="select__roles">
      <mat-label>Add for role:</mat-label>
      <mat-select [formControl]="rolesSelect" required>
        <mat-option *ngFor="let role of rolesList" [value]="role">
          {{ role.displayName }}
        </mat-option>
      </mat-select>
      <mat-hint>Multiple contributors can be added for this role</mat-hint>
      <mat-error *ngIf="rolesSelect.hasError('required')">
        Role is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <span class="flex"></span>
    <div class="col column__create-contributor">
      <button mat-flat-button color="accent" class="button__add-new" [disabled]="isSelectHidden"
        (click)="showCreateContributor()">
        <mat-icon>add</mat-icon> Create new contributor
      </button>
      <span class="helper" [hidden]="!isSelectHidden">Please select a role before creating a new contributor</span>
    </div>
  </div>
  <div class="shadow__multi-select" [hidden]="!isSelectHidden"></div>
  <app-multi-select [id]="'contributors'" [hidden]="isSelectHidden" [hasFilter]="true" [label]="'Filter by name'"
    [filteredOptionsSubject]="optionsSubject" [multiSelectOptions]="multiSelectOptions" [ariaLabel]="'contributors'"
    [selectionsToReturn]="selectedContributors" (onSelectionsChanged)="updateSelections($event)"></app-multi-select>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button class="button__save" mat-button mat-flat-button (click)="getSelections()"
    [disabled]="rolesSelect.hasError('required') || isUpdating">
    <span class="add-contributors--loading" *ngIf="isUpdating">
      <mat-spinner color="accent"></mat-spinner>
    </span>
  Confirm changes
</button>
</div>