import { Gender } from './Gender';
import { Disability } from './Disability';
import { Religion } from './Religion';

export class Contributor {
    bookId: number;
    contributorId: number;
    contributorTypeId: number;
    sortOrder: number;
    contributor: ContributorPersonalInfo;
    contributorType: ContributorType;

    constructor() {
        this.bookId = 0;
        this.contributorId = 0;
        this.contributorTypeId = 0;
        this.sortOrder = 0;
    }
}

export class ContributorType {
    id: number | null;
    type: string; //max 25
    displayName: string; //max 50

    constructor() { 
        this.id = null;
        this.type = "";
        this.displayName = "";
    }
}

export class ContributorPersonalInfo {
    id: number | null;
    firstName: string; //required 50 max
    middleName: string; // 50 max
    lastName: string; // 50 max
    
    culturalHeritageNotes: string; //255 Max
    culturalHeritageContested: boolean;
    specificCulture: string; //100 max

    verified: boolean;
    verificationSource: string;  //100 max

    disabilityNotes: string;  //255 max

    lgbtq: boolean;
    lgbtqNotes: string; //255 max

    wisconsinContributor: boolean;
    wisconsinContributorNotes: string; //255 max

    contributorsGenders: Array<ContributorsGenders>;
    contributorsReligions: Array<ContributorsReligions>;
    contributorsDisabilities: Array<ContributorsDisabilities>;
    contributorsContributorHeritages: Array<ContributorsContributorHeritages>;

    constructor() {
        this.id = null;
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        
        this.culturalHeritageNotes = '';
        this.culturalHeritageContested = false;
        this.specificCulture = '';
        this.verified = false;
        this.verificationSource = '';
        this.disabilityNotes = '';
        this.lgbtq = false;
        this.lgbtqNotes = '';
        this.wisconsinContributor = false;
        this.wisconsinContributorNotes = '';
        this.contributorsGenders = new Array<ContributorsGenders>();
        this.contributorsDisabilities = new Array<ContributorsDisabilities>();
        this.contributorsReligions = new Array<ContributorsReligions>();
        this.contributorsContributorHeritages = new Array<ContributorsContributorHeritages>();
    }
}

export class ContributorsGenders {
    contributorId: number;
    genderId: number;
    contributor?: ContributorPersonalInfo;
    gender?: Gender;
    constructor() {
        this.contributorId = null;
        this.genderId = null;
    }
}

export class ContributorsReligions {
    contributorId: number;
    religionId: number;
    contributor? : ContributorPersonalInfo;
    religion? : Religion;
    constructor() {
        this.contributorId = null;
        this.religionId = null;
    }
}

export class ContributorsDisabilities {
    contributorId: number;
    disabilityId: number;
    contributor?: ContributorPersonalInfo;
    disability?: Disability;
    constructor() {
        this.contributorId;
        this.disabilityId = null;        
    }
}

export class ContributorsContributorHeritages {
    contributorId: number;
    contributorHeritageId: number;
    contributor?: ContributorPersonalInfo;
    contributorHeritage?: ContributorHeritage;
    constructor() {
        this.contributorId = null;
        this.contributorHeritageId = null;
    }
}



export class ContributorHeritage {
    id: number;
    name: string;
    constructor() {
        this.id = null;
        this.name = null;
    }
}
