<mat-card class="card__diversity--heritage">
  <!-- Begin Heritage -->
  <mat-card-title>
    <span class="title">Heritage/Region</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #diversityHeritageMultiSelect [id]="'diversityHeritageMultiSelect'" [label]=""
      [multiSelectOptions]="diversityHeritageOptions" [selectionsToReturn]="selectedDiversityHeritages"
      (onSelectionsChanged)="setSelectedHeritages($event)" [ariaLabel]="'heritage/region'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectheritageNotes" class="form-control" maxlength="255"
        [(ngModel)]="model.subjectDetail.heritageNotes" name="heritageNotes" placeholder="Notes"></textarea>
    </div>
    <!-- End Heritage -->
  </mat-card-content>
</mat-card>

<mat-card class="card__diversity--religion">
  <mat-card-title>
    <span class="title">Religion</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #diversityReligionMultiSelect [id]="'diversityReligionMultiSelect'" [label]=""
      [multiSelectOptions]="diversityReligionOptions" [selectionsToReturn]="selectedDiversityReligions"
      (onSelectionsChanged)="setSelectedReligions($event)" [ariaLabel]="'religion'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectReligionNotes" class="form-control" maxlength="255"
        [(ngModel)]="model.subjectDetail.religionNotes" name="religionNotes" placeholder="Notes"></textarea>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="card__diversity--lgbtq">
  <mat-card-title>
    <span class="title">LGBTQ</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #diversityLGBTQMultiSelect [id]="'diversityLGBTQMultiSelect'" [label]=""
      [multiSelectOptions]="diversityLGBTQOptions" [selectionsToReturn]="selectedDiversityLGBTQ"
      (onSelectionsChanged)="setSelectedLgbtqs($event)" [ariaLabel]="'lgbtq'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectLgbtqNotes" class="form-control" maxlength="255"
        [(ngModel)]="model.subjectDetail.lgbtqNotes" name="lgbtqNotes" placeholder="Notes"></textarea></div>
  </mat-card-content>
</mat-card>

<mat-card class="card__diversity--disabilities">
  <mat-card-title>
    <span class="title">Disability/Condition</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #diversityDisabilityMultiSelect [id]="'diversityDisabilityMultiSelect'" [label]=""
      [multiSelectOptions]="diversityDisabilityOptions" [selectionsToReturn]="selectedDiversityDisability"
      (onSelectionsChanged)="setSelectedDisabilities($event)" [ariaLabel]="'disability'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectDisabilityNotes" class="form-control" maxlength="255"
        [(ngModel)]="model.subjectDetail.disabilityNotes" name="disabilityNotes" placeholder="Notes"></textarea>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="card__diversity--secondary">
  <mat-card-title>
    <span class="title">Secondary Diversity</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #diversitySecondaryMultiSelect [id]="'diversitySecondaryMultiSelect'" [label]=""
      [multiSelectOptions]="diversitySecondaryOptions" [selectionsToReturn]="selectedSecondaryDiversity"
      (onSelectionsChanged)="setSelectedSecondaries($event)" [ariaLabel]="'secondary diversity'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectsecondaryNotes" class="form-control" maxlength="255"
        [(ngModel)]="model.subjectDetail.secondaryNotes" name="secondaryNotes" placeholder="Notes"></textarea>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="card__diversity--gender">
  <mat-card-title>
    <span class="title">Gender Markers</span>
  </mat-card-title>
  <mat-card-content>
    <app-multi-select class="multi-select__diversity" #gendermarkerMultiSelect [id]="'gendermarkerMultiSelect'" [label]=""
      [multiSelectOptions]="genderMarkerOptions" [selectionsToReturn]="selectedGenderMarker"
      (onSelectionsChanged)="setSelectedGenderMarkers($event)" [ariaLabel]="'gender markers'">
    </app-multi-select>
    <div class="input-group">
      <textarea type="text" id="subjectGenderMarkerNotes" class="form-control" [(ngModel)]="model.genderMarkerNotes"
        name="gendermarkerNotes" placeholder="Notes" maxlength="255"></textarea>
    </div>
  </mat-card-content>
</mat-card>