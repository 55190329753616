<mat-card class="card__login">
  <mat-card-title>Log in to CCBC</mat-card-title>
  <form (ngSubmit)="onSubmit()" #formDir="ngForm" [formGroup]="loginForm">
    <mat-card-content>  
      <mat-form-field class="">
        <input matInput type="text" id="email" formControlName="email" placeholder="Email">
      </mat-form-field>
    
      <mat-form-field class="example-full-width">
        <input matInput type="password" id="password" formControlName="password" placeholder="Password">
      </mat-form-field>
      <mat-error *ngIf="loginFailed">
        We couldn't recognize the credentials you entered.
      </mat-error>
    </mat-card-content>
    <mat-card-actions>
      <button color="accent" mat-raised-button type="submit">Log in</button>
    </mat-card-actions>
  </form>
</mat-card>