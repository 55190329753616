import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public loggedIn: boolean = false;

  constructor(
    public auth: AuthService, 
    private cd: ChangeDetectorRef,
    private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('CCBC Book Search');
    this.loggedIn = this.auth.isLoggedIn();
    this.auth.authEvent().subscribe(loggedIn => {
      if (this.loggedIn != loggedIn) {
        this.loggedIn = loggedIn;
        this.cd.detectChanges();
      }
    });
  }
}
