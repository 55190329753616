import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

// Services
import { ContributorService } from 'src/app/services/contributor.service';

// Classes
import { Contributor, ContributorType, ContributorPersonalInfo } from 'src/app/classes/Contributor';

// Pipes
import { RemoveDuplicatesPipe } from 'src/app/pipes/remove-duplicates.pipe';

// Components
import { DialogNewContributorComponent } from 'src/app/components/add-edit-contributor/add-edit-contributor.component';
import { IMultiSelectOptions } from 'src/app/components/multi-select/multi-select.component';

export interface IContributorData {
  bookTitle: string;
  bookId: number;
  contributorsList: Array<ContributorPersonalInfo>;
  existingContributors?: Array<Contributor>;
}

/**
 * Dialog that appears when a user clicks the "Add contributor(s)" button when editing a book.
 */
@Component({
  selector: 'app-dialog-contributors',
  templateUrl: './dialog-contributors.component.html',
  styleUrls: ['./dialog-contributors.component.scss']
})
export class DialogContributorsComponent implements OnInit {
  bookContributors;
  allContributors: Array<ContributorPersonalInfo>;
  rolesList: Array<ContributorType>;
  contributorsToAdd: Array<Contributor>;
  contributorsSelect = new FormControl();
  rolesSelect = new FormControl();
  existingContributors = new Array<Contributor>();
  selectedContributors: Array<IMultiSelectOptions>;
  lastNewContributor: ContributorPersonalInfo;
  addedSuccess: boolean = false;
  multiSelectOptions: Array<IMultiSelectOptions>;
  optionsSubject: Subject<any> = new Subject();
  isSelectHidden: boolean = true;
  isUpdating: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogContributorsComponent>,
    private contributorService: ContributorService,
    public dialog: MatDialog,
    public removeDupes: RemoveDuplicatesPipe,
    @Inject(MAT_DIALOG_DATA) public data: IContributorData) {
    this.dialogRef.beforeClose().subscribe(() => this.dialogRef.close(this.contributorsToAdd));
  }

  ngOnInit() {
    // Initialize list of contributors to return
    this.contributorsToAdd = new Array<Contributor>();
    this.selectedContributors = new Array<IMultiSelectOptions>();
    // Capture any existing contributors
    this.contributorService.getContributorTypes().subscribe(types => {
      this.rolesList = types;
    });
    if (this.data.existingContributors) {
      this.existingContributors = this.data.existingContributors;
    }
    if (this.data.contributorsList) {
      this.allContributors = this.data.contributorsList;
    }
    // Register change listener on role select to update options list
    this.rolesSelect.valueChanges.subscribe(() => {
      this.isSelectHidden = false;
      this.assembleMultiSelectOptions();
    });
  }

  /**
   * Transforms contributors to match interface expected by multi-select component, updates service that the multi-select is listening to.
   * @param {Array<ContributorPersonalInfo>} contributors Contributor objects to transform to match multi-select options interface
   */
  assembleMultiSelectOptions(): void {
    // Update list of all contributors and filterables, reinitialize multi-select options
    this.multiSelectOptions = new Array<IMultiSelectOptions>();
    let roleId = this.rolesSelect.value.id;
    let filteredContributors = this.allContributors.slice();

    // Check if this selected role matches any contributor type IDs in the existing contributors array
    // If a role matches, check if the entry's ID exists in the contributors list
    // If so, remove the matching element from the list before creating the multi-select options    
    this.existingContributors.forEach(contrib => {
      if (contrib.contributorTypeId === roleId) {
        let removeMe: any = filteredContributors.find((entry: any) => {
          if (entry.id === contrib.contributorId) {
            return entry;
          }
        });
        filteredContributors.splice(filteredContributors.indexOf(removeMe), 1);
      }
    });

    // Convert data to match IMultiSelectOptions
    for (let contributor of filteredContributors) {
      let data = {
        option: contributor.id,
        displayText: contributor.lastName + ', ' + contributor.firstName + ' ' + contributor.middleName
      }
      this.multiSelectOptions.push(data);
    }

    // Update multi-select options list
    this.optionsSubject.next({ list: this.multiSelectOptions, resetSelections: true });
  }

  /**
   * Receive selections from the multi-select component, set the view's array equal to them, 
   * then reassign the array reference so changes are detected throughout the components.
   * @param {Array<IMultiSelectOptions>} selections User's multi-select selections
   */
  updateSelections(selections): void {
    this.selectedContributors = selections;
    this.selectedContributors = this.selectedContributors.slice();
  }

  /**
   * Get user's selections from multi-select component and transform into data format expected
   * by edit-book component (Contributor/BookContributor).
   */
  getSelections(): void {
    let selections = this.selectedContributors;
    if (selections != undefined && selections.length > 0) {
      for (let contributor of selections) {
        let newEntry: Contributor = new Contributor();
        newEntry.bookId = 0; // Purposely inititalizing as zero instead of this.data.bookId, so we can identify the contributors that are new since the last save
        if (this.lastNewContributor && contributor.option == this.lastNewContributor.id) {
          newEntry.contributor = this.lastNewContributor;
        } else {
          newEntry.contributor = this.allContributors.find(entry => {
            if (entry.id === contributor.option) {
              // Recast return value to avoid typescript compilation errors ("find" method returns boolean by default)
              return entry as any;
            }
          }); 
        } 
        newEntry.contributorId = contributor.option;
        newEntry.contributorType = this.rolesSelect.value;
        newEntry.contributorTypeId = this.rolesSelect.value['id'];
        this.contributorsToAdd.push(newEntry);        
      }
    }
    console.info('DIALOG(Add contributors): done setting contributors to add to the book', this.contributorsToAdd);
    this.dialogRef.close();
  }

  /**
   * Close the dialog when the user clicks the "cancel" button, reinitialize the array that will be returned.
   */
  closeDialog(): void {
    // User canceled, so clear the contributorsToAdd array
    this.contributorsToAdd = new Array<Contributor>();
    this.dialogRef.close();
  }

  /**
   * Save new contributor to db, then update the component's list of all contributors
   * @param newContributor 
   */
  saveNewContributor(newContributor: ContributorPersonalInfo): void {
    // Save the new contributor via service, then add it to the multi-select
    this.contributorService.saveContributor(newContributor)
    .subscribe(contributor => {
      this.lastNewContributor = contributor;
      this.selectedContributors.push({
        option: contributor.id,
        displayText: contributor.lastName + ', ' + contributor.firstName + ' ' + contributor.middleName
      });
      // Reassign the array to a new reference so angular can detect changes in the child component
      this.selectedContributors = this.selectedContributors.slice();
      this.contributorService.getContributors().subscribe(contributors => {            
        this.allContributors = contributors;
        this.isUpdating = false;
      });
    });
  }

  ////////////
  // EVENTS //
  ////////////
  /**
   * Displays the second-level of dialog to allow creation of brand new contributors.
   */
  showCreateContributor(): void {
    let newContributor = new ContributorPersonalInfo();
    // Create new dialog with data that matches ContributorDataInterface
    const dialogRef = this.dialog.open(DialogNewContributorComponent, {
      width: '80%',
      data: {
        isNewContributor: true,
        contributor: newContributor
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === -1) { return; }
      this.isUpdating = true;
      this.saveNewContributor(result);
    });

  }
}
