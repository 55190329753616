<div class="row__save" style="margin-left:auto;">
    <h1 *ngIf="isNewContributor">Create new contributor</h1>
    <h1 *ngIf="!isNewContributor">Editing: <small>{{ contributor.firstName }} {{ contributor.middleName }} {{ contributor.lastName }}</small></h1>
    <div class="col">
        <span class="helper__success" *ngIf="saveSuccessful">
            <mat-icon>check</mat-icon> Changes were saved {{ lastSavedDate | timeAgo }}
        </span>
        <button mat-raised-button class="button__save" (click)="saveContributor()"
            [disabled]="!contributorForm.form.valid">
            <mat-icon>save</mat-icon> Save changes
        </button>
    </div>
</div>
<form #contributorForm="ngForm">
    <!-- LOADING STATE -->
    <div class="row" *ngIf="isLoading && !hasError">
        <div class="col-12">
            <mat-card class="card__loading">
                <mat-card-title>
                    <span class="title">Loading contributor...</span>
                </mat-card-title>
                <mat-card-content>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <!-- ERROR STATE -->
    <div class="row" *ngIf="!isLoading && hasError">
        <div class="col-12">
            <mat-card class="card__loading">
                <mat-card-title>
                    <span class="title">Error</span>
                </mat-card-title>
                <mat-card-content>
                    <p>We couldn't find a contributor with the id: {{ errorId }}.</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div *ngIf="!isLoading && !hasError">
        <div class="row">
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                        [ngClass]="{'valid_and_required': lastName.valid, 'invalid': lastName.invalid}"
                        id="input__lastName">Last name</span>
                </div>
                <input #lastName="ngModel" type="text" class="form-control" [(ngModel)]="contributor.lastName"
                    name="lastName" aria-labelledby="input__lastName" required>
            </div>
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                        [ngClass]="{'valid_and_required': firstName.valid, 'invalid': firstName.invalid}"
                        id="input__firstName">First name</span>
                </div>
                <input #firstName="ngModel" type="text" class="form-control" [(ngModel)]="contributor.firstName"
                    name="firstName" aria-labelledby="input__firstName" required>
            </div>
            <div class="input-group col-4">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="input__middleName">Middle name</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="contributor.middleName" name="middleName"
                    aria-labelledby="input__middleName">
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-card class="card__heritages">
                    <mat-card-title>
                        <span class="title">Heritage</span>
                    </mat-card-title>
                    <mat-card-content>
                        <app-multi-select [id]="'heritageMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                            [multiSelectOptions]="heritageOptions" [selectionsToReturn]="selectedHeritages"
                            (onSelectionsChanged)="setSelectedHeritages($event)" [ariaLabel]="'heritage'">
                        </app-multi-select>
                        <label for="contributorHeritageNotes" class="sr-only">Heritage notes</label> 
                        <div class="input-group">
                            <textarea type="text" id="contributorHeritageNotes" class="form-control"
                                [(ngModel)]="contributor.culturalHeritageNotes" name="contributorHeritageNotes"
                                placeholder="Notes"></textarea>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="input__specificCulture">Specific culture</span>
                            </div>
                            <input type="text" class="form-control" [(ngModel)]="contributor.specificCulture"
                                name="specificCulture" aria-labelledby="input__specificCulture">
                        </div>
                        <div class="row mat-row">
                            <mat-checkbox #checkContested name="heritageContested"
                                [(ngModel)]="contributor.culturalHeritageContested" (change)="pretendToBeRadio($event)">
                                Heritage
                                contested</mat-checkbox>
                        </div>
                        <div class="row mat-row">
                            <mat-checkbox #checkVerified name="heritageVerified" [(ngModel)]="contributor.verified"
                                (change)="pretendToBeRadio($event)">Heritage verified
                            </mat-checkbox>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="input__verificationSource">Verification source</span>
                            </div>
                            <input type="text" class="form-control" [(ngModel)]="contributor.verificationSource"
                                name="verificationSource" aria-labelledby="input__verificationSource">
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card__gender">
                    <mat-card-title>
                        <span class="title">Gender</span>
                    </mat-card-title>
                    <mat-card-content>
                        <app-multi-select [id]="'gendersMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                            [multiSelectOptions]="genderOptions" [selectionsToReturn]="selectedGenders"
                            (onSelectionsChanged)="setSelectedGenders($event)" [ariaLabel]="'gender'">
                        </app-multi-select>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-6">

                <mat-card class="card__religion">
                    <mat-card-title>
                        <span class="title">Religion</span>
                    </mat-card-title>
                    <mat-card-content>
                        <app-multi-select [id]="'religionMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                            [multiSelectOptions]="religionOptions" [selectionsToReturn]="selectedReligions"
                            (onSelectionsChanged)="setSelectedReligions($event)" [ariaLabel]="'religion'">
                        </app-multi-select>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card__disability">
                    <mat-card-title>
                        <span class="title">Disability/Condition</span>
                    </mat-card-title>
                    <mat-card-content>
                        <app-multi-select [id]="'disabilityMultiSelect'" [hasFilter]="true" [label]="'Start typing to filter'"
                            [multiSelectOptions]="disabilityOptions" [selectionsToReturn]="selectedDisabilities"
                            (onSelectionsChanged)="setSelectedDisabilities($event)" [ariaLabel]="'disability'">
                        </app-multi-select>
                        <label for="contributorDisabilityNotes" class="sr-only">Disability/condition notes</label>
                        <div class="input-group">
                            <textarea type="text" id="contributorDisabilityNotes" class="form-control"
                                [(ngModel)]="contributor.disabilityNotes" name="contributorDisabilityNotes"
                                placeholder="Notes"></textarea>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card__sexuality">
                    <mat-card-title>
                        <span class="title">Sexuality</span>
                    </mat-card-title>
                    <mat-card-content>
                        <mat-checkbox name="lgbtq" [(ngModel)]="contributor.lgbtq">LGBTQ+</mat-checkbox>
                        <label for="contributorSexualityNotes" class="sr-only">Sexuality notes</label>
                        <div class="input-group">
                            <textarea type="text" id="contributorSexualityNotes" class="form-control"
                                [(ngModel)]="contributor.lgbtqNotes" name="contributorSexualityNotes"
                                placeholder="Notes"></textarea>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card__wisconsin">
                    <mat-card-title>
                        <span class="title">Wisconsin</span>
                    </mat-card-title>
                    <mat-card-content>
                        <mat-checkbox name="wisconsinContributor" [(ngModel)]="contributor.wisconsinContributor">Wisconsin author/illustrator</mat-checkbox>
                        <label for="contributorWisconsinContributorNotes" class="sr-only">Wisconsin notes</label>
                        <div class="input-group">
                            <textarea type="text" id="contributorWisconsinContributorNotes" class="form-control"
                                [(ngModel)]="contributor.wisconsinContributorNotes" name="contributorWisconsinContributorNotes"
                                placeholder="Notes"></textarea>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row" *ngIf="!isLoading">
            <div class="col-12">
                <mat-card class="card__books">
                    <mat-card-title>
                        <span class="title">Books</span>
                    </mat-card-title>
                    <mat-card-content>
                        <span class="hint">Note: Links open in a new tab</span>
                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngFor="let book of contributorBooks">
                                <a href="/book/{{book.id}}" target="_blank"
                                    rel="noopener noreferrer">{{ book.title }}</a>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</form>