import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

// Classes
import { Contributor, ContributorType, ContributorPersonalInfo,  ContributorHeritage } from 'src/app/classes/Contributor';
import { Gender } from 'src/app/classes/Gender';

@Injectable()
export class ContributorService {

  private hostName: string;
  private contributorUrlBase = '/api/Contributors';

  multiSelectOptions$: Observable<any>;
  private multiSelectOptionsSubject = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {
    this.hostName = environment.apiHost;
    this.multiSelectOptions$ = this.multiSelectOptionsSubject.asObservable();
  }

  /**
   * Get all existing contributors from the API
   */
  getContributors(): Observable<ContributorPersonalInfo[]> {
    return this.http.get<ContributorPersonalInfo[]>(this.hostName + this.contributorUrlBase);
  }

  /**
   * Get all contributor types from the API
   */
  getContributorTypes(): Observable<ContributorType[]> {
    return this.http.get<ContributorType[]>(this.hostName + this.contributorUrlBase + '/Types')
  }

  getContributorGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(this.hostName + this.contributorUrlBase + '/Genders');
  }

  

  getContributorHeritages(): Observable<ContributorHeritage[]> {
    return this.http.get<ContributorHeritage[]>(this.hostName + this.contributorUrlBase + '/Heritages');
  }

  /**
   * Save a newly created contributor via the API. Due to expectations on the back end, the new contributor's "id" must be set
   * to zero in order to save properly.
   * @param {ContributorPersonalInfo} contributor The new contributor object
   */
  saveContributor(contributor: ContributorPersonalInfo) : Observable<ContributorPersonalInfo> {
    if (contributor.id === 0 || contributor.id === null || contributor.id === undefined) {
      contributor.id = 0;  //needs to be zero, not null or undefined, for the model to match on the backend      
      return this.http.post<ContributorPersonalInfo>(this.hostName+this.contributorUrlBase, contributor);
    }
    else {      
      return this.http.put<ContributorPersonalInfo>(this.hostName+this.contributorUrlBase+"/"+contributor.id, contributor);
    }
  }
}
