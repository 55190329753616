<mat-card class="card__list-biliography">
    <div class="row">
        <div class="col-10">
            <span class="title">All Bibliographies / Booklists</span>
          </div>
          <div class="col text-right">
             <button mat-flat-button color="accent"
                [routerLink]="['/bibliography', 0]">
                <mat-icon>add</mat-icon>Add Bibliography
            </button>
          </div>
    </div>
    
<mat-card-content>
    <table  class="table table-bordered table-striped">
        <col width="10%">
        <col width="74%">
        <col width="8%">
        <col width="8%">
        <thead class="thead-light">
            <tr>
                <th scope="col" class="text-center" >Edit</th>
                <th scope="col">Name</th>
                <th scope="col" class="text-center">Books</th>
                <th scope="col" class="text-center">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let bibliography of allBibliography">
                <td scope="col" class="text-center"><button mat-stroked-button color="accent"
                    [routerLink]="['/bibliography', bibliography.id]">Edit</button></td>
                <td scope="col">{{bibliography.name}}</td>
                <td  scope="col" class="text-center">{{bibliography.numberOfBooks}}</td>
                <td scope="col" class="text-center"><button mat-stroked-button mat-icon-button color="primary"
                    (click)="confirmDelete(bibliography)" aria-label="delete bibliography">
                    <mat-icon>delete_forever</mat-icon>
                </button></td>
            </tr>
        </tbody>
    </table>
</mat-card-content>
</mat-card>