import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { SharedModule } from '../shared.module';

import { AuthService } from '../services/auth.service';

import { LoginComponent } from '../components/login/login.component';
import { LogoutComponent } from '../components/logout/logout.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
      SharedModule
  ],
  declarations: [ LoginComponent, LogoutComponent ],
  providers: [ AuthService ]
})
export class AuthModule { }