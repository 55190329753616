import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that filters objects in the bound array and returns and array of strings matching the text input.
 */
@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      let text = '';
      if (item.displayText === undefined) {
        try {
          text = item.lastName + item.firstName + item.middleName
        } catch {
          console.info('Couldn\'t filter object by lastName, firstName, middleName');
          return [];
        }
      } else {
        text = item.displayText;
      } 
      
      return text.toLowerCase().includes(searchText);
      });
    }
}
