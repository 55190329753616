import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { DialogCCBCSubjectsComponent } from 'src/app/components/dialog-ccbcsubjects/dialog-ccbcsubjects.component';
import { MatDialog } from '@angular/material/dialog';

//services
import { ListBooksService } from 'src/app/services/list-books.service';

//interface for multi-selection
import { IMultiSelectOptions } from '../multi-select/multi-select.component';

import {
  Recommendation, AgeGradeRange, RecommendationsAgeGradeRange, ContentType, RecommendationsContentType, CCBCSubject, RecommendationsCCBCSubject,
  Format, RecommendationsFormat, WisconsinInterest, RecommendationsWisconsinInterest
} from 'src/app/classes/Recommendation';


@Component({
  selector: 'app-book-recommendation',
  templateUrl: './book-recommendation.component.html',
  styleUrls: ['./book-recommendation.component.scss']
})
export class BookRecommendationComponent implements OnInit {

  @Input() model: Recommendation;
  @Input() isActive: boolean;

  constructor(private bookService: ListBooksService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.model.newBookCover = null;

    this.getAgeGradeRanges();
    this.getAllCCBCSubjects();

    // Transforms Array<RecommendationsCCBCSubject> into an array of 
    // generic data objects for the multi-select component, and appends them to this.selectedCCBCSubjects
    this.transformRecommendationCCBCSubject(this.model.recommendationsCCBCSubjects);

    this.getContentTypes();
    this.getAllFormats();
    this.transformRecommendationFormat(this.model.recommendationsFormats);
    this.getAllWisconsinInterests();
    this.transformRecommendationWisconsinInterest(this.model.recommendationsWisconsinInterests);
  }

  //Book Cover display
  public get coverUrl(): SafeUrl {
    let temp = null;

    if (this.model.coverImage) {

      // If we know the correct content type, use it, else default to png
      let tempCoverImageContentType = this.model.coverImageContentType ? this.model.coverImageContentType : 'image/png';
      temp = this.sanitizer.bypassSecurityTrustUrl('data:' + tempCoverImageContentType + ';base64,' + this.model.coverImage);
    }

    return temp;
  };

  //book cover upload
  coverWrongFileType: boolean = false;
  coverImageTooBig: boolean = false;
  imgUrl: any = null;

  // Handle keyboard event triggering the select file "button"
  openFileBrowser(event: KeyboardEvent) {
    let target = event.target as any;
    target.click();
  }

  handleFileInput(files): void {
    this.coverWrongFileType = false;
    this.coverImageTooBig = false;

    //validate upload
    if (files.length !== 1) {
      return;
    }

    let type = ['image/png', 'image/jpeg', 'image/jpg'];
    this.coverWrongFileType = type.findIndex(x => files[0].type === x) < 0;

    let maxSize = 500000;
    this.coverImageTooBig = files[0].size > maxSize;

    if (this.coverWrongFileType || this.coverImageTooBig) {
      return;
    }

    //add to recommendation
    this.model.newBookCover = files[0];

    //display img preview
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgUrl = reader.result;
    }
  }

  // ----------------- AgeGradeRanges - checkboxes
  allAgeGradeRanges: Array<AgeGradeRange> = new Array<AgeGradeRange>();
  selectedAgeGradeRanges: Array<RecommendationsAgeGradeRange> = new Array<RecommendationsAgeGradeRange>();

  getAgeGradeRanges(): void {
    this.bookService.getAllAgeGradeRangeOptions().subscribe(ageGradeRanges => {
      if (ageGradeRanges != undefined && ageGradeRanges.length > 0) {
        this.populateAgeGradeRange(ageGradeRanges)
      }
    })
  }

  populateAgeGradeRange(ageGradeRanges: Array<AgeGradeRange>): void {
    this.selectedAgeGradeRanges = this.model.recommendationsAgeGradeRanges;
    this.allAgeGradeRanges = ageGradeRanges;
    this.allAgeGradeRanges.forEach(item => {

      if (this.selectedAgeGradeRanges.filter(age => age.ageGradeRangeId === item.ageGradeRangeId).length > 0) {
        item.selected = true;
      }
      else {
        item.selected = false;
      }
    })
  }

  onClickAgeGradeRange() {
    let updatedAgeGradeRanges = new Array<RecommendationsAgeGradeRange>();
    this.allAgeGradeRanges.forEach(all => {
      if (all.selected) {
        const updatedAgeGradeRange: RecommendationsAgeGradeRange = {
          bookId: this.model.bookId,
          ageGradeRangeId: all.ageGradeRangeId,
          ageGradeRange: null,
          recommendation: null
        }
        updatedAgeGradeRanges.push(updatedAgeGradeRange);
      }
    });
    this.model.recommendationsAgeGradeRanges = updatedAgeGradeRanges;
  }

  // ----------------- CCBCSubjects - multiselect with add Subjects button
  allCCBCSubjects: Array<CCBCSubject> = new Array<CCBCSubject>();
  ccbcSubjectOptions: Array<any> = new Array<any>();
  selectedCCBCSubjects: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedCCBCSubjectsToSave: Array<RecommendationsCCBCSubject> = new Array<RecommendationsCCBCSubject>();

  getAllCCBCSubjects(): void {
    this.bookService.getAllCCBCSubjectOptions().subscribe(subjects => {

      if (subjects !== undefined && subjects.length > 0) {
        this.allCCBCSubjects = subjects;
        // Convert to multi-select options and set the multi-select input param
        this.assembleCCBCSubjectOptions(subjects);
      }
    });
  }

  // Convert an array of CCBCSubject objects to IMultiSelectOptions instances,
  // and set this.ccbcSubjectOptions - which is passed as an input param to the multi-select component 
  assembleCCBCSubjectOptions(subjects: Array<CCBCSubject>): void {
    
    let tempCCBCSubjectOptions: Array<any> = new Array<any>();

    for (let subject of subjects) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: subject.ccbcSubjectId,
        displayText: subject.name
      }

      tempCCBCSubjectOptions.push(data);

    }
    
    //this.ccbcSubjectOptions = JSON.parse(JSON.stringify(tempCCBCSubjectOptions));
    this.ccbcSubjectOptions = tempCCBCSubjectOptions;

  }

  // Transforms an array of RecommendationsCCBCSubject objects into an array of 
  // generic data objects for the multi-select component, and appends them to this.selectedCCBCSubjects
  transformRecommendationCCBCSubject(recommendationCCBCSubjects: Array<RecommendationsCCBCSubject>) {
    recommendationCCBCSubjects.forEach(item => {
      let data = {
        option: item.ccbcSubjectId,
        displayText: item.ccbcSubject.name
      };
      this.selectedCCBCSubjects.push(data);
    });
  }

  setSelectedCCBCSubject(subjects: Array<IMultiSelectOptions>): void {
    this.selectedCCBCSubjects = subjects;
    this.selectedCCBCSubjects = this.selectedCCBCSubjects.slice();
    this.updateRecommendationCCBCSubjectToSave();
  }

  updateRecommendationCCBCSubjectToSave(): void {
    // Transform multi-select data 
    let updatedRecommendationCCBCSubjects = new Array<RecommendationsCCBCSubject>();
    this.selectedCCBCSubjects.forEach(item => {
      const recommendationCCBCSubject: RecommendationsCCBCSubject = {
        bookId: this.model.bookId,
        ccbcSubjectId: item.option,
        ccbcSubject: null,
        recommendation: null
      }
      updatedRecommendationCCBCSubjects.push(recommendationCCBCSubject);
    });
    this.model.recommendationsCCBCSubjects = updatedRecommendationCCBCSubjects;
  }

  // ----------------- ContentTypes - checkboxes
  allContentTypes: Array<ContentType> = new Array<ContentType>();
  selectedContentTypes: Array<RecommendationsContentType> = new Array<RecommendationsContentType>();

  getContentTypes(): void {
    this.bookService.getAllContentTypeOptions().subscribe(contentTypes => {
      if (contentTypes != undefined && contentTypes.length > 0) {
        this.populateContentTypes(contentTypes)
      }
    })
  }

  populateContentTypes(contentTypes: Array<ContentType>): void {
    this.selectedContentTypes = this.model.recommendationsContentTypes;
    this.allContentTypes = contentTypes;
    this.allContentTypes.forEach(item => {

      if (this.selectedContentTypes.filter(ct => ct.contentTypeId === item.contentTypeId).length > 0) {
        item.selected = true;
      }
      else {
        item.selected = false;
      }
    })
  }

  onClickContentType() {
    let updatedContentTypes = new Array<RecommendationsContentType>();
    this.allContentTypes.forEach(all => {
      if (all.selected) {
        const updatedContentType: RecommendationsContentType = {
          bookId: this.model.bookId,
          contentTypeId: all.contentTypeId,
          contentType: null,
          recommendation: null
        }
        updatedContentTypes.push(updatedContentType);
      }

    })
    this.model.recommendationsContentTypes = updatedContentTypes;
  }


  // ----------------- Formats - multiselect
  allFormats: Array<Format> = new Array<Format>();
  formatOptions: Array<any> = new Array<any>();
  selectedFormats: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedFormatsToSave: Array<RecommendationsFormat> = new Array<RecommendationsFormat>();

  getAllFormats(): void {
    this.bookService.getAllFormatOptions().subscribe(formats => {
      if (formats != undefined && formats.length > 0) {
        this.allFormats = formats;
        this.assembleFormatOptions(formats);
      }
    })
  }

  assembleFormatOptions(formats: Array<Format>): void {
    for (let format of formats) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: format.formatId,
        displayText: format.name
      }
      this.formatOptions.push(data);
    }
    this.formatOptions = this.formatOptions.slice();
  }

  transformRecommendationFormat(recommendationFormats: Array<RecommendationsFormat>) {
    recommendationFormats.forEach(item => {
      let data = {
        option: item.formatId,
        displayText: item.format.name
      };
      this.selectedFormats.push(data);
    });
  }

  setSelectedFormat(formats: Array<IMultiSelectOptions>): void {
    this.selectedFormats = formats;
    this.selectedFormats = this.selectedFormats.slice();
    this.updateRecommendationFormatToSave();
  }

  updateRecommendationFormatToSave(): void {
    let updatedFormats = new Array<RecommendationsFormat>();
    this.selectedFormats.forEach(item => {
      const recoomendationFormat: RecommendationsFormat = {
        bookId: this.model.bookId,
        formatId: item.option,
        format: null,
        recommendation: null
      }
      updatedFormats.push(recoomendationFormat);
    });
    this.model.recommendationsFormats = updatedFormats;
  }

  // ----------------- WisconsinInterests - multiselect
  allWisconsinInterests: Array<WisconsinInterest> = new Array<WisconsinInterest>();
  wisconsinInterestOptions: Array<any> = new Array<any>();
  selectedWisconsinInterests: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  selectedWisconsinInterestsToSave: Array<RecommendationsWisconsinInterest> = new Array<RecommendationsWisconsinInterest>();

  getAllWisconsinInterests(): void {
    this.bookService.getAllWisconsinInterestOptions().subscribe(interests => {
      if (interests != undefined && interests.length > 0) {
        this.allWisconsinInterests = interests;
        this.assembleWisconsinInterestOptions(interests);
      }
    })
  }

  assembleWisconsinInterestOptions(interests: Array<WisconsinInterest>): void {
    for (let interest of interests) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: interest.id,
        displayText: interest.name
      }
      this.wisconsinInterestOptions.push(data);
    }
    this.wisconsinInterestOptions = this.wisconsinInterestOptions.slice();
  }

  transformRecommendationWisconsinInterest(recommendationWisconsinInterests: Array<RecommendationsWisconsinInterest>) {
    recommendationWisconsinInterests.forEach(item => {
      let data = {
        option: item.wisconsinInterestId,
        displayText: item.wisconsinInterest.name
      };
      this.selectedWisconsinInterests.push(data);
    });
  }

  setSelectedWisconsinInterest(interests: Array<IMultiSelectOptions>): void {
    this.selectedWisconsinInterests = interests;
    this.selectedWisconsinInterests = this.selectedWisconsinInterests.slice();
    this.updateRecommendationWisconsinInterestToSave();
  }

  updateRecommendationWisconsinInterestToSave(): void {
    let updatedWisconsinInterests = new Array<RecommendationsWisconsinInterest>();
    this.selectedWisconsinInterests.forEach(item => {
      const recommendationWisconsinInterest: RecommendationsWisconsinInterest = {
        bookId: this.model.bookId,
        wisconsinInterestId: item.option,
        wisconsinInterest: null,
        recommendation: null
      }
      updatedWisconsinInterests.push(recommendationWisconsinInterest);
    });
    this.model.recommendationsWisconsinInterests = updatedWisconsinInterests;
  }

  /**
   * Displays a modal where users can create, edit, and delete subjects
   */

  showManageSubjectsModal(): void {
    // Create new dialog with data that matches IPublisherData
    const dialogRef = this.dialog.open(DialogCCBCSubjectsComponent, {
      width: '80%',
      data: {
        allCCBCSubjects: this.allCCBCSubjects
      }
    });

    // When the modal dialog closes
    dialogRef.afterClosed().subscribe(result => {

      // If the dialog component didn't return an array of all subjects
      if(result === undefined || result.allSubjects === undefined){

        // We should never get here unless there's a bug with the dialog
        
        // Refetch all the subjects from the backend
        // and update this.allCCBCSubjects and this.ccbcSubjectOptions
        this.getAllCCBCSubjects();

      }
      else{
        // Update the list of all CCBC Subjects for this component
        this.allCCBCSubjects = result.allSubjects; 
      }

      // update this.ccbcSubjectOptions for the multi-select component
      this.assembleCCBCSubjectOptions(this.allCCBCSubjects);

    });
  }

  //////////////
  // Tiny MCE //
  //////////////
  public isTinyMCEAlive: boolean = false;
  public tinyMCEInput: string = '';
  public tinyMCELimit: number = 2000;
  public tinyMCEHasError: boolean = false;
  public TINY_MCE_REGEX: RegExp = /&nbsp;/;

  @Output()
  isRichTextValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Set initial input value equal to back end data
   */
  initTinyMCE(): void {
    if (this.isTinyMCEAlive) { return; }
    if (this.model.ccbcChoicesAnnotation != null) {
      this.tinyMCEInput = this.model.ccbcChoicesAnnotation;
    }    
    this.tinyMCEHasError = this.checkTinyMCEHasError();
    this.isTinyMCEAlive = true;
  }

  /**
   * Get length of editor input after stripping non-breaking spaces. We should not get any of these
   * if input validation works, but we should check for them just in case.
   */
  getTinyMCEInputLength(): number {
    const length = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim().length;    
    return length;
  }

  /**
   * Check if input length is within limit
   */
  checkTinyMCEHasError(): boolean {
    const length = this.getTinyMCEInputLength();
    return length > this.tinyMCELimit;
  }

  /**
   * Check for length within constraints and update model data to match editor input
   * (minus auto-inserted non-breaking spaces to save on character limit)
   */
  onTinyMCEInput(): void {
    // Store rich text but replace '&nbsp;' to save on characters
    this.model.ccbcChoicesAnnotation = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim();
    // Update UI helper and bubble up event to disable saving the book
    this.tinyMCEHasError = this.checkTinyMCEHasError();
    this.isRichTextValid.emit(this.tinyMCEHasError);
  }
}
