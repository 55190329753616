export class SubjectDetail {

    constructor() {
        this.heritageNotes = '';
    }
    id: number = 0;
    heritageNotes: string;  
    religionNotes: string;    
    lgbtqNotes: string;
    secondaryNotes: string;   
    disabilityNotes: string;
}