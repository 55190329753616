import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(private titleService: Title, private auth: AuthService) { }

  ngAfterViewInit() {
    this.titleService.setTitle('Logged out | CCBC Book Search');
    this.auth.logout();    
  }

}
