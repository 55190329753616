/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./search-books.component";
import * as i8 from "./search-books.component.scss.shim.ngstyle";
import * as i9 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/router";
var styles_DialogConfirmSearchComponent = [];
var RenderType_DialogConfirmSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogConfirmSearchComponent, data: {} });
export { RenderType_DialogConfirmSearchComponent as RenderType_DialogConfirmSearchComponent };
function View_DialogConfirmSearchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["Leave with unsaved changes?"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
function View_DialogConfirmSearchComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["Are you done updating this ", "?"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.model; _ck(_v, 2, 0, currVal_1); }); }
export function View_DialogConfirmSearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogConfirmSearchComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogConfirmSearchComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Click \"Search\" to continue. Unsaved changes will be lost."])), (_l()(), i0.ɵeld(8, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["aria-label", "cancel search"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).dialogRef.close(i0.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(12, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Stay here"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["aria-label", "search"], ["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).dialogRef.close(i0.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(16, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Search"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.action == "new"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.data.action == "update"); _ck(_v, 3, 0, currVal_1); var currVal_6 = "cancel search"; var currVal_7 = ""; _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_12 = "search"; var currVal_13 = true; _ck(_v, 16, 0, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_2 = (i0.ɵnov(_v, 11).disabled || null); var currVal_3 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_4 = (i0.ɵnov(_v, 12).ariaLabel || null); var currVal_5 = i0.ɵnov(_v, 12).type; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = (i0.ɵnov(_v, 15).disabled || null); var currVal_9 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_10 = (i0.ɵnov(_v, 16).ariaLabel || null); var currVal_11 = i0.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_DialogConfirmSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-confirm-search", [], null, null, null, View_DialogConfirmSearchComponent_0, RenderType_DialogConfirmSearchComponent)), i0.ɵdid(1, 49152, null, 0, i7.DialogConfirmSearchComponent, [i1.MAT_DIALOG_DATA], null, null)], null, null); }
var DialogConfirmSearchComponentNgFactory = i0.ɵccf("dialog-confirm-search", i7.DialogConfirmSearchComponent, View_DialogConfirmSearchComponent_Host_0, {}, {}, []);
export { DialogConfirmSearchComponentNgFactory as DialogConfirmSearchComponentNgFactory };
var styles_SearchBooksComponent = [i8.styles];
var RenderType_SearchBooksComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SearchBooksComponent, data: {} });
export { RenderType_SearchBooksComponent as RenderType_SearchBooksComponent };
export function View_SearchBooksComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["searchText", 1]], null, 0, "input", [["aria-label", "search books by title, author, publisher or year"], ["class", "form-control"], ["id", "searchBooks"], ["placeholder", "Search books by title, author, publisher or year"], ["type", "text"]], null, [[null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.enter" === en)) {
        var pd_0 = (_co.searchBooks(i0.ɵnov(_v, 1).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["aria-label", "submit book clear text"], ["class", "clear-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearchText(i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i0.ɵdid(4, 9158656, null, 0, i10.MatIcon, [i0.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["close"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "button", [["aria-label", "submit book search"], ["class", "search-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchBooks(i0.ɵnov(_v, 1).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i0.ɵdid(8, 9158656, null, 0, i10.MatIcon, [i0.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["search"]))], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).inline; var currVal_1 = (((i0.ɵnov(_v, 4).color !== "primary") && (i0.ɵnov(_v, 4).color !== "accent")) && (i0.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 8).inline; var currVal_3 = (((i0.ɵnov(_v, 8).color !== "primary") && (i0.ɵnov(_v, 8).color !== "accent")) && (i0.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_SearchBooksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "search-books", [], null, null, null, View_SearchBooksComponent_0, RenderType_SearchBooksComponent)), i0.ɵdid(1, 114688, null, 0, i7.SearchBooksComponent, [i11.Router, i1.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchBooksComponentNgFactory = i0.ɵccf("search-books", i7.SearchBooksComponent, View_SearchBooksComponent_Host_0, {}, {}, []);
export { SearchBooksComponentNgFactory as SearchBooksComponentNgFactory };
