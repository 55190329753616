import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


/**
 * Component that displays a confirmation dialog upon trying to delete a book.
 */
@Component({
  selector: 'dialog-confirm-search',
  templateUrl: 'dialog-confirm-search.component.html'
})
export class DialogConfirmSearchComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'search-books',
  templateUrl: './search-books.component.html',
  styleUrls: ['./search-books.component.scss']
})
export class SearchBooksComponent implements OnInit {

  constructor(
    private router: Router,
    public confirmSearchDialog: MatDialog) { }

  ngOnInit() { }

  /**
   * Reloads the Angular route, including state data received by searchText.
   * @param {string} searchText The query param to add as state data
   */
  searchBooks(searchText: string) {
    if (searchText !== null) {
      let navigatationExtras: NavigationExtras = {
        state: {
          data: searchText
        }
      }
      this.router.routeReuseStrategy.shouldReuseRoute = () => false; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
      this.router.onSameUrlNavigation = 'reload';

      // If the user is currently creating a new book or updating an 
      // existing one, ask for confirmation before navigating away      
      if (this.router.url.indexOf('book/') != -1) {
        // Get the book id to determine if user is creating or updating a book
        let id = this.router.url.split('book/')[1];
        let action = id === '0' ? 'new' : 'update';
        // Show confirmation dialog
        this.showConfirmationDialog({ action: action, model: 'book'}, navigatationExtras);
      } else if (this.router.url.indexOf('contributor/') != -1) {
        // Get the contributor id to determine if user is creating or updating
        let id = this.router.url.split('contributor/')[1];
        let action = id === '0' ? 'new' : 'update';
        // Show confirmation dialog
        this.showConfirmationDialog({ action: action, model: 'contributor'}, navigatationExtras);
      } else {
        this.router.navigate(['/books'], navigatationExtras);
      }
    }
  }

  clearSearchText(searchText: HTMLInputElement) {
    searchText.value = '';
    searchText.focus();
  }

  /**
   * Display a dialog that executes the search upon confirmation
   * @param data Context-specific information for the dialog to display
   * @param navExtras 
   */
  showConfirmationDialog(data: any, navExtras): void {
    const dialogRef = this.confirmSearchDialog.open(DialogConfirmSearchComponent, {
      data: {
        action: data.action,
        model: data.model
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // If true, the user has confirmed
      if (result) {
        this.router.navigate(['/books'], navExtras);
      }
    });
  }
}
