<mat-card class="card__recommend--recommended">
    <mat-card-content>
        <div class="row mat-row">
            <mat-checkbox [(ngModel)]="model.isRecommended" name="recommended"><span class="title">Recommended</span>
            </mat-checkbox>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="card__recommend--cover">
    <mat-card-title>
        <span class="title">Cover</span>
    </mat-card-title>
    <mat-card-content>
        <div class="row">
            <div class="col-6 upload">                
                <input class="hide" id="input_book_cover" type="file" (change)="handleFileInput($event.target.files)">
                <label for="input_book_cover" class="label-button mat-flat-button mat-button-base mat-accent" tabindex="0" (keyup.enter)="openFileBrowser($event)">Select File</label>
                <p>.png, .jpeg, and .jpg </p>
                <p>500kb or less</p>
                <mat-error *ngIf="coverWrongFileType">Wrong file type</mat-error>
                <mat-error *ngIf="coverImageTooBig">Image too big</mat-error>
            </div>
            <div class="col-6">
                <img *ngIf="coverUrl && !imgUrl" class="book-cover" [src]="coverUrl" alt="Cover Image" />
                <img *ngIf="imgUrl" class="book-cover" [src]="imgUrl" alt="New Cover Image" />
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="card__recommend--details">
    <mat-card-title>
        <span class="title">CCBC Details</span>
        <button mat-flat-button color="accent" (click)="showManageSubjectsModal()">
            <mat-icon>edit</mat-icon> Manage subjects
        </button>
    </mat-card-title>
    <mat-card-content>
        <p>Associate this book with a subject by selecting the subject from the dropdown. Filter the available options by typing in
            the subject you're looking for. Remove a subject from this book by clicking the &quot;x&quot; next to the name.</p>
        <!--subjects-->
        <div class="row multi-select-group">
            <div class="col multi-select-group__prepend">
                <span class="input-group-text">Subjects</span>
            </div>
            <div class="col multi-select-group__select">
                <app-multi-select #subjectsMultiSelect [id]="'subjectsMultiSelect'" [hasFilter]="true" [label]="'Filter by name'" [multiSelectOptions]="ccbcSubjectOptions"
                    [selectionsToReturn]="selectedCCBCSubjects" (onSelectionsChanged)="setSelectedCCBCSubject($event)" [ariaLabel]="'subjects'">
                </app-multi-select>
            </div>
        </div>

        <!--age recommendation-->
        <div class="input-group age-recommendation">
            <div class="input-group-prepend">
                <span class="input-group-text" id="input__ccbcAgeRecommendation">Age Recommendation</span>
            </div>
            <input type="text" class="form-control" aria-label="CCBCAgeRecommendation" maxlength="100"
                aria-describedby="input__ccbcAgeRecommendation" [(ngModel)]="model.ccbcAgeRecommendation"
                name="ccbcAgeRecommendation">
        </div>

        <!-- Choices annotation  -->
        <p class="title">Choices Annotation</p>
        <div class="">
            <label for="CCBCChoicesAnnotation" class="sr-only">choices annotation</label>
            <editor *ngIf="isActive" id="CCBCChoicesAnnotation" [(ngModel)]="tinyMCEInput"
                (ngModelChange)="onTinyMCEInput()" name="CCBCChoicesAnnotation"
                [init]="{valid_elements:'p,br,i,b,u,span,strong,em,ul,li,ol', plugins:'lists', menubar:'false', toolbar:'bold italic | bullist numlist', oninit: initTinyMCE()}"
                apiKey="25vqkf9hajnbjpua6m8ymgjs2hnqecl9odd07tfwuamo7u7h">
            </editor>
            <div class="row mat-row">
                <span class="helper helper__error" *ngIf="tinyMCEHasError"><mat-icon>warning</mat-icon> Max length exceeded!</span>
                <span class="flex"></span>
                <span class="helper"
                    [ngClass]="{ 'helper__error': tinyMCEHasError}">{{ getTinyMCEInputLength() }}/{{ tinyMCELimit }}
                    characters <mat-icon matTooltip="Character count includes hidden HTML tags to preserve text formatting">help_outline</mat-icon></span>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="card__recommend--other">
    <mat-card-content>
        <!--Ages/Grades-->
        <mat-card-title>
            <span class="title">Ages/Grades</span>
        </mat-card-title>
        <div class="checkbox-list">
            <mat-checkbox *ngFor="let item of allAgeGradeRanges; let i = index" [(ngModel)]="item.selected"
                name="ageGradeRange{{i}}" (ngModelChange)="onClickAgeGradeRange()">
                <span>{{item.name}}</span>
            </mat-checkbox>
        </div>

        <!--Content Type-->
        <mat-card-title>
            <span class="title">Content Type</span>
        </mat-card-title>
        <div class="checkbox-list">
            <mat-checkbox *ngFor="let item of allContentTypes; let i = index" [(ngModel)]="item.selected"
                id="contentType{{i}}" name="contentType{{i}}" (ngModelChange)="onClickContentType()">
                <span>{{item.name}}</span>
            </mat-checkbox>

        </div>

        <!-- Formats-->
        <div class="row multi-select-group">
            <div class="col multi-select-group__prepend">
                <span class="input-group-text">Formats</span>
            </div>
            <div class="col multi-select-group__select">
                <app-multi-select #formatsMultiSelect [id]="'formatsMultiSelect'" [label]="" [multiSelectOptions]="formatOptions"
                    [selectionsToReturn]="selectedFormats" (onSelectionsChanged)="setSelectedFormat($event)" [ariaLabel]="'formats'">
                </app-multi-select>
            </div>
        </div>

        <!-- WI Interests-->
        <div class="row multi-select-group">
            <div class="col multi-select-group__prepend">
                <span class="input-group-text">WI Interests</span>
            </div>
            <div class="col multi-select-group__select">
                <app-multi-select #wisconsinInterestsMultiSelect [id]="'wisconsinInterestsMultiSelect'" [label]=""
                    [multiSelectOptions]="wisconsinInterestOptions" [selectionsToReturn]="selectedWisconsinInterests"
                    (onSelectionsChanged)="setSelectedWisconsinInterest($event)" [ariaLabel]="'wisconsin interests'">
                </app-multi-select>
            </div>
        </div>
    </mat-card-content>
</mat-card>