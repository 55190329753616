import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';

// Components
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ListBooksComponent } from './components/list-books/list-books.component';
import { ListContributorsComponent } from './components/list-contributors/list-contributors.component';
import { EditBookComponent } from './components/edit-book/edit-book.component';
import { PageNewContributorComponent } from './components/add-edit-contributor/add-edit-contributor.component';
import { EditBibliographyComponent } from './components/edit-bibliography/edit-bibliography.component';
import { ListBibliographyComponent} from  './components/list-bibliography/list-bibliography.component';

const routes: Routes = [
  { path: '', redirectTo: '/books', pathMatch: 'full' },
  { path: 'books', component: ListBooksComponent, canActivate: [AuthService] },
  { path: 'book/:id', component: EditBookComponent, canActivate: [AuthService] },
  { path: 'contributors', component: ListContributorsComponent, canActivate: [AuthService] },
  { path: 'contributors/:page', component: ListContributorsComponent, canActivate: [AuthService] },
  { path: 'contributor/:id', component: PageNewContributorComponent, canActivate: [AuthService] },
  { path: 'bibliography/:id', component: EditBibliographyComponent, canActivate: [AuthService] },
  { path: 'bibliographies', component: ListBibliographyComponent, canActivate: [AuthService] },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
