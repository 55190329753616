import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filters an array by matching a given attribute to values in an additional given array
 */
@Pipe({
  name: 'removeDupes'
})
export class RemoveDuplicatesPipe implements PipeTransform {

  transform(sourceArray: any[], attributeToMatch: string, valuesToFilter: any[]): any[] {
    if(!sourceArray) return [];
    if(!valuesToFilter) return [];
    if(!attributeToMatch) return [];

    return sourceArray.filter(item => {
        for (var value in valuesToFilter) {
          if (item[attributeToMatch] === valuesToFilter[value]) {
            return false;
          }
        }
        return true;
      });
  }    
}
