import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
/* diversity subject + secondary diversity + gender Marker */
import { Heritage } from 'src/app/classes/Heritage';
import { Secondary } from 'src/app/classes/Secondary';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Religion } from 'src/app/classes/Religion';
import { Disability } from 'src/app/classes/Disability';
import { Lgbtq } from 'src/app/classes/Lgbtq';
import { Book } from 'src/app/classes/book';
import { BooksHeritages } from 'src/app/classes/BooksHeritages';
import { IMultiSelectOptions } from '../multi-select/multi-select.component';

import { ListBooksService } from 'src/app/services/list-books.service';
import { BooksReligions } from 'src/app/classes/BooksReligions';
import { BooksLgbtqs } from 'src/app/classes/BooksLgbtqs';
import { BooksDisabilities } from 'src/app/classes/BooksDisabilities';
import { BooksSecondaries } from 'src/app/classes/BooksSecondaries';
import { BooksGenderMarkers } from 'src/app/classes/BooksGenderMarkers';
import { GenderMarkerLookup } from 'src/app/classes/GenderMarkerLookup';

@Component({
  selector: 'app-diversity-subject',
  templateUrl: './diversity-subject.component.html',
  styleUrls: ['./diversity-subject.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class DiversitySubjectComponent implements OnInit {

  // @Input() diversitySubject: FormGroup;
  @Input() model: Book;

  //diversity subject - Heritage setting
  AllDiversityHeritages: Array<Heritage> = new Array<Heritage>();
  diversityHeritageOptions: Array<any> = new Array<any>();
  selectedDiversityHeritages: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksHeritageToSave: Array<BooksHeritages> = new Array<BooksHeritages>();

  //diversity subject - Religion
  AllDiversityReligions: Array<Religion> = new Array<Religion>();
  diversityReligionOptions: Array<any> = new Array<any>();
  selectedDiversityReligions: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksReligionToSave: Array<BooksReligions> = new Array<BooksReligions>();

  //diversity subject - LGBTQ
  AllDiversityLGBTQ: Array<Lgbtq> = new Array<Lgbtq>();
  diversityLGBTQOptions: Array<any> = new Array<any>();
  selectedDiversityLGBTQ: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksLGBTQToSave: Array<BooksLgbtqs> = new Array<BooksLgbtqs>();

  //diversity subject - Disabilities
  AllDiversityDisability: Array<Disability> = new Array<Disability>();
  diversityDisabilityOptions: Array<any> = new Array<any>();
  selectedDiversityDisability: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksDisabilityToSave: Array<BooksDisabilities> = new Array<BooksDisabilities>();

  //secondary diversity
  AllSecondaryDiversity: Array<Secondary> = new Array<Secondary>();
  diversitySecondaryOptions: Array<any> = new Array<any>();
  selectedSecondaryDiversity: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksSecondaryToSave: Array<BooksSecondaries> = new Array<BooksSecondaries>();

  //gender marker
  AllDiversityGenderMarker: Array<GenderMarkerLookup> = new Array<GenderMarkerLookup>();
  genderMarkerOptions: Array<any> = new Array<any>();
  selectedGenderMarker: Array<IMultiSelectOptions> = new Array<IMultiSelectOptions>();
  booksGenderMarkerToSave: Array<BooksGenderMarkers> = new Array<BooksGenderMarkers>();

  constructor(
    private bookService: ListBooksService) { }
  /**
     * Transform exististing publishers into data consumable by the multi-select
     * @param {Array<BooksHeritages>} heritages Publishers already associated with this book
     */
  transformBookHeritages(heritages: Array<BooksHeritages>) {
    heritages.forEach(item => {
      let data = {
        option: item.heritageId,
        displayText: item.heritage.name
      };
      this.selectedDiversityHeritages.push(data);
    });
  }

  transformBookReligions(religions: Array<BooksReligions>) {
    religions.forEach(item => {
      let data = {
        option: item.religionId,
        displayText: item.religion.name
      };
      this.selectedDiversityReligions.push(data);
    });
  }
  transformBookLgbtqs(lgbtqs: Array<BooksLgbtqs>) {
    lgbtqs.forEach(item => {
      let data = {
        option: item.lgbtqId,
        displayText: item.lgbtq.name
      };
      this.selectedDiversityLGBTQ.push(data);
    });
  }
  transformBookDisabilities(disabilities: Array<BooksDisabilities>) {
    disabilities.forEach(item => {
      let data = {
        option: item.disabilityId,
        displayText: item.disability.name
      };
      this.selectedDiversityDisability.push(data);
    });
  }

  transformBookSecondary(secondaries: Array<BooksSecondaries>) {
    secondaries.forEach(item => {
      let data = {
        option: item.secondaryId,
        displayText: item.secondary.name
      };
      this.selectedSecondaryDiversity.push(data);
    });
  }
  transformBookGenderMarker(genderMarkers: Array<BooksGenderMarkers>) {
    genderMarkers.forEach(item => {
      let data = {
        option: item.genderMarkerLookupId,
        displayText: item.genderMarkerLookup.name
      };
      this.selectedGenderMarker.push(data);
    });
  }

  getAllDiversityHeritages(): void {
    this.bookService.getAllHeritages().subscribe(heritages => {
      if (heritages != undefined && heritages.length > 0) {
        this.AllDiversityHeritages = heritages;
        this.assembleHeritageOptions(heritages);
      }
    })
  }
  getAllDiversityReligions(): void {
    this.bookService.getAllReligions().subscribe(religions => {
      if (religions != undefined && religions.length > 0) {
        this.AllDiversityHeritages = religions;
        this.assembleReligionOptions(religions);
      }
    })
  }
  getAllDiversityLgbtqs(): void {
    this.bookService.getAllLgbtqs().subscribe(lgbtqs => {
      if (lgbtqs != undefined && lgbtqs.length > 0) {
        this.AllDiversityLGBTQ = lgbtqs;
        this.assembleLgbtqOptions(lgbtqs);
      }
    })
  }
  getAllDiversityDisabilities(): void {
    this.bookService.getAllDisabilities().subscribe(disabilities => {
      if (disabilities != undefined && disabilities.length > 0) {
        this.AllDiversityDisability = disabilities;
        this.assembleDisabilityOptions(disabilities);
      }
    })
  }
  getAllSecondaries(): void {
    this.bookService.getAllSecondaries().subscribe(secondaries => {
      if (secondaries != undefined && secondaries.length > 0) {
        this.AllSecondaryDiversity = secondaries;
        this.assembleSecondaryOptions(secondaries);
      }
    })
  }

  getAllGenderMarkers(): void {
    this.bookService.getAllGenderMarkers().subscribe(genderMarkers => {
      if (genderMarkers != undefined && genderMarkers.length > 0) {
        this.AllDiversityGenderMarker = genderMarkers;
        this.assembleGenderMarkerOptions(genderMarkers);
      }
    })
  }

  assembleHeritageOptions(heritages: Array<Heritage>): void {
    for (let heritage of heritages) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: heritage.id,
        displayText: heritage.name
      }
      this.diversityHeritageOptions.push(data);
    }
    this.diversityHeritageOptions = this.diversityHeritageOptions.slice();
  }
  assembleReligionOptions(religions: Array<Heritage>): void {
    for (let religion of religions) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: religion.id,
        displayText: religion.name
      }
      this.diversityReligionOptions.push(data);
    }
    this.diversityReligionOptions = this.diversityReligionOptions.slice();
  }
  assembleLgbtqOptions(lgbtqs: Array<Lgbtq>): void {
    for (let lgbtq of lgbtqs) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: lgbtq.id,
        displayText: lgbtq.name
      }
      this.diversityLGBTQOptions.push(data);
    }
    this.diversityLGBTQOptions = this.diversityLGBTQOptions.slice();
  }

  assembleDisabilityOptions(disabilities: Array<Disability>): void {
    for (let disability of disabilities) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: disability.id,
        displayText: disability.name
      }
      this.diversityDisabilityOptions.push(data);
    }
    this.diversityDisabilityOptions = this.diversityDisabilityOptions.slice();
  }
  assembleSecondaryOptions(secondaries: Array<Secondary>): void {
    for (let secondary of secondaries) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: secondary.id,
        displayText: secondary.name
      }
      this.diversitySecondaryOptions.push(data);
    }
    this.diversitySecondaryOptions = this.diversitySecondaryOptions.slice();
  }
  assembleGenderMarkerOptions(gendermarkers: Array<GenderMarkerLookup>): void {
    for (let gendermarker of gendermarkers) {
      // Convert data to match IMultiSelectOptions
      let data = {
        option: gendermarker.id,
        displayText: gendermarker.name
      }
      this.genderMarkerOptions.push(data);
    }
    this.genderMarkerOptions = this.genderMarkerOptions.slice();
  }

  setSelectedHeritages(heritages: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
      this.selectedDiversityHeritages = heritages;
      this.selectedDiversityHeritages = this.selectedDiversityHeritages.slice();
      this.updateBooksHeritageToSave();
  }

  setSelectedReligions(religions: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
      this.selectedDiversityReligions = religions;
      this.selectedDiversityReligions = this.selectedDiversityReligions.slice();
      this.updateBooksReligionToSave();
  }

  setSelectedLgbtqs(lgbtqs: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
    
      this.selectedDiversityLGBTQ = lgbtqs;
      this.selectedDiversityLGBTQ = this.selectedDiversityLGBTQ.slice();
      this.updateBooksLGBTQToSave();

  }

  setSelectedDisabilities(disabilities: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
         this.selectedDiversityDisability = disabilities;
      this.selectedDiversityDisability = this.selectedDiversityDisability.slice();
      this.updateBooksDisabilityToSave();
  }
  setSelectedSecondaries(secondaries: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
    
      this.selectedSecondaryDiversity = secondaries;
      this.selectedSecondaryDiversity = this.selectedSecondaryDiversity.slice();
      this.updateBooksSecondaryToSave();
  }
  setSelectedGenderMarkers(gendermarkers: Array<IMultiSelectOptions>): void {
    // If the user removed all publishers, mark them all for deletion
     this.selectedGenderMarker = gendermarkers;
      this.selectedGenderMarker = this.selectedGenderMarker.slice();
      this.updateBooksGenderMarkerToSave();

  }

  updateBooksHeritageToSave(): void {
    // Transform multi-select data into book publishers
    let updatedHeritages = new Array<BooksHeritages>();
    this.selectedDiversityHeritages.forEach(item => {
      const bookheritage: BooksHeritages = {
        bookId: this.model.id,
        heritageId: item.option,
        heritage: null // this.AllDiversityHeritages.find(entry => entry.id === item.option)
      }
      updatedHeritages.push(bookheritage);
    });
    this.model.booksHeritages = updatedHeritages;
  }

  updateBooksReligionToSave(): void {
    // Transform multi-select data into book publishers
    let updatedReligions = new Array<BooksReligions>();
    this.selectedDiversityReligions.forEach(item => {
      const bookReligion: BooksReligions = {
        bookId: this.model.id,
        religionId: item.option,
        religion: null 
      }
      updatedReligions.push(bookReligion);
    });
    this.model.booksReligions = updatedReligions;

  }

  updateBooksLGBTQToSave(): void {
    // Transform multi-select data into book publishers
    let updatedLgbtqs = new Array<BooksLgbtqs>();
    this.selectedDiversityLGBTQ.forEach(item => {
      const bookLgbtq: BooksLgbtqs = {
        bookId: this.model.id,
        lgbtqId: item.option,
        lgbtq: null //this.AllDiversityLGBTQ.find(entry => entry.id === item.option)
      }
      updatedLgbtqs.push(bookLgbtq);
    });
    this.model.booksLgbtqs = updatedLgbtqs;

  }
  updateBooksDisabilityToSave(): void {
    // Transform multi-select data into book publishers
    let updatedDisabilities = new Array<BooksDisabilities>();
    this.selectedDiversityDisability.forEach(item => {
      const bookDisability: BooksDisabilities = {
        bookId: this.model.id,
        disabilityId: item.option,
        //book: this.model,
        disability: null //this.AllDiversityDisability.find(entry => entry.id === item.option)
      }
      updatedDisabilities.push(bookDisability);
    });
    this.model.booksDisabilities = updatedDisabilities;
  }
  updateBooksSecondaryToSave(): void {
    // Transform multi-select data into book publishers
    let updatedSecondaries = new Array<BooksSecondaries>();
    this.selectedSecondaryDiversity.forEach(item => {
      const booksecondary: BooksSecondaries = {
        bookId: this.model.id,
        secondaryId: item.option,
        //book: this.model,
        secondary: null //this.AllSecondaryDiversity.find(entry => entry.id === item.option)
      }
      updatedSecondaries.push(booksecondary);
    });
    this.model.booksSecondaries = updatedSecondaries;
  }
  updateBooksGenderMarkerToSave(): void {
    // Transform multi-select data into book publishers
    let updatedGenderMarkers = new Array<BooksGenderMarkers>();
    this.selectedGenderMarker.forEach(item => {
      const bookGendermarker: BooksGenderMarkers = {
        bookId: this.model.id,
        genderMarkerLookupId: item.option,
        //book: this.model,
        genderMarkerLookup: null //this.AllDiversityGenderMarker.find(entry => entry.id === item.option)
      }
      updatedGenderMarkers.push(bookGendermarker);
    });
    this.model.booksGenderMarkers = updatedGenderMarkers;
  }
  ngOnInit() {
    this.getAllDiversityHeritages();
    this.transformBookHeritages(this.model.booksHeritages);
    this.getAllDiversityReligions();
    this.transformBookReligions(this.model.booksReligions);
    this.getAllDiversityLgbtqs();
    this.transformBookLgbtqs(this.model.booksLgbtqs);
    this.getAllDiversityDisabilities();
    this.transformBookDisabilities(this.model.booksDisabilities);
    this.getAllSecondaries();
    this.transformBookSecondary(this.model.booksSecondaries);
    this.getAllGenderMarkers();
    this.transformBookGenderMarker(this.model.booksGenderMarkers);
  }

}
