<h1 mat-dialog-title>Manage CCBC Subjects</h1>
<div class="wrapperAddNewSubject"><button mat-flat-button color="accent" (click)="addNewSubjectClick()"><mat-icon>add</mat-icon> Add New Subject</button></div>
<div style="clear: both;"></div>
<div>Curate the subjects that can be associated with this, and other, books.</div>
<div mat-dialog-content>
    <table class="table table-bordered table-striped tblCCBCSubjects">
        <colgroup>
            <col>
            <col style="width: 295px;">
            <col style="width: 40px;">
        </colgroup>
        <thead class="thead-light">
            <tr>
                <th scope="col">Subject Name</th>
                <th scope="col" class="cell-centered">Edit</th>
                <th scope="col" class="cell-centered">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="allSubjects.length === 0">
                <td colspan="3">
                    <p>There are no CCBC Subjects</p>
                </td>
            </tr>
            <!-- Hidden row to add a new CCBC Subject -->
            <tr [hidden]="!isAddingNewSubject">
                <td>
                    <label for="newSubjectName" class="sr-only">Subject Name</label>
                    <input #inputNewSubjectName matInput id="newSubjectName" name="newSubjectName" type="text" [formControl]="newSubjectName" required />
                    <div>
                        <mat-error *ngIf="newSubjectName.dirty && newSubjectName.hasError('required')">
                            Name is required
                        </mat-error>
                        <mat-error *ngIf="newSubjectName.dirty && newSubjectName.hasError('subjectExists')">
                            A CCBC Subject with this name already exists.
                        </mat-error>
                    </div>
                </td>
                <td>
                    <button mat-button (click)="cancelAddingNewSubject()">Cancel</button>
                    <button class="button__save" mat-button mat-flat-button (click)="saveNew()" 
                        [disabled]="!newSubjectName.valid || crudCallIsPending">Save new Subject</button>
                </td>
                <td>&nbsp;</td>
            </tr>
            <!-- Rows for existing CCBC Subjects -->
            
            <!--
            <tr *ngFor="let ccbcSubject of this.formGroupSubjectNames.controls.subjects.controls; let i = index;">
                -->
            <tr *ngFor="let ccbcSubject of formGroupSubjectNames.get('subjects')['controls']; let i = index;">
                <td [formGroup]="ccbcSubject">
                    <span *ngIf="!ccbcSubject.value.editing">{{ ccbcSubject.value.name }}</span>
                    <span *ngIf="ccbcSubject.value.editing"><input matInput id="editSubjectName{{ccbcSubject.value.id}}" name="editSubjectName{{ccbcSubject.value.id}}" 
                        formControlName="name" aria-label="Subject Name" required /></span>
                    <div>
                        <mat-error *ngIf="ccbcSubject.value.editing && ccbcSubject.controls.name.dirty && ccbcSubject.controls.name.hasError('required')">
                            Name is required
                        </mat-error>
                        <mat-error *ngIf="ccbcSubject.value.editing && ccbcSubject.controls.name.dirty && ccbcSubject.controls.name.hasError('subjectExists')">
                            A CCBC Subject with this name already exists.
                        </mat-error>
                    </div>
                    
                </td>
                <td scope="col" class="text-center">
                    <button *ngIf="!ccbcSubject.value.editing" mat-stroked-button color="accent"
                        (click)="makeRowEditable(ccbcSubject)">Edit</button>
                    <button *ngIf="ccbcSubject.value.editing" mat-button 
                        (click)="cancelEditRow(ccbcSubject)">Cancel</button>
                    <button *ngIf="ccbcSubject.value.editing" class="button__save" mat-button mat-flat-button (click)="saveEditRow(ccbcSubject)"
                        [disabled]="!ccbcSubject.valid || crudCallIsPending">Save changes</button>
                </td>
                <td scope="col" class="text-center">
                    <button mat-stroked-button mat-icon-button color="primary"
                        (click)="confirmDeleteRow(ccbcSubject)" [disabled]="crudCallIsPending"><mat-icon>delete_forever</mat-icon></button>
                </td>
            </tr>
            
        </tbody>
    </table>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="closeButtonClick()">Close</button>
</div>
