import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';

import { PagerService } from 'src/app/services/pager.service';
import { ListBooksService } from 'src/app/services/list-books.service';

// Classes
import { BooksList } from 'src/app/classes/BooksList';
import { Book } from 'src/app/classes/Book';
import { OrderBy } from 'src/app/classes/OrderBy';

export interface IConfirmDeleteData {
  id: number;
  title: string;
}

export enum SORT {
  ASC = 'ascending',
  DES = 'descending',
  NONE = 'none'
}

/**
 * Component that displays a confirmation dialog upon trying to delete a book.
 */
@Component({
  selector: 'dialog-confirm-delete-book',
  templateUrl: 'dialog-confirm-delete-book.component.html'
})
export class DialogConfirmDeleteBook {
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDeleteData) { }
}

/**
 * Component that retrieves and displays a list of books specified by user-set parameters.
 */
@Component({
  selector: 'list-books',
  animations: [
    trigger('hideShowAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('0s', style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './list-books.component.html',
  styleUrls: ['./list-books.component.scss']
})
export class ListBooksComponent implements OnInit {

  constructor(
    private titleService: Title,
    private listBooksService: ListBooksService,
    private pagerService: PagerService,
    private route: ActivatedRoute,
    private router: Router,
    public confirmDeleteDialog: MatDialog
  ) {

    this.route.queryParamMap.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.searchText = this.router.getCurrentNavigation().extras.state.data;
      }
    });
  }

  // Local variables
  total: number;
  loading: boolean;
  pager: any = {};
  pagedBooks: Array<BooksList> = new Array<BooksList>();;
  readonly accessibilityKeycodes: number[] = [ENTER];
  ariaSort = {
    title: SORT.ASC,
    author: SORT.NONE,
    publishers: SORT.NONE,
    year: SORT.NONE,
    isRecommended: SORT.NONE
  }

  // Book list api params
  // See CCBCBackEnd BookListRepository#22
  order: OrderBy;
  selectedPage: number = 1; // init on page 1
  take: number = 25; // number of books to take per page
  searchText: string = '';

  ngOnInit() {
    this.titleService.setTitle('Books | CCBC Book Search');
    this.loading = true;
    this.order = new OrderBy('title');
    this.order.ascending = true;
    // Initialize on page 1
    this.updatePage(1);
  }

  /**
   * Gets books via the ListBooksService, passing expected local params. Sets view bindings on response.
   */
  getAllBooks(): void {
    this.listBooksService.getBooksList(this.selectedPage, this.take, this.order, this.searchText).subscribe(list => {
      // API gives us total number of books in db
      this.total = list.total;
      this.loading = false;
      // Get current page of items
      this.pager = this.pagerService.getPager(this.total, this.selectedPage, this.take);
      this.pagedBooks = list.books.map(x => new BooksList(x));
    });
  }

  /**
   * Permanently delete the book entry with the given id
   * @param {number} id The id of the book being deleted
   */
  deleteBook(id: number): void {
    this.listBooksService.deleteBookById(id).subscribe(result => {
      console.info(`Successfully deleted book with id: ${id}`);
      this.getAllBooks();
    });
  }

  ////////////
  // EVENTS //
  ////////////
  /**
   * Set the page number binding and get all the books for that page.
   * @param {number} page Page number to get from API
   */
  updatePage(page: number): void {
    // Set page param for GET request
    this.selectedPage = page;
    this.getAllBooks();
  }

  /**
   * Sort the books list by the given column
   * @param orderColumn 
   */
  sortBy(orderColumn: string): void {
    if (orderColumn === this.order.property) {
      this.order.ascending = !this.order.ascending;
    }
    else if (orderColumn === 'year' || orderColumn == 'isRecommended') {  //defaulting to descending order seems more natural for these cases
      this.order.property = orderColumn;
      this.order.ascending = false;
    }
    else {
      this.order.property = orderColumn;
      this.order.ascending = true;
    }
    this.updatePage(1);

    // update aria-sort on column headers
    this.ariaSort = {
      title: SORT.NONE,
      author: SORT.NONE,
      publishers: SORT.NONE,
      year: SORT.NONE,
      isRecommended: SORT.NONE
    }
    this.ariaSort[orderColumn] = this.order.ascending ? SORT.ASC : SORT.DES;
    
  }

  /**
   * Listen for keypresses on sortable table headers
   * @param {KeyboardEvent} e The keyboard event 
   * @param {string} orderColumn Name of the column to sort by
   */
  onKey(event: KeyboardEvent, orderColumn: string): void {
    if (event.code == 'Space' || event.code == 'Enter') {
      event.preventDefault(); 
      this.sortBy(orderColumn);
    }
  }

  /**
   * Delete a book
   * @param {Book} book A book object to delete 
   */
  confirmDelete(book: Book): void {
    const dialogRef = this.confirmDeleteDialog.open(DialogConfirmDeleteBook, {
      data: {
        id: book.id,
        title: book.title
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteBook(book.id);
      }
    });
  }
}