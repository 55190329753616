import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Login } from '../../classes/login';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginModel : Login | null;
  loginForm: FormGroup;
  loginFailed: boolean = false;

  constructor(
    private titleService: Title,
    private authService: AuthService, 
    private router: Router, 
    private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm();
    this.titleService.setTitle('Log in | CCBC Book Search');
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [''],
      password: ['']
    });
  }

  onSubmit(): void {
    this.loginFailed = false;
    this.loginModel = this.loginForm.value;
    this.authService.login(this.loginModel).subscribe((success : boolean) => {
      if (success) {
        this.router.navigate(['']);
      } else {
        this.loginFailed = true;
      }
    });
  }

}
