<mat-card class="card__list-contributors">
    <div class="row">
        <div class="col-10">
            <span class="title">All Contributors</span>
          </div>
          <div class="col text-right">
             <button mat-flat-button color="accent"
                [routerLink]="['/contributor', 0]">
                <mat-icon>add</mat-icon>Add Contributor
            </button>
          </div>
    </div>
    <mat-card-content>
        <div class="row list-controls" [hidden]="loading">
            <div class="col-8 list-controls--page">
                <nav aria-label="page navigation">
                    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                            <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(1)">First</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                            <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.currentPage - 1)">Previous</a>
                        </li>
                        <li *ngFor="let page of pager.pages" class="page-item"
                            [ngClass]="{'active': pager.currentPage === page}">
                            <a class="page-link" href="#"
                                (click)="$event.preventDefault();updatePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                            <a class="page-link" href="#"
                                [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.currentPage + 1)">Next</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                            <a class="page-link" href="#"
                                [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.totalPages)">Last</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-4 list-controls--take">
                <span class="hint">Results per page:</span>
                <span *ngFor="let option of takes" id="take{{ option }}"
                    (click)="setTake(option, $event)">{{ option }}</span>
            </div>
        </div>
        <mat-accordion [multi]="false">
            <table class="table table-bordered table-striped">
                <col width="10%">
                <col width="60%">
                <col width="30%">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="cell-centered">Edit</th>
                        <th scope="col" class="table-controls">
                            <div class="table-controls--filterNames">
                                <span>Name:</span>
                                <div class="input-group">
                                    <input class="form-control" aria-label="filter list by name" type="text"
                                        #filterContributorNames [(ngModel)]="filterText"
                                        (ngModelChange)="filterAllContributors()" placeholder="Filter list by name">
                                </div>
                            </div>
                            <div class="table-controls--orderBy">
                                <span>Sort by:</span>
                                <button mat-stroked-button color="default"
                                    [ngClass]="{'active' : selectedOrderBy.property === 'lastName'}"
                                    (click)="setOrderBy('lastName')">Last name
                                    <mat-icon
                                        *ngIf="selectedOrderBy.property === 'lastName' && selectedOrderBy.ascending">
                                        arrow_drop_up</mat-icon>
                                    <mat-icon
                                        *ngIf="selectedOrderBy.property=== 'lastName' && !selectedOrderBy.ascending">
                                        arrow_drop_down</mat-icon>
                                    <mat-icon *ngIf="selectedOrderBy.property!== 'lastName'" class="disabled">
                                        arrow_drop_up
                                    </mat-icon>
                                </button>
                                <button mat-stroked-button color="default"
                                    [ngClass]="{'active' : selectedOrderBy.property === 'firstName'}"
                                    (click)="setOrderBy('firstName')">First name
                                    <mat-icon
                                        *ngIf="selectedOrderBy.property === 'firstName' && selectedOrderBy.ascending">
                                        arrow_drop_up</mat-icon>
                                    <mat-icon
                                        *ngIf="selectedOrderBy.property=== 'firstName' && !selectedOrderBy.ascending">
                                        arrow_drop_down</mat-icon>
                                    <mat-icon *ngIf="selectedOrderBy.property!== 'firstName'" class="disabled">
                                        arrow_drop_up
                                    </mat-icon>
                                </button>
                            </div>
                        </th>
                        <th scope="col">Books</th>
                    </tr>
                </thead>
                <tbody class="table-striped">
                    <tr [hidden]="!loading">
                        <td colspan="7">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                            </div>
                        </td>
                    </tr>

                    <tr #allContributors *ngFor="let contributor of contributors" [hidden]="loading">
                        <td class="cell-centered"><button mat-stroked-button color="accent"
                                routerLink="/contributor/{{ contributor.id }}">Edit</button></td>
                        <td>
                            <span *ngIf="selectedOrderBy.property === 'lastName'">{{ contributor.lastName }},
                                {{ contributor.firstName }} {{ contributor.middleName.substring(0, 1) }}.</span>
                            <span *ngIf="selectedOrderBy.property === 'firstName'">{{ contributor.firstName }}
                                {{ contributor.middleName.substring(0, 1) }}. {{ contributor.lastName }}</span>
                        </td>
                        <td>
                            <mat-expansion-panel (opened)="getContributorBooks(contributor.id)"
                                (closed)="closePanel(contributor.id)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title id="panelTitle{{ contributor.id }}">
                                        <span class="panel-title--opened">
                                            Collapse
                                        </span>
                                        <span class="panel-title--closed">
                                            Expand
                                        </span>
                                        &nbsp;books
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-template matExpansionPanelContent>
                                    <p *ngIf="books.length === 0">This contributor is not associated with any books.</p>
                                    <ul *ngIf="books.length > 0">
                                        <li *ngFor="let book of books">
                                            <a routerLink="/book/{{ book.id }}">{{ book.title }}</a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </mat-expansion-panel>
                        </td>
                    </tr>
                    <!-- Empty state -->
                    <tr id="noResults" [hidden]="loading" *ngIf="isNoResults">
                        <td colspan="3">No contributors with "{{ filterText }}" in their name</td>
                    </tr>
                </tbody>
            </table>
        </mat-accordion>
        <div class="row list-controls" [hidden]="loading">
            <div class="col-8 list-controls--page">
                <nav aria-label="page navigation">
                    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                            <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(1)">First</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === 1 }">
                            <a class="page-link" href="#" [attr.tabindex]="pager.currentPage === 1 ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.currentPage - 1)">Previous</a>
                        </li>
                        <li *ngFor="let page of pager.pages" class="page-item"
                            [ngClass]="{'active': pager.currentPage === page}">
                            <a class="page-link" href="#"
                                (click)="$event.preventDefault();updatePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                            <a class="page-link" href="#"
                                [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.currentPage + 1)">Next</a>
                        </li>
                        <li class="page-item" [ngClass]="{ 'disabled' : pager.currentPage === pager.totalPages }">
                            <a class="page-link" href="#"
                                [attr.tabindex]="pager.currentPage === pager.totalPages ? '-1' : null"
                                (click)="$event.preventDefault();updatePage(pager.totalPages)">Last</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-4 list-controls--take">
                <span class="hint">Results per page:</span>
                <span *ngFor="let option of takes" id="take{{ option }}Bottom"
                    (click)="setTake(option, $event)">{{ option }}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>