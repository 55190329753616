import { Component, OnInit, Inject} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { BibliographyService } from 'src/app/services/bibliography.service';

// Classes
import { BibliographyListView } from 'src/app/classes/BibliographyListView';

export interface IConfirmDeleteData {
  id: number;
  name: string;
}

/**
 * Component that displays a confirmation dialog upon trying to delete a bibliography.
 */
@Component({
  selector: 'dialog-confirm-delete-bibliography',
  templateUrl: 'dialog-confirm-delete-bibliography.component.html'
})
export class DialogConfirmDeleteBibliography {
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDeleteData) { }
}

@Component({
  selector: 'app-list-bibliography',
  templateUrl: './list-bibliography.component.html',
  styleUrls: ['./list-bibliography.component.scss']
})
export class ListBibliographyComponent implements OnInit {

  public allBibliography = new Array<BibliographyListView>();

  constructor(
    private bibliographyService: BibliographyService,
    private titleService: Title,
    public confirmDeleteDialog: MatDialog) { }

  ngOnInit() {
    this.titleService.setTitle('Booklists | CCBC Book Search');
    this.bibliographyService.getAllBibliography().subscribe(bibliographies => {
      this.allBibliography = bibliographies;
      });
  }

  /**
   * Delete a book
   * @param {Bibliography} bibliography A bibliography object to delete 
   */
  confirmDelete(bibliography: BibliographyListView): void {
    const dialogRef = this.confirmDeleteDialog.open(DialogConfirmDeleteBibliography, {
      data: {
        id: bibliography.id,
        name: bibliography.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteBibliography(bibliography.id);
      }
    });
  }

    /**
   * Permanently delete the bibliography entry with the given id
   * @param {number} id The id of the bibliography being deleted
   */
  deleteBibliography(id: number): void {
    this.bibliographyService.deleteBibliographyById(id).subscribe(result => {
      console.info(`Successfully deleted bibliography with id: ${id}`);
      this.bibliographyService.getAllBibliography().subscribe(bibliographies => {
        this.allBibliography = bibliographies;
        });
    });
  }

}
