export class BookBibliography {
    bookId: number;
    bibliographyId: number;
    bibliographyName: string;
    categoryId: number;
    categoryName: string;
    subcategoryName: string;
    parentId: number;
    
}
