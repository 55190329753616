import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Publisher } from 'src/app/classes/Publisher';

@Component({
  selector: 'app-dialog-publishers',
  templateUrl: './dialog-publishers.component.html',
  styleUrls: ['./dialog-publishers.component.scss']
})
export class DialogPublishersComponent implements OnInit {
  allPublishers = new Array<string>();
  newPublisher = new Publisher();
  inputPublisherName = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<DialogPublishersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.newPublisher.name = '';
    // Store known publishers to guard against adding duplicates
    if (this.data.allPublishers) {
      this.data.allPublishers.forEach(publisher => {
        this.allPublishers.push(publisher.name.toLowerCase());
      });
    }

  }

  /**
   * On input, check input against existing publishers and show hint if 
   * it already exists in the list.
   */
  checkExistingPublishers(event): void {
    if (this.allPublishers.indexOf(this.inputPublisherName.value.toLowerCase()) != -1) {
      this.inputPublisherName.setErrors({
        'publisherExists': true
      });
    }
  }

  /**
   * Trigger the dialog's close event in response to user action.
   */
  closeDialog(): void {
      // Only return the new publisher if the required field has a value
      if (this.inputPublisherName.hasError('required') || this.inputPublisherName.hasError('publisherExists')) {
        this.dialogRef.close(-1);
      } else {
        this.newPublisher.name = this.inputPublisherName.value;
        this.dialogRef.close(this.newPublisher);
      } 
  }

  cancelAdd(): void {
    this.dialogRef.close(-1);
  }
}
