import { CharactersCharacterHeritages } from "./CharactersCharacterHeritages";
import { CharactersCharacterTypes} from "./CharactersCharacterTypes";
import { CharactersCharacterGenders} from "./CharactersCharacterGenders";
import { CharactersCharacterGenderSpecifies } from "./CharactersCharacterGenderSpecifies";
import { CharactersCharacterHeritageSpecifies} from "./CharactersCharacterHeritageSpecifies";
import { CharactersCharacterReligions} from "./CharactersCharacterReligions";
/*new class for characterDisabilities */
import { CharactersDisabilities } from "./CharactersDisabilities";
import { CharactersCharacterSexualities} from "./CharactersCharacterSexualities";

import { Religion } from "./Religion";

export class Character {

    constructor() {
        this.id = 0;
        this.charactersCharacterTypes = new Array<CharactersCharacterTypes>();
        this.charactersCharacterGenders = new Array<CharactersCharacterGenders>();
        this.charactersCharacterGenderSpecifies = new Array<CharactersCharacterGenderSpecifies>();
        this.charactersCharacterHeritages = new Array<CharactersCharacterHeritages>();
        this.charactersCharacterHeritageSpecifies = new Array<CharactersCharacterHeritageSpecifies>();
        this.charactersReligions = new Array<CharactersReligions>();
        this.charactersDisabilities = new Array<CharactersDisabilities>();
        this.charactersCharacterSexualities = new Array<CharactersCharacterSexualities>();
    }

    id: number;
    bookId: number;
    sortOrder: number;
    isSecondaryCharacter: boolean;
    typeNotes: string;
    genderNotes: string;
    heritageNotes: string;
    heritageByNotes: string;
    religionNotes: string;
    disabilityNotes: string;
    sexualityNotes: string;

    /*========== Character Refactoring SECTION ==============*/
    charactersCharacterHeritages:  Array<CharactersCharacterHeritages>;
    charactersCharacterTypes: Array<CharactersCharacterTypes>;
    charactersCharacterGenders:  Array<CharactersCharacterGenders>;
    charactersCharacterGenderSpecifies: Array<CharactersCharacterGenderSpecifies>;
    charactersCharacterHeritageSpecifies:  Array<CharactersCharacterHeritageSpecifies>;
    charactersReligions: Array<CharactersReligions>;
    charactersDisabilities:  Array<CharactersDisabilities>;
    charactersCharacterSexualities: Array<CharactersCharacterSexualities>;
  
    /*========== END Character Refactoring ================= */

}

export class CharactersReligions {
    characterId: number;
    religionId: number;

    religion: Religion;
}