import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Classes
import { BookForBibliographyAdd } from 'src/app/classes/Bibliography';

//services
import { BibliographyService } from 'src/app/services/bibliography.service';

@Component({
  selector: 'app-dialog-bibliography-book',
  templateUrl: './dialog-bibliography-book.component.html',
  styleUrls: ['./dialog-bibliography-book.component.scss']
})
export class DialogBibliographyBookComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogBibliographyBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bibliographyService: BibliographyService,) { }

  bookList: Array<BookForBibliographyAdd>; 
  searchText: string;
  searchInitiated: boolean = false;

  ngOnInit() {
    this.bookList = new Array<BookForBibliographyAdd>();
  }

  getBooks(): void {
    this.searchInitiated = true;
    this.bibliographyService.searchForBooks(this.searchText).subscribe(bl => {
      this.bookList = bl;
    });
  }

  /**
   * Trigger the dialog's close event in response to user action.
   */
  closeDialog(): void {
    let addBookList = new Array<BookForBibliographyAdd>();
    for (let book of this.bookList) {

      //don't allow duplicate books to be added to the category
      let bookExists = this.data.category.booksForBibliographyView.some( elem => elem.bookId == book.bookId );

      if (book.add == true && bookExists == false ) {
        addBookList.push(book);
      }
    }
    this.dialogRef.close(addBookList);

  }

  cancelAdd(): void {
    this.dialogRef.close(-1);
  }


}
