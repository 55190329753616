import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//api service
import { BibliographyService } from 'src/app/services/bibliography.service';

//classes
import { BookBibliography } from 'src/app/classes/BookBibliography';
import { BibliographyList } from 'src/app/classes/BibliographyList';
import { BibliographyCategoryList } from 'src/app/classes/BibliographyCategoryList';
import { MatSelectChange, MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-dialog-assign-bibliography',
  templateUrl: './dialog-assign-bibliography.component.html',
  styleUrls: ['./dialog-assign-bibliography.component.scss']
})
export class DialogAssignBibliographyComponent implements OnInit {
  
  bookNumber: number;
  bookBibliographiesDia: Array<BookBibliography>;
  bibliographyCategories: Array<BibliographyList>;
  categoryList: Array<BibliographyCategoryList>;
  subCategoryList: Array<BibliographyCategoryList>;

  /** */
  selectedBibliography: number;
  selectedCategory: number;
  selectedSubcategory: number;

  blexists: boolean;

  constructor(public dialogRef: MatDialogRef<DialogAssignBibliographyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bibliographyService: BibliographyService) { }

  ngOnInit() {
    this.bookNumber = this.data.bookId;
    this.bookBibliographiesDia = this.data.bookBibliographies;
    this.categoryList=new Array<BibliographyCategoryList>();
    this.subCategoryList=new Array<BibliographyCategoryList>();
    this.bibliographyCategories =new Array<BibliographyList>();
    this.getAllBibliographyCategory();
    this.selectedBibliography=0;
    this.selectedCategory = 0;
    this.selectedSubcategory = 0;
    this.blexists = false;
  }

  onBibliographyChange(selectVal: number) :void {
    if (selectVal!==this.selectedBibliography || this.selectedBibliography==0){
      this.categoryList=[];
      this.subCategoryList=[];
      this.selectedBibliography = selectVal;
      this.selectedCategory=0;
      this.selectedSubcategory = 0;
      this.blexists =false;
     
    }
    this.categoryList =(this.bibliographyCategories
      .filter(x=>x.id==selectVal)).shift().bibliographyCategoryList
      .filter(y=>y.isSubcategory==false);
  }

  onCategoryChange(selectVal: number) :void {
    if (selectVal!==this.selectedCategory || this.selectedCategory===0 ){
      this.subCategoryList =[];
      this.selectedCategory = selectVal;
      this.selectedSubcategory = 0;
    }
    this.selectedCategory = selectVal;
    this.subCategoryList =(this.bibliographyCategories
      .filter(x=>x.id==this.selectedBibliography)).shift().bibliographyCategoryList
      .filter(y=>y.isSubcategory==true && y.parentId == selectVal);
    
     this.blexists = this.bookBibliographiesDia.filter(x=>x.categoryId ==selectVal).length>0; 
  }
  onSubcategoryChange(event: MatSelectChange) :void {
    
    if (event.value !=-1) {
      this.selectedSubcategory = event.value;
      this.blexists = this.bookBibliographiesDia.filter(x=>x.categoryId ==event.value).length>0; 
    }
    else{
          this.selectedSubcategory = 0;
          const matSelect: MatSelect = event.source;
          matSelect.writeValue(null);
          this.blexists = this.bookBibliographiesDia.filter(x=>x.categoryId ==this.selectedCategory).length>0; 

    }
    
  }

  assignBibliography() :void{
    let temp =new BookBibliography();
    temp.bookId = this.bookNumber;
    temp.bibliographyId =this.selectedBibliography;
    temp.bibliographyName=this.bibliographyCategories.filter(x=>x.id==this.selectedBibliography).shift().bibliographyName;
    if (this.selectedSubcategory==0){
      temp.categoryId =this.selectedCategory;
      temp.parentId = null;
      temp.categoryName = this.categoryList.filter(x=>x.id==this.selectedCategory).shift().categoryName;
    }
    else{
      temp.categoryName = this.categoryList.filter(x=>x.id==this.selectedCategory).shift().categoryName;
      temp.categoryId =this.selectedSubcategory;
      temp.parentId = this.selectedCategory;
      temp.subcategoryName = this.subCategoryList.filter(x=>x.id==this.selectedSubcategory).shift().categoryName;
    } 
    
    this.bookBibliographiesDia.push(temp);
  
    this.dialogRef.close(-1);
  }
  /**
   * 
   * loading all avaiable bibliographies and categories
   */
  getAllBibliographyCategory(): void{
    this.bibliographyService.getAllBibliographyCategory().subscribe(bibliography=>{
      this.bibliographyCategories = bibliography;
   });

  }
  cancelAdd(): void {
        this.dialogRef.close(-1);
  }

  //close modal and navigate to new bibliography
  closeAddBibliographyModal(): void{
    this.dialogRef.close(-1);
  }

  

}
