<div #multiSelectComponent class="select__multi-select" [ngClass]="{'active': isActive}">
    <input type="hidden" name="{{label}}">    
    <a class="item__selected" *ngFor="let item of selectionsToReturn" tabindex="0" (keydown)="onSelectedDelete($event, item)" aria-label="remove selection">
        {{ item.displayText }}
        <mat-icon (click)="removeSelection(item)">close</mat-icon>
    </a>
    <!-- Search filter input -->
    <label for="{{id}}" class="sr-only">{{ariaLabel}}</label>
    <input [hidden]="!hasFilter" name="searchMultiSelect" id="{{id}}" #searchMultiSelect class="search" tabindex="0" placeholder="{{ label }}" (focus)="setActive()"
        [(ngModel)]="searchText" (ngModelChange)="setActive()" (keyup)="onInputKeyup($event)">
    <span [hidden]="hasFilter" id="{{id}}" #noFilter class="search" tabindex="0" (focus)="setActive()"
        (keyup)="onInputKeyup($event)"></span>    
    <!-- Up/down arrow to open/close options list -->
    <span class="drop-arrow" #dropdownArrow [hidden]="isActive" (click)="toggleActive()" tabindex="0" (keydown)="onArrowButton($event)" aria-label="expand options"><mat-icon class="icon">arrow_drop_down</mat-icon></span>
    <span class="drop-arrow" #dropupArrow [hidden]="!isActive" (click)="toggleActive()" tabindex="0" (keydown)="onArrowButton($event)" aria-label="collapse options"><mat-icon class="icon">arrow_drop_up</mat-icon></span>
    <!-- Options list -->
    <div #optionsList class="options" [ngClass]="{'hidden': !isActive}">
        <div class="item" *ngFor="let option of data | searchFilter: searchText; index as i;" [attr.data]="option.option"
            (click)="addSelection(option)" (keydown)="addOnKeydown($event, option, i)" tabindex="0">
            {{ option.displayText }}
        </div>
        <div class="item" *ngIf="(data | searchFilter: searchText).length === 0">No results for "{{ searchText }}"</div>
    </div>
</div>