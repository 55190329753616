<mat-expansion-panel (opened)="openCharacterPanel(prefix)"
(closed)="closeCharacterPanel(prefix)">
  <mat-expansion-panel-header>
    <mat-panel-title *ngIf="!model.isSecondaryCharacter" id="{{prefix}}panelTitle"><span class="panel-title--opened sr-only">Collapse</span><span class="panel-title--closed sr-only">Expand</span>&nbsp;Primary Character/Subject {{index + 1}}</mat-panel-title>
    <mat-panel-title *ngIf="model.isSecondaryCharacter" id="{{prefix}}panelTitle"><span class="panel-title--opened sr-only">Collapse</span><span class="panel-title--closed sr-only">Expand</span>&nbsp;Secondary Character/Subject {{index + 1}}</mat-panel-title>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <mat-card class="card__character--type">
      <!-- Begin Character Type -->
      <mat-card-title>
        <span class="title">Type</span>
      </mat-card-title>
      <mat-card-content>
        <app-multi-select #characterTypeMultiSelect{{prefix}} [id]="prefix + ' character type'" [label]="" [multiSelectOptions]="characterTypeOptions"
          [selectionsToReturn]="selectedCharacterTypes" (onSelectionsChanged)="setSelectedCharacterType($event)"
          [ariaLabel]="'character type'">
        </app-multi-select>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterTypeNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.typeNotes" name="{{prefix}}characterTypeNotes" placeholder="Notes"></textarea>
        </div>
        <!-- End character type -->
      </mat-card-content>
    </mat-card>

    <mat-card class="card__character--gender">
      <!-- Begin Character gender -->
      <mat-card-title>
        <span class="title">Gender</span>
      </mat-card-title>
      <mat-card-content>
        <app-multi-select #characterGenderMultiSelect{{prefix}} [id]="prefix + ' gender'" [label]="" [multiSelectOptions]="characterGenderOptions"
          [selectionsToReturn]="selectedCharacterGenders" (onSelectionsChanged)="setSelectedCharacterGenders($event)"
          [ariaLabel]="'gender'">
        </app-multi-select>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterGenderNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.genderNotes" name="{{prefix}}characterGenderNotes" placeholder="Notes"></textarea>
        </div>
        <div class="row-spacing"></div>
        <div class="row multi-select-group">
          <div class="col multi-select-group__prepend">
            <span class="input-group-text">Specified by</span>
          </div>
          <div class="col multi-select-group__select">
            <app-multi-select #characterGenderSpecifyMultiSelect{{prefix}} [id]="prefix + ' gender specified by'" [label]=""
              [multiSelectOptions]="characterGenderSpecifyOptions" [selectionsToReturn]="selectedCharacterGenderSpecifies"
              (onSelectionsChanged)="setSelectedCharacterGenderSpecifies($event)" [ariaLabel]="'gender specified by'">
            </app-multi-select>
          </div>
        </div>
        <!-- End character gender -->
      </mat-card-content>
    </mat-card>

    <mat-card class="card__character--heritage">
      <!-- Begin Character heritage -->
      <mat-card-title>
        <span class="title">Heritage</span>
      </mat-card-title>
      <mat-card-content>
        <app-multi-select #characterHeritageMultiSelect{{prefix}} [id]="prefix + ' heritage'" [label]="" [multiSelectOptions]="characterHeritageOptions"
          [selectionsToReturn]="selectedCharacterHeritages" (onSelectionsChanged)="setSelectedCharacterHeritage($event)"
          [ariaLabel]="'heritage'">
        </app-multi-select>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterHeritageNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.heritageNotes" name="{{prefix}}characterHeritageNotes" placeholder="Notes"></textarea>
        </div>
        <div class="row-spacing"></div>
        <div class="row multi-select-group">
          <div class="col multi-select-group__prepend">
            <span class="input-group-text">Specified by</span>
          </div>
          <div class="col multi-select-group__select">
            <app-multi-select #characterHeritageMultiSelect{{prefix}} [label]=""
              [id]="prefix + ' heritage specified by'"
              [multiSelectOptions]="characterHeritageSpecifyOptions"
              [selectionsToReturn]="selectedCharacterHeritageSpecifies"
              (onSelectionsChanged)="setSelectedCharacterHeritageSpecify($event)"
              [ariaLabel]="'heritage specified by'">
            </app-multi-select>
          </div>
        </div>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterHeritageSpecifyNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.heritageByNotes" name="{{prefix}}characterHeritageSpecifyNotes"
            placeholder="Notes"></textarea>
        </div>
        <!-- End character heritage -->
      </mat-card-content>
    </mat-card>

    <mat-card class="card__character--religion">
      <!-- Begin Character Religion -->
      <mat-card-title>
        <span class="title">Religion</span>
      </mat-card-title>
      <mat-card-content>
        <app-multi-select #characterTypeMultiSelect{{prefix}} [id]="prefix + ' religion'" [label]="" [multiSelectOptions]="religionOptions"
          [selectionsToReturn]="selectedReligions" (onSelectionsChanged)="setSelectedReligion($event)" [ariaLabel]="'religion'">
        </app-multi-select>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterReligionNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.religionNotes" name="{{prefix}}characterReligionNotes" placeholder="Notes"></textarea>
        </div>
        <!-- End character religion -->
      </mat-card-content>
    </mat-card>

    <mat-card class="card__character--disability">
      <!-- Begin Character disability -->
      <mat-card-title>
        <span class="title">Disability/Condition</span>
      </mat-card-title>
      <mat-card-content>
        <app-multi-select #characterTypeMultiSelect{{prefix}} [id]="prefix + ' disability'" [label]="" [multiSelectOptions]="characterDisabilityOptions"
          [selectionsToReturn]="selectedCharacterDisabilities"
          (onSelectionsChanged)="setSelectedCharacterDisability($event)" [ariaLabel]="'disability'">
        </app-multi-select>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterDisabilityNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.disabilityNotes" name="{{prefix}}characterDisabilityNotes" placeholder="Notes"></textarea>
        </div>
        <!-- End Character disability -->
      </mat-card-content>
    </mat-card>

    <mat-card class="card__character--sexuality">
      <!-- Begin Character sexuality -->
      <mat-card-title>
        <span class="title">Sexuality</span>
      </mat-card-title>
      <mat-card-content>
        <div *ngFor="let item of allCharacterSexuality">
          <mat-checkbox id="{{prefix}}characterSexuality" name="{{prefix}}characterSexuality" [(ngModel)]="item.selected"
            (ngModelChange)="onClickSexuality()">LGBTQ+</mat-checkbox>
        </div>
        <div class="input-group">
          <textarea type="text" id="{{prefix}}characterSexualityNotes" class="form-control" maxlength="255"
            [(ngModel)]="model.sexualityNotes" name="{{prefix}}characterSexualityNotes" placeholder="Notes"></textarea>
        </div>
        <!-- End Character sexuality -->
      </mat-card-content>
    </mat-card>
  </ng-template>

</mat-expansion-panel>