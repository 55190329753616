<h1 mat-dialog-title>Create new publisher</h1>
<div mat-dialog-content>
    <mat-form-field class="input__publisherName">
        <mat-label>Publisher name</mat-label>
        <input matInput placeholder="Publisher name" value="" [formControl]="inputPublisherName" (ngModelChange)="checkExistingPublishers($event)"
            required>        
        <mat-error *ngIf="inputPublisherName.hasError('required')">
            Publisher's name is required
        </mat-error>
        <mat-error *ngIf="inputPublisherName.hasError('publisherExists')">
            This publisher already exists.
        </mat-error>
    </mat-form-field>
    <input hidden [(ngModel)]="newPublisher.publisherId">
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelAdd()">Cancel</button>
    <button class="button__save" mat-button mat-flat-button (click)="closeDialog()" [disabled]="inputPublisherName.hasError('required') || inputPublisherName.hasError('publisherExists')">Save new publisher</button>
</div>