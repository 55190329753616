/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generate-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i6 from "@angular/material/progress-spinner";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/common";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i11 from "@angular/router";
import * as i12 from "./generate-report.component";
import * as i13 from "../../services/list-books.service";
var styles_GenerateReportComponent = [i0.styles];
var RenderType_GenerateReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenerateReportComponent, data: {} });
export { RenderType_GenerateReportComponent as RenderType_GenerateReportComponent };
function View_GenerateReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-icon", [["class", "mat-list-icon mat-icon notranslate"], ["matListIcon", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 16384, [[3, 4]], 0, i3.MatListIconCssMatStyler, [], null, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["cloud_download"])), (_l()(), i1.ɵted(-1, null, [" Generate report"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_GenerateReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i5.View_MatSpinner_0, i5.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i6.MatSpinner, [i1.ElementRef, i7.Platform, [2, i8.DOCUMENT], [2, i9.ANIMATION_MODULE_TYPE], i6.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵted(-1, null, [" Downloading report"]))], function (_ck, _v) { var currVal_3 = 25; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_GenerateReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.doGetReport() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatListItem_0, i10.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), i1.ɵdid(5, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i8.LocationStrategy], null, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_GenerateReportComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_GenerateReportComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.loading; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = i1.ɵnov(_v, 5).target; var currVal_3 = i1.ɵnov(_v, 5).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_GenerateReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generate-report", [], null, null, null, View_GenerateReportComponent_0, RenderType_GenerateReportComponent)), i1.ɵdid(1, 114688, null, 0, i12.GenerateReportComponent, [i13.ListBooksService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenerateReportComponentNgFactory = i1.ɵccf("app-generate-report", i12.GenerateReportComponent, View_GenerateReportComponent_Host_0, {}, {}, []);
export { GenerateReportComponentNgFactory as GenerateReportComponentNgFactory };
