import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Login } from '../classes/login';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService implements CanActivate {

  private hostName;

  private authEmitter;

  constructor(private http: HttpClient, private router: Router) {
    this.hostName = environment.apiHost;
  }

  login(loginModel: Login): Observable<boolean> {
    return this.http.post(this.hostName + '/api/account/login', loginModel)
      .pipe(map((res) => {
        this.saveToken(res.toString());
        this.authEmitter.next(true);
        return true;
      }))
      .pipe(catchError(e => {
        return of(false);
      }))
  }

  logout(): void {
    localStorage.removeItem('bearerToken');
    this.authEmitter.next(false);
  }

  isLoggedIn(): boolean {
    return localStorage.getItem('bearerToken') !== null;
  }

  authEvent(): Observable<boolean> {
    return Observable.create(e => this.authEmitter = e);
  }

  canActivate(): boolean {
    if (this.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login'])
    }
  }

  private saveToken(token: string): void {
    localStorage.setItem(`bearerToken`, token);
  }
}
