<!-- LOADING STATE -->
<div class="container-fluid loading__animated" [hidden]="!loading" [@hideShowAnimation]>
    <div class="row__save">
        <h1 class="shadow shadow__heading" *ngIf="isNewBook" aria-label="new book"></h1>
        <h1 class="shadow shadow__heading" *ngIf="!isNewBook" aria-label="updating book"></h1>
        <div class="shadow shadow__button"></div>
    </div>
    <div class="row">
        <div class="col region__left">
            <div class="shadow shadow__input-12"></div>
            <div class="shadow shadow__input-4"></div>
            <div class="shadow shadow__card"></div>
            <div class="shadow shadow__card"></div>
        </div>
        <div class="col region__right">
            <div class="shadow shadow__card"></div>
            <div class="shadow shadow__card"></div>
        </div>
    </div>
</div>
<!-- ERROR STATE -->
<div class="row" *ngIf="!loading && hasGetBookError">
    <div class="row__save">
        <h1>Updating Book</h1>
    </div>
    <div class="col-12">
        <mat-card class="card__loading">
            <mat-card-title>
                <span class="title">Error</span>
            </mat-card-title>
            <mat-card-content>
                <p>We couldn't find a book with the id: "<strong>{{ errorId }}</strong>".</p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<form #bookForm="ngForm" *ngIf="!loading && !hasGetBookError" [@hideShowAnimation]>
    <div class="row__save">
        <h1 *ngIf="isNewBook">New Book</h1>
        <h1 *ngIf="!isNewBook">Updating Book</h1>
        <div class="col">
            <span class="helper helper__success" *ngIf="saveSuccessful && !richTextHasError">
                <mat-icon>check</mat-icon> Changes were saved {{ lastSavedDate | timeAgo }}
            </span>
            <span class="helper helper__error" *ngIf="richTextHasError">
                <mat-icon>warning</mat-icon> CCBC Recommendation: Choices Annotation exceeds maximum length
            </span>
            <button mat-raised-button class="button__save" (click)="onSave()"
                [disabled]="!bookForm.form.valid || richTextHasError">
                <mat-icon>save</mat-icon> Save changes
            </button>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col region__left">
                <div class="row">
                    <div class="input-group col-12">
                        <div class="input-group-prepend">
                            <span [ngClass]="{'valid_and_required': title.valid, 'invalid': title.invalid}"
                                class="input-group-text" id="input__title">Title</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Title" aria-describedby="input__title"
                            [(ngModel)]="book.title" name="title" #title="ngModel" required maxlength="255">
                    </div>
                </div>
                <div class="row">
                    <div class="input-group col-6">
                        <div class="input-group-prepend">
                            <span [ngClass]="{'valid_and_required': isbn.valid, 'invalid': isbn.invalid}"
                                class="input-group-text" id="input__isbn">ISBN</span>
                        </div>
                        <input type="text" class="form-control" aria-label="ISBN" aria-describedby="input__ISBN"
                            [(ngModel)]="book.isbn" name="isbn" #isbn="ngModel" required maxlength="16">
                    </div>
                </div>
                <div class="row">
                    <div class="input-group col-6">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="input__altTitle1">Alt Title</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Alternative Title 1" aria-describedby="input__altTitle1"
                            [(ngModel)]="book.altTitle1" name="altTitle1" #altTitle1="ngModel" maxlength="255">
                    </div>
                    <div class="input-group col-6">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="input__altTitle2">Alt Title</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Alternative Title 2" aria-describedby="input__altTitle2"
                            [(ngModel)]="book.altTitle2" name="altTitle2" #altTitle2="ngModel" maxlength="255">
                    </div>
                </div>
                <div class="row">
                    <div class="input-group col-6">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="input__altTitle3">Alt Title</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Alternative Title 3" aria-describedby="input__altTitle3"
                            [(ngModel)]="book.altTitle3" name="altTitle3" #altTitle3="ngModel" maxlength="255">
                    </div>
                    <div class="input-group col-6">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="input__altTitle4">Alt Title</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Alternative Title 4" aria-describedby="input__altTitle4"
                            [(ngModel)]="book.altTitle4" name="altTitle4" #altTitle4="ngModel" maxlength="255">
                    </div>
                </div>
                <!-- CONTRIBUTORS SECTION -->
                <mat-card class="card__contributor">
                    <mat-card-title>
                        <span class="title">Contributor</span>
                        <button mat-flat-button color="accent" (click)="showContributorsModal()"
                            [disabled]="isLoadingContributors">
                            <span class="add-contributors--loading" *ngIf="isLoadingContributors">
                                <mat-spinner color="accent"></mat-spinner>
                            </span>
                            <mat-icon *ngIf="!isLoadingContributors">add</mat-icon> Add contributors
                        </button>
                    </mat-card-title>
                    <mat-card-content>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Role(s)</th>
                                    <th scope="col">Books</th>
                                    <th scope="col" class="cell-centered">Edit</th>
                                    <th scope="col">Remove</th>
                                </tr>
                            </thead>
                            <tbody class="drag-list" cdkDropList (cdkDropListDropped)="onContributorDrop($event)">
                                <tr *ngIf="book.bookContributors.length === 0">
                                    <td colspan="5">
                                        <p>There are no contributors associated with this book.</p>
                                    </td>
                                </tr>
                                <!-- CONTRIBUTORS CURRENTLY ASSOCIATED WITH THIS BOOK -->
                                <tr class="drag-row" *ngFor="let contributor of book.bookContributors; let i = index;" cdkDrag cdkDragLockAxis="y">
                                    <td>{{ contributor.contributor.lastName }},
                                        {{ contributor.contributor.firstName }}
                                        {{ contributor.contributor.middleName }}</td>
                                    <td>
                                        <mat-chip-list #contributorRoleChipList aria-label="Select contributor roles">
                                            <mat-chip [selectable]="selectable">
                                                {{ contributor.contributorType.displayName }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </td>
                                    <td class="small-col"><a mat-icon-button href="/contributor/{{ contributor.contributor.id}}"
                                            aria-label="view all books associated with [this contributor]">
                                            <mat-icon>library_books</mat-icon>
                                        </a>
                                    </td>
                                    <td class="small-col">
                                        <button mat-flat-button color="accent"
                                            (click)="showEditContributorModal(contributor.contributor.id)">Edit</button>
                                    </td>
                                    <td class="small-col">
                                        <mat-checkbox aria-label="remove contributor from this book"
                                            (change)="toggleDeleteContributor($event, contributor, i)">
                                        </mat-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>

                <!-- PUBLISHERS SECTION -->
                <mat-card class="card__publisher">
                    <mat-card-title>
                        <span class="title">Publisher</span>
                        <button mat-flat-button color="accent" (click)="showPublishersModal()">
                            <mat-icon>add</mat-icon> Add publishers
                        </button>
                    </mat-card-title>
                    <mat-card-content>
                        <p>Associate this book with a publisher by selecting the publisher from the dropdown. Filter the available
                            options by typing in the publisher you're looking for. Remove a publisher from this book by clicking the &quot;x&quot; next to the
                            name.</p>
                        <app-multi-select [id]="'publishers'" [hasFilter]="true" [label]="'Filter by name'" [ariaLabel]="'publishers'"
                            [multiSelectOptions]="publisherOptions" [selectionsToReturn]="selectedPublishers"
                            (onSelectionsChanged)="setSelectedPublishers($event)"></app-multi-select>
                        <div class="row">
                            <div class="input-group col-6">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        [ngClass]="{'valid_and_required': year.valid, 'invalid': year.invalid}"
                                        id="input__publishYear">Year</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="book.year" name="year"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    step="1" min="0" max="9999" aria-label="year published" maxlength="4"
                                    aria-describedby="input__publishYear" value="" #year="ngModel" required>
                            </div>
                            <div class="input-group col-6">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="input__publisherPages">Pages</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="book.pageNumbers"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    name="pageNumbers" step="1" min="0" max="2147483647" maxlength="9"
                                    aria-label="number of pages" aria-describedby="input__publisherPages" value="">
                            </div>
                        </div>
                        <div class="row mat-row">
                            <mat-checkbox id="input__nonUsPublisher" [(ngModel)]="book.nonUsPublisher"
                                name="nonUsPublisher">Non-US Publisher</mat-checkbox>
                            <mat-checkbox id="input__newEdition" [(ngModel)]="book.newEdition" name="newEdition">New
                                Edition</mat-checkbox>
                            <mat-checkbox id="input__reIssue" [(ngModel)]="book.reIssue" name="reIssue">Reissue
                            </mat-checkbox>
                           
                        </div>
                    </mat-card-content>
                </mat-card>
                <!-- COLLECTION SECTION -->
                <mat-card class="card__collection">
                    <mat-card-title>
                        <span class="title">Collection</span>
                    </mat-card-title>
                    <mat-card-content>
                        <div class="row">
                            <div class="input-group col-6">
                                <div class="input-group-prepend">
                                    <span
                                        [ngClass]="{'valid_and_required': ccbcCollection.valid, 'invalid': ccbcCollection.invalid}"
                                        class="input-group-text" id="input__ccbcCollection">CCBC Collection</span>
                                </div>
                                <select class="form-control" aria-label="ccbcCollection"
                                    aria-describedby="input__ccbcCollection" [(ngModel)]="book.ccbcCollection"
                                    name="ccbcCollection" #ccbcCollection="ngModel" required>
                                    <option *ngFor="let opt of ccbcCollectionOptions" [value]="opt">{{opt}}</option>
                                </select>
                            </div>
                            <div class="input-group col-6">
                                <div class="input-group-prepend">
                                    <span
                                        [ngClass]="{'valid_and_required': callNumber.valid, 'invalid': callNumber.invalid}"
                                        class="input-group-text" id="input__callNumber">Call #</span>
                                </div>
                                <input class="form-control" aria-label="callNumber" aria-describedby="input__callNumber"
                                    [(ngModel)]="book.callNumber" name="callNumber" #callNumber="ngModel" required
                                    maxlength="100">
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-group col-6">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="input__originallyPublishedIn">Year 1st
                                        Published</span>
                                </div>
                                <input class="form-control" aria-label="originallyPublishedIn" type="number"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    aria-describedby="input__originallyPublishedIn" step="1" min="0" max="9999"
                                    maxlength="4" maxlength="4" [(ngModel)]="book.originallyPublishedIn"
                                    name="originallyPublishedIn">
                            </div>
                        </div>
                        <div class="row multi-select-group">
                            <div class="col multi-select-group__prepend">
                                <span class="input-group-text">Genre/Format</span>
                            </div>
                            <div class="col multi-select-group__select">
                                <app-multi-select id="genresMultiselect" #genresMultiSelect [id]="'genresMultiselectInput'" [hasFilter]="true"
                                    [label]="'Filter by name'" [multiSelectOptions]="genreOptions" [ariaLabel]="'genre/format'"
                                    [selectionsToReturn]="selectedGenres"
                                    (onSelectionsChanged)="setSelectedGenres($event)" aria-labelledby="labelGenre">
                                </app-multi-select>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="card__translated">
                    <div class="row mat-row">
                        <mat-checkbox [(ngModel)]="book.translated" name="translated">
                            <span class="title">Translated</span>
                        </mat-checkbox>
                    </div>
                    <div *ngIf="book.translated">
                        <div class="row">
                            <div class="input-group col-12">
                                <textarea class="form-control" aria-label="translated notes" placeholder="Notes"
                                    [(ngModel)]="book.translatedNotes" name="translatedNotes"
                                    maxlength="255"></textarea>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <!-- assign bibliography section -->
                <mat-card>
                    <mat-card-title>
                        <span class="title">Bibliographies</span>
                        <button mat-flat-button color="accent"
                        (click)="assignBibliography(book.booId)">
                         <mat-icon>add</mat-icon> Assign to Bibliography
                        </button>
                    </mat-card-title>
                    <mat-card-content>
                        <table class="table table-bordered tblBibliographyHeader">
                            <thead>
                                <tr>
                                    <th width="40%">Name</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Subcategory</th>
                                    <th scope="col">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let bookBibliography of bookBibliographies; let i = index;">
                                    <td scope="row">{{bookBibliography.bibliographyName}}</td>
                                    <td>{{bookBibliography.categoryName}}</td>
                                    <td>{{bookBibliography.subcategoryName}}</td>
                                    <td class="text-center">
                                        <mat-checkbox aria-label="remove bibliography from this book"
                                        (change)="deleteBibliographyFromBook($event, bookBibliography, i)">
                                    </mat-checkbox>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </mat-card-content>
                    
                </mat-card>
                
            </div>
            
            <div class="col region__right">
                <mat-tab-group id="tabs" class="book-subject-tabs" #tabGroup (selectedTabChange)="handleTabChange($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <strong class="title">Diversity<br />Subject</strong>
                        </ng-template>
                        <div class="tabInsideContainer">
                            <app-diversity-subject [model]="book"></app-diversity-subject>
                        </div>

                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <strong>Primary<br /> Character/Subject</strong>
                        </ng-template>
                        <div class="tabInsideContainer">
                            <mat-accordion>
                                <app-book-characters *ngFor="let character of bookPrimaryCharacters; let i = index" [model]="character" [index]="i">
                                </app-book-characters>
                            </mat-accordion>
                        </div>


                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <strong>Secondary<br /> Character/Subject</strong>
                        </ng-template>
                        <div class="tabInsideContainer">
                            <mat-accordion>
                                <app-book-characters *ngFor="let character of bookSecondaryCharacters; let i = index" [model]="character" [index]="i">
                                </app-book-characters>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <strong>Recommend</strong>
                        </ng-template>
                        <div class="tabInsideContainer">
                            <app-book-recommendation [(model)]="book.recommendation"
                                (isRichTextValid)="checkMCEInput($event)" [isActive]="activeTabIndex===3">
                            </app-book-recommendation>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
    </div>

    <div class="row__save" style="margin-top:1rem;">
        <div class="col">
            <span class="helper__success" *ngIf="saveSuccessful && !richTextHasError">
                <mat-icon>check</mat-icon> Changes were saved {{ lastSavedDate | timeAgo }}
            </span>
            <span class="helper helper__error" *ngIf="richTextHasError">
                <mat-icon>warning</mat-icon> CCBC Recommendation: Choices Annotation exceeds maximum length
            </span>
            <button mat-raised-button class="button__save" (click)="onSave()"
                [disabled]="!bookForm.form.valid || richTextHasError">
                <mat-icon>save</mat-icon> Save changes
            </button>
        </div>
    </div>
</form>