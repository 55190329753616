import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Classes
import { BooksList } from 'src/app/classes/BooksList';
import { OrderBy } from 'src/app/classes/OrderBy';
import { Book, Genre } from 'src/app/classes/Book';
import { Contributor, ContributorType } from 'src/app/classes/Contributor';
import { Publisher, BookPublishers } from 'src/app/classes/Publisher';
//diveristy subject + secondary diversity + gender marker
import { Heritage } from '../classes/Heritage';
import { Religion } from '../classes/Religion';
import { Disability } from '../classes/Disability';
import { Lgbtq } from '../classes/Lgbtq';
import { Secondary } from '../classes/Secondary';
import { GenderMarkerLookup } from '../classes/GenderMarkerLookup';
//characters
import { CharacterType } from '../classes/CharacterType';
import { Gender } from '../classes/Gender';
import { CharacterGenderSpecify } from '../classes/CharacterGenderSpecify';
import { CharacterHeritage } from '../classes/CharacterHeritage';
import { CharacterHeritageSpecify } from '../classes/CharacterHeritageSpecify';
import { CharacterSexuality } from '../classes/CharacterSexuality';

//recommendation
import {Recommendation, AgeGradeRange, ContentType, CCBCSubject, Format, WisconsinInterest } from '../classes/Recommendation';

@Injectable()
export class ListBooksService {

    private hostName: string;
    private bookUrlBase = '/api/Books'; 
  
    constructor(
      private http: HttpClient
    ) {
      this.hostName = environment.apiHost;
    }
    
    /**
     * Gets a list of books, given the params expected by the back end API.
     * @param {number} page Page number for which books are requested
     * @param {number} take Number of books to get from the back end
     * @param {OrderBy} order Ordering instructions for API
     * @param {string} searchText Optional text string on which to match results
     */
    getBooksList(page: number, take: number, order: OrderBy, searchText?:string) : Observable<BooksList> {   
        let params = JSON.parse(JSON.stringify({
            page: page,
            take: take,
            order: order,
            searchText: searchText
        }));
      return this.http.post<BooksList>(this.hostName+this.bookUrlBase+'/GetBookListAsync/', params);
    }

    /**
     * Gets a single book matching on the provided id
     * @param {number} id The book's id number
     */
    getBookById(id : number | null) : Observable<Book> {
      return this.http.get<Book>(this.hostName+this.bookUrlBase+'/GetByIdAsync/'+id.toString());
    }

    /**
     * Delete the book with the provided id
     * @param {number} id The book's id number
     */
    deleteBookById(id: number | null): Observable<boolean> {
      return this.http.delete<boolean>(this.hostName+this.bookUrlBase+'/'+id.toString());
    }

    /**
     * Get a list of contributors
     * @param id 
     */
    getBooksForContributorId(id: number): Observable<Array<Book>> {
      return this.http.get<Book[]>(this.hostName+this.bookUrlBase+'/GetForContributorIdAsync/'+id.toString());
    }

    /**
     * Removes the association between a contributor and a book
     * @param {Contributor} bookContributor The contributor to disassociate from the book
     */
    removeContributor(bookContributor: Contributor): Observable<number> {
      return this.http.delete<number>(this.hostName+this.bookUrlBase+`/${bookContributor.bookId}/contributors/${bookContributor.contributorId}/${bookContributor.contributorTypeId}`);
    }

    /**
     * Get all publishers from API
     */
    getPublishers() : Observable<Publisher[]> {
      return this.http.get<Publisher[]>(this.hostName+this.bookUrlBase+'/publishers')
        .pipe();
    }
    
    /**
     * Add a publisher to the book
     * @param {Publisher} publisher The new publisher to add to the book
     */
    addPublisher(publisher: Publisher): Observable<Publisher> 
    {   
      return this.http.post<Publisher>(this.hostName+this.bookUrlBase+"/AddPublisher", publisher)      
    }

     /**
     * Get all genres from API
     */
    getGenres() : Observable<Genre[]> {
      return this.http.get<Genre[]>(this.hostName+this.bookUrlBase+'/genres')
        .pipe();
    }

    /**
     * Diversity Subject section
     * Get all heritages from backend
     * 
     */
    getAllHeritages() : Observable<Heritage[]> {
      return this.http.get<Heritage[]>(this.hostName+this.bookUrlBase+'/AvailableHeritages')
        .pipe();
    }
    getAllReligions() : Observable<Religion[]> {
      return this.http.get<Religion[]>(this.hostName+this.bookUrlBase+'/AvailableReligions')
        .pipe();
    }
    getAllDisabilities() : Observable<Disability[]> {
      return this.http.get<Disability[]>(this.hostName+this.bookUrlBase+'/AvailableDisabilities')
        .pipe();
    }
    getAllLgbtqs() : Observable<Lgbtq[]> {
      return this.http.get<Lgbtq[]>(this.hostName+this.bookUrlBase+'/AvailableLgbtqs')
        .pipe();
    }
    getAllSecondaries() : Observable<Secondary[]> {
      return this.http.get<Secondary[]>(this.hostName+this.bookUrlBase+'/AvailableSecondaries')
        .pipe();
    }
    getAllGenderMarkers() : Observable<GenderMarkerLookup[]> {
      return this.http.get<GenderMarkerLookup[]>(this.hostName+this.bookUrlBase+'/AvailableGendermarkers')
        .pipe();
    }
    /**END of diversity subject section */
    
    /*Character dropdow lists */
    getAllCharacterTypeOptions() : Observable<CharacterType[]> {
      return this.http.get<CharacterType[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterTypes')
        .pipe();
    }
    getAllCharacterGenderOptions() : Observable<Gender[]> {
      return this.http.get<Gender[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterGenders')
        .pipe();
    }
    getAllCharacterGenderSpecifyOptions() : Observable<CharacterGenderSpecify[]> {
      return this.http.get<CharacterGenderSpecify[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterGenderSpecifies')
        .pipe();
    }
    getAllCharacterHeritageOptions() : Observable<CharacterHeritage[]> {
      return this.http.get<CharacterHeritage[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterHeritages')
        .pipe();
    }
    getAllCharacterHeritageSpecifyOptions() : Observable<CharacterHeritageSpecify[]> {
      return this.http.get<CharacterHeritageSpecify[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterHeritageSpecifies')
        .pipe();
    }
    /*
    getAllCharacterDisabilityOptions() : Observable<CharacterDisability[]> {
      return this.http.get<CharacterDisability[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterDisabilities')
        .pipe();
    }
    */
    getAllCharacterSexualityOptions() : Observable<CharacterSexuality[]> {
      return this.http.get<CharacterSexuality[]>(this.hostName+this.bookUrlBase+'/AvailableCharacterSexualities')
        .pipe();
    }
    /**END of characters subject section */
    
    /*
     *  Get Recommendation dropdowns
     */
    getAllAgeGradeRangeOptions() : Observable<AgeGradeRange[]> {
      return this.http.get<AgeGradeRange[]>(this.hostName+this.bookUrlBase+'/AvailableAgeGradeRanges')
        .pipe();
    }
    getAllCCBCSubjectOptions() : Observable<CCBCSubject[]> {
      return this.http.get<CCBCSubject[]>(this.hostName+this.bookUrlBase+'/AvailableCCBCSubjects').pipe();
    }    
    getAllContentTypeOptions() : Observable<ContentType[]> {
      return this.http.get<ContentType[]>(this.hostName+this.bookUrlBase+'/AvailableContentTypes').pipe();
    }
    getAllFormatOptions() : Observable<Format[]> {
      return this.http.get<Format[]>(this.hostName+this.bookUrlBase+'/AvailableFormats').pipe();
    }
    getAllWisconsinInterestOptions() : Observable<WisconsinInterest[]> {
      return this.http.get<WisconsinInterest[]>(this.hostName+this.bookUrlBase+'/AvailableWisconsinInterests').pipe();
    }    
    
    addCCBCSubject(ccbcSubject: CCBCSubject): Observable<CCBCSubject> 
    {   
      return this.http.post<CCBCSubject>(this.hostName+this.bookUrlBase + "/AddCCBCSubject", ccbcSubject);
    }
  
    updateCCBCSubject(ccbcSubject: CCBCSubject): Observable<CCBCSubject> 
    {   
      return this.http.post<CCBCSubject>(this.hostName+this.bookUrlBase + "/UpdateCCBCSubject", ccbcSubject);
    }

    deleteCCBCSubject(ccbcSubject: CCBCSubject): Observable<CCBCSubject> 
    {   
      return this.http.post<CCBCSubject>(this.hostName+this.bookUrlBase + "/DeleteCCBCSubject", ccbcSubject);
    }

    /*
     *  Upload an image file to the CoverImage field of the Recommendation
     */
    postFile(fileToUpload: File, bookId: number): Observable<boolean> {    
      let endpoint = this.hostName+this.bookUrlBase+"/UploadBookCover/"+bookId;    
      const formData: FormData = new FormData();
      let headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
      }
      formData.append('fileKey', fileToUpload, fileToUpload.name);
      
      return this.http.put<boolean>(endpoint, formData);
    }
  
 
    /**
     * Update a book with the given id, or create a new book if the id equals zero.
     * @param {Book} book The book's updated information
     */
    saveBook(book: Book): Observable<Book> {
      console.info("saving book: ", book);
      if(book.id === 0 || book.id === undefined) {      
        return this.http.post<Book>(this.hostName+this.bookUrlBase+"/CreateBook", book);
      } else {
        return this.http.post<Book>(this.hostName+this.bookUrlBase+"/UpdateBook", book);
      }
    }


    /*
     *  Download an csv report of the database contents
     */
    getReport(): Observable<string> {
      const options = { responseType: 'text' as 'json' }; //This is a weird workaround to return a string instead of JSON object. 
      return this.http.get<string>(this.hostName + this.bookUrlBase + '/GetReportAsync', options);
    }

}