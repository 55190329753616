import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Bibliography, BibliographyAgeGrade, BookForBibliographyAdd } from 'src/app/classes/Bibliography';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class BibliographyService {
    constructor(http) {
        this.http = http;
        this.bibliographyUrlBase = '/api/Bibliography';
        this.hostName = environment.apiHost;
    }
    /**
     * Gets a single bibliography matching on the provided id
     * @param {number} id The bibliography's id number
     */
    getBibliographyById(id) {
        return this.http.get(this.hostName + this.bibliographyUrlBase + '/GetByIdAsync/' + id.toString());
    }
    /* Get  bibliography age grades for checkboxes*/
    getAllAgeGrades() {
        return this.http.get(this.hostName + this.bibliographyUrlBase + '/AvailableBibliographyAgeGrades')
            .pipe();
    }
    /**
     * Update a bibliography with the given id, or create a new bibliography if the id equals zero.
     * @param {Bibliography} bibliography The bibliography's updated information
     */
    saveBibliography(bibliography) {
        console.info("saving bibliography: ", bibliography);
        if (bibliography.id === 0 || bibliography.id === undefined) {
            return this.http.post(this.hostName + this.bibliographyUrlBase + "/CreateBibliography", bibliography);
        }
        else {
            return this.http.post(this.hostName + this.bibliographyUrlBase + "/UpdateBibliography", bibliography);
        }
    }
    /* search for books by title to add to a bibliography category */
    searchForBooks(text) {
        let searchParameters = {
            searchText: text
        };
        return this.http.post(this.hostName + this.bibliographyUrlBase + "/SearchForBooksByTitle", searchParameters);
    }
    /*get all bibliography with book numbers*/
    getAllBibliography() {
        return this.http.get(this.hostName + this.bibliographyUrlBase + "/GetAllBibliography");
    }
    /**
     * Get bibliography for this book
     * @param id
     */
    getBookBibliographyById(id) {
        return this.http.get(this.hostName + this.bibliographyUrlBase + '/GetBookBibliography/' + id.toString());
    }
    getAllBibliographyCategory() {
        return this.http.get(this.hostName + this.bibliographyUrlBase + "/GetAllBibliographyCategory");
    }
    /**
     * Delete the bibliography with the provided id
     * @param {number} id The bibliography's id number
     */
    deleteBibliographyById(id) {
        return this.http.delete(this.hostName + this.bibliographyUrlBase + '/' + id.toString());
    }
}
BibliographyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BibliographyService_Factory() { return new BibliographyService(i0.ɵɵinject(i1.HttpClient)); }, token: BibliographyService, providedIn: "root" });
