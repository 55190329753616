<h1 mat-dialog-title>Add books to category</h1>
<div>
    <div class="row">
        <div class="input-group mb-3">
            <input aria-label="" class="form-control" id="BibliographyBookSearch" placeholder="Search for books by title" type="text" 
            (keyup.enter)="getBooks()" [(ngModel)]="searchText"/>
            <div class="input-group-append">
                <button type="button" class="btn btn-outline-secondary" (click)="getBooks()">Search</button>
            </div>
        </div>
        <p class="form-text text-muted" *ngIf="searchInitiated && bookList.length == 0">No books found.</p>
    </div>

    <div class="row table-fixed" >
        <table class="table table-sm table-bordered" *ngIf="bookList.length>0"v>
            <thead>
                <tr>
                    <th>Add</th>
                    <th>Author(s)</th>
                    <th>Title</th>
                    <th>Year</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let book of bookList">
                    <td><mat-checkbox [(ngModel)]="book.add" ></mat-checkbox></td>
                    <td>{{book.authors}}</td>
                    <td>{{book.title}}</td>
                    <td>{{book.year}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-flat-button class="button__white" type="button" (click)="cancelAdd()">Cancel</button>
    <button mat-flat-button class="button__save" type="button" (click)="closeDialog()" *ngIf="bookList.length>0"><mat-icon>add</mat-icon> Add book(s)</button>
</div>
