import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

//services
import { ListBooksService } from 'src/app/services/list-books.service';

import { CCBCSubject } from 'src/app/classes/Recommendation';
import { HttpErrorResponse } from '@angular/common/http';

export interface IConfirmDeleteCCBCSubject {
  id: number;
  name: string;
}

/**
 * Child component that displays a confirmation dialog when deleting a CCBC Subject.
 */
@Component({
  selector: 'dialog-confirm-delete-ccbcsubject',
  templateUrl: 'dialog-confirm-delete-ccbcsubject.component.html'
})
export class DialogConfirmDeleteCCBCSubject {
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDeleteCCBCSubject) { }
}

/**
 * Component that displays a list of CCBC Subjects and provides controls for creating, updating, and deleting these subjects.
 */

@Component({
  selector: 'app-dialog-ccbcsubjects',
  templateUrl: './dialog-ccbcsubjects.component.html',
  styleUrls: ['./dialog-ccbcsubjects.component.scss']
})


export class DialogCCBCSubjectsComponent implements OnInit {

  @ViewChild('inputNewSubjectName', { static: false }) inputNewSubjectName: ElementRef;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.closeIfWeCan();
  }

  allSubjects: Array<CCBCSubject> = new Array<CCBCSubject>();
  
  newSubjectName = new FormControl();
  isAddingNewSubject = false;

  formGroupSubjectNames: FormGroup;
  
  crudCallIsPending: boolean = false;

  constructor(private bookService: ListBooksService,
    public dialogRef: MatDialogRef<DialogCCBCSubjectsComponent>,
    public confirmDeleteDialog: MatDialog,
    private el: ElementRef,    
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.newSubjectName.setValidators([this.subjectExistsValidator(0)]);

      this.formGroupSubjectNames = new FormGroup({
        subjects: new FormArray([])
      });

    }

  ngOnInit() {
    
    // Don't allow closing by normal means (clicking-away, pressing Escape)
    // We need to validate that it's safe to close, so we can pass the canonical
    // list of subjects back to the parent.
    this.dialogRef.disableClose = true;
    
    // If the backdrop around the dialog is clicked
    this.dialogRef.backdropClick().subscribe(_ => {
      this.closeIfWeCan();
    });

    if(this.data.allCCBCSubjects) {

      this.allSubjects = this.data.allCCBCSubjects;

      // We'll initially sort the list alphabetically to make it easy to find an entry for editing,
      // but that sorting won't be maintained as we don't want a table row to "jump" after the user renames an entry.
      // Likewise, we'll insert newly created entries at the top of the table, so the user can see that it was saved successfully
      this.allSubjects.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

      // For each CCBC Subject
      for(const subject of this.allSubjects){

        // Create a form group
        const subjectGroup: FormGroup = new FormGroup({ 
          id: new FormControl(subject.ccbcSubjectId),
          name: new FormControl(subject.name, [this.subjectExistsValidator(subject.ccbcSubjectId)]),
          editing: new FormControl(false),
          originalName: new FormControl(subject.name) });

        // Add the form group, respresenting a single CCBCSubject, to our form array 
        (<FormArray>this.formGroupSubjectNames.controls.subjects).push(subjectGroup);
      }

    }

  }

  addNewSubjectClick(): void{
    this.isAddingNewSubject = !this.isAddingNewSubject;
    
    // Wait for the field to be rendered, then focus on it
    setTimeout(() => {
      this.inputNewSubjectName.nativeElement.focus();
    }, 0);
    
  }

  cancelAddingNewSubject(): void{
    this.isAddingNewSubject = false;
    this.newSubjectName.reset();
  }

  // Custom validator to check if the given CCBC Subject name is already in-use
  subjectExistsValidator(subjectId): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      if(control.value === null){
        return null;
      }

      // Default to zero, for a new CCBC Subject
      let ccbcSubjectId = 0;

      // If we were given a CCBCSubjectId
      if(subjectId){
        ccbcSubjectId = subjectId;
      }

      // If we found a different CCBC Subject that has the same name (value of the control)
      if(this.subjectExists(ccbcSubjectId, control.value.toLowerCase().trim())){
        return { subjectExists: { value: control.value} };
      }

      return null;

    };

  }

  // Check our list of subjects to see if the given name is in-use by a different subject
  subjectExists(ccbcSubjectId, subjectName): boolean{

    for(let subject of this.allSubjects){
         // If there's a subject with the same name and different ID
         if(subject.name.toLowerCase().trim() === subjectName.toLowerCase().trim() && ccbcSubjectId !== subject.ccbcSubjectId )
          {
            return true;
          }
    }

    return false;
  }

  makeRowEditable(ccbcSubjectFormGroup: FormGroup): void{
    ccbcSubjectFormGroup.controls.editing.setValue(true);

     // Wait for the form field to be rendered, then focus on it
    setTimeout(() => {
      const inputToFocus = this.el.nativeElement.querySelector('[name="editSubjectName' + ccbcSubjectFormGroup.controls.id.value + '"]');
      inputToFocus.focus();
    }, 0);
   
  }

  cancelEditRow(ccbcSubjectFormGroup: FormGroup): void{
    // Revert to the default name, and toggle the editing flag
    ccbcSubjectFormGroup.controls.name.reset(ccbcSubjectFormGroup.controls.originalName.value);
    ccbcSubjectFormGroup.controls.editing.setValue(false);
  }

  saveEditRow(ccbcSubjectFormGroup: FormGroup): void{
    
    // Since multiple rows can be in the editable state at the same time,
    // someone could start editing two rows, specify a new identical subject name for both rows
    // and then click save on each row. We need to manually trigger the validation on this row before saving
    ccbcSubjectFormGroup.controls.name.updateValueAndValidity();

    if(!ccbcSubjectFormGroup.valid){
      return;
    }

    let newSubject = new CCBCSubject();
    newSubject.ccbcSubjectId = ccbcSubjectFormGroup.controls.id.value;
    newSubject.name = ccbcSubjectFormGroup.controls.name.value;
    
    this.crudCallIsPending = true;
        
    // Update subject via service
    this.bookService.updateCCBCSubject(newSubject)
      .subscribe(updatedSubject => {

        // Update the subject in our global array
        const subject = this.allSubjects.find(s => s.ccbcSubjectId === updatedSubject.ccbcSubjectId);
        subject.name = newSubject.name;

        // We're no longer editing
        ccbcSubjectFormGroup.controls.editing.setValue(false);
        ccbcSubjectFormGroup.controls.originalName.setValue(newSubject.name);

        this.crudCallIsPending = false;
        
      },
    error => {

      // We're expecting error to be an instance of HttpErrorResponse
      console.log(error);

      this.crudCallIsPending = false;

      // This is most likely the frontend submitting invalid data,
      // the backend API logs should be checked for further info
      if(error.status !== undefined && error.status === 400){        
        window.alert('Error saving edits to CCBC Subject - Bad Request');
        return;
      }

      window.alert('Unknown error saving edits to CCBC Subject');

    });

  }

  saveNew(): void{

    let newSubject = new CCBCSubject();
    newSubject.ccbcSubjectId = 0;
    newSubject.name = this.newSubjectName.value;

    this.crudCallIsPending = true;

    // Save new subject via service
    this.bookService.addCCBCSubject(newSubject)
      .subscribe(createdSubject => {

        // Add the subject to our global array
        this.allSubjects.push(createdSubject);

        // Append to our editable form group
        const subjectGroup: FormGroup = new FormGroup({ 
          id: new FormControl(createdSubject.ccbcSubjectId),
          name: new FormControl(createdSubject.name, [this.subjectExistsValidator(createdSubject.ccbcSubjectId)]), 
          editing: new FormControl(false),
          originalName: new FormControl(createdSubject.name) });

        (<FormArray>this.formGroupSubjectNames.controls.subjects).insert(0, subjectGroup);

        this.cancelAddingNewSubject();

        this.crudCallIsPending = false;

      },
      error => {
  
        // We're expecting error to be an instance of HttpErrorResponse
        console.log(error);
        
        this.crudCallIsPending = false;

        // This is most likely the frontend submitting invalid data,
        // the backend API logs should be checked for further info
        if(error.status !== undefined && error.status === 400){
          window.alert('Error saving new CCBC Subject - Bad Request');
          return;
        }
  
        window.alert('Unknown error saving new CCBC Subject');
  
      });

  }

  confirmDeleteRow(ccbcSubjectFormGroup: FormGroup): void{

    const deleteDialogRef = this.confirmDeleteDialog.open(DialogConfirmDeleteCCBCSubject, {
      data: {
        id: ccbcSubjectFormGroup.controls.id.value,
        name: ccbcSubjectFormGroup.controls.originalName.value
      }
    });


    let deleteSubject = new CCBCSubject();
    deleteSubject.ccbcSubjectId = ccbcSubjectFormGroup.controls.id.value;
    deleteSubject.name = ccbcSubjectFormGroup.controls.name.value;

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSubject(deleteSubject);
      }
    });

  }

  /**
   * Permanently delete the given CCBCSubject entry with the given id
   */
  deleteSubject(ccbcSubject: CCBCSubject): void {

    this.crudCallIsPending = true;

    this.bookService.deleteCCBCSubject(ccbcSubject).subscribe(result => {

      // Remove the subject from our global array
      this.allSubjects = this.allSubjects.filter((subject) => subject.ccbcSubjectId !== ccbcSubject.ccbcSubjectId);

      // Remove from the FormArray
      const indexToRemove = this.formGroupSubjectNames.value.subjects.findIndex(s => s.id === ccbcSubject.ccbcSubjectId);
      (<FormArray>this.formGroupSubjectNames.controls.subjects).removeAt(indexToRemove);

      this.crudCallIsPending = false;

    },
    error => {

      // We're expecting error to be an instance of HttpErrorResponse
      console.log(error);
      
      this.crudCallIsPending = false;

      // This is most likely the frontend submitting invalid data,
      // the backend API logs should be checked for further info
      if(error.status !== undefined && error.status === 400){
        window.alert('Error deleting CCBC Subject - Bad Request');
        return;
      }

      window.alert('Unknown error deleting CCBC Subject');

    });
  }

  // When the close button is clicked
  closeButtonClick(): void {
    this.closeIfWeCan();
  }

  // Close the dialog if it's safe to do so
  closeIfWeCan(): void{

    // We're waiting for a backend response
    if(this.crudCallIsPending){
      window.alert('Waiting for CCBC Subject changes to save. Please wait a few seconds and try to close this popup again.');
      return;
    }

   // Close the dialog, return all the CCBC subjects
   this.dialogRef.close({allSubjects: this.allSubjects});
    
  }

}
